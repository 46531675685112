import * as React from 'react';
import * as DateTime from 'react-datetime';
import {Moment} from 'moment';
import CheckBox from 'rc-checkbox';
import {Link} from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import LoaderContent from 'components/loader-content';
import {countryCode} from 'platform/constants';
import {OrderDeliveryTimeTypeEnum, OrderDeliveryTypeEnum} from 'platform/api/order/constants/enums';
import Select from 'components/select';
import {OrderDeliveryTimeTypeDropdown, OrderDeliveryTypeDropdown} from 'platform/constants/dropdowns';
import {IOrderCreateRequestModel} from 'platform/api/order/models/request';
import Storage from 'platform/services/storage';
import {IDropdownOption, IResponse, IYandexPlace} from 'platform/constants/interfaces';
import {validateForm} from './services/helper';
import {formatDate, formatPrice} from 'platform/services/helper';
import ChooseAddress from './components/choose-address';
import {IUserAddressListResponseModel} from 'platform/api/userAddress/models/response';
import OrderController from 'platform/api/order';
import SuccessModal from 'components/success-modal';
import {CardTypeEnum, PaymentTypeEnum} from 'platform/constants/enums';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PaymentMethod from './components/payment';
import {IBonusCardDetailsWithHistoryResponseModel} from 'platform/api/bonusCard/models/response';
import BonusCardController from 'platform/api/bonusCard';
import NumberInput from 'components/number-input';
import {IOrderResultResponseModel} from 'platform/api/order/models/response';
import Connection from 'platform/services/connection';
import ChoosePharmacy from './components/choose-pharmacy';
import {IPharmacyBranchListResponseModel} from 'platform/api/pharmacyBranch/models/response';
import UserAddressController from 'platform/api/userAddress';
import {Shared} from 'modules';
import './style.scss';
import LocationImage from 'assets/images/placeholder.svg';
import LocationHomeIcon from '../../../assets/images/icons/location-home.svg';
import TruckIcon from '../../../assets/images/icons/truck.svg';
import {CREATE_FORM_AND_SUBMIT} from "../products/pages/list/services/helper";
import No_Promo from '../../../assets/images/icons/no_promo.svg'
import Arrow from '../../../assets/images/ic_arrow.svg'
import Yes_Promo from '../../../assets/images/icons/yes_promo.svg'
import x_promo from '../../../assets/images/icons/x_promo.svg'
import {
    IBasketChangeResponseModel,
    IBasketListResponseModel,
    IBasketResponseModel
} from "../../../platform/api/basket/models/response";
import BasketController from "../../../platform/api/basket";
import PhotoStorage from "../../../platform/services/photoStorage";
import {PromoNotValid} from "../cart/components/promoNotValid";
import MasterCard from "../../../assets/images/master.svg";
import VisaCard from "../../../assets/images/visaImage.svg";
import Environment from "../../../platform/services/environment";
import GtagData from "../../../platform/services/gtagData";
import Addresses from "../../../components/addresses";

declare global {
    interface Window {
        gtag: any;
    }
}

interface IState {
    data?: IBasketResponseModel;
    outOfStockConfirm: boolean;
    priceNotEnoughModalOpen: boolean;
    promoValid?: boolean;
    promoMessage?: "Promo is not valid",
    bonusDetails?: IBonusCardDetailsWithHistoryResponseModel;
    resultInfo?: IOrderResultResponseModel;
    form: IOrderCreateRequestModel;
    promoResponseText?: any;
    dateType: OrderDeliveryTimeTypeEnum;
    submited: boolean;
    submitLoading: boolean;
    choosePharmacyOpen: boolean;
    cardType: number;
    chooseAddressOpen: boolean;
    authModalOpen: boolean;
    successModalOpen: boolean;
    chosenBranch?: IPharmacyBranchListResponseModel;
    addressList: IUserAddressListResponseModel[];
    initialTotalDiscountedPrice: number;
    isUsingBonus: boolean;
    isPayment: boolean;
    idramAmount: number | null;
    idramNId: number | null;
    isFinishPage: boolean;
    promocodeMessage: boolean;
    visaPromocodeMessage: boolean,
    visaPromocodeMessageGuest: boolean,
    visaPromocodeBool: boolean,
    authOpen: boolean;
    smallWindow: boolean;
    showPersonalInfo: boolean;
    showMyAddresses: boolean;
    inputBool: boolean;
    promoCode: boolean;
    visaPromoTotal: number;
    visaPromoBonus: number;
    cleared: boolean;
    anotherPromo: boolean;
    addressesList: any
};

interface GtagSubmitData {
    COUPON: string,
    VALUE: number | undefined,
}

export enum responseEnum {
    success,
    no_such_promo,
    card_missing,
    invalid_card,
    promo_limit_expired,
    card_limit_expired,
    does_not_make_sense,
    promo_percent_is_smoller,
    delevery_does_not_make_sense,
    not_valid_time,
    not_valid_promo_limit_expiered
};

@byRoute(ROUTES.CHECKOUT)
class Checkout extends HelperComponent<{}, IState> {

    public state: IState = {
        outOfStockConfirm: false,
        priceNotEnoughModalOpen: false,
        promoValid: false,
        promoMessage: "Promo is not valid",
        submited: false,
        submitLoading: false,
        choosePharmacyOpen: false,
        promoResponseText: null,
        cardType: 0,
        authOpen: false,
        chooseAddressOpen: false,
        successModalOpen: false,
        resultInfo: {
            totalPrice: 0,
            deliveryFee: 0,
            receivedBonus: 0,
            receivedBonusWithoutPromo: 0,
            totalDiscountedPrice: 0,
            isBonus: 0,
            isBonusVisa: 0,
            multiply: 0,
            totalDiscountedPriceWithoutPromo: 0,
            totalPriceWithoutPromo: 0
        },
        authModalOpen: false,
        isUsingBonus: false,
        addressList: [],
        initialTotalDiscountedPrice: 0,
        dateType: OrderDeliveryTimeTypeEnum.Asap,
        form: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            deliveryType: OrderDeliveryTypeEnum.Delivery,
            paymentType: PaymentTypeEnum.Cash
        },
        isPayment: false,
        idramAmount: null,
        idramNId: null,
        isFinishPage: false,
        promocodeMessage: false,
        visaPromocodeMessage: false,
        visaPromocodeBool: true,
        smallWindow: false,
        showPersonalInfo: false,
        showMyAddresses: true,
        inputBool: true,
        visaPromocodeMessageGuest: false,
        promoCode: false,
        visaPromoTotal: 0,
        visaPromoBonus: 0,
        cleared: false,
        anotherPromo: false,
        addressesList:[]
    };

    private get formValidation() {
        const {submited, form} = this.state;
        return validateForm.call(form, submited);
    }

    public componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const step = query.get('step');
        query.delete('paymentType');
        query.delete('card');
        window.history.replaceState({path: window.location.pathname}, '', `?${query}`);
        this.getResultInfo().then(() => {
            this.fetchData()
        })
        if (Storage.profile) {
            const {form} = this.state;
            form.firstName = Storage.profile.firstName;
            form.lastName = Storage.profile.lastName;
            form.phoneNumber = Storage.profile.phoneNumber.substring(`+${countryCode}`.length);
            form.email = Storage.profile.email;
            this.getTotalPrice();
            this.getBonusDetails();
            this.fetchAddressesList();
            this.safeSetState({form});
            this.getQuery();
            if (step === '3') {
                this.safeSetState({isPayment: true});
            }
            if (window.innerWidth < 901) {
                this.safeSetState({smallWindow: true})
            }
        } else {
            this.safeSetState({visaPromocodeMessageGuest: true})
        }
        this.updateBasketCount();
    }

    private getQuery() {
        const query = new URLSearchParams(window.location.search);
        const isCard = query.get('isCard');

        const card = query.get('card');
        const paymentType = query.get('paymentType');

        if (card && paymentType) {
            const {form} = this.state;
            form.paymentType = +paymentType;
            form.creditCardId = +card;
            this.safeSetState({form});
        }

        if (isCard) {
            query.delete('isCard');
            this.safeSetState({submited: true}, () => {
                this.safeSetState({isPayment: true}, () => {
                    window.scrollTo(0, 0);
                });
            });
        }
    }

    private getTotalPrice = () => {
        const query = new URLSearchParams(window.location.search);
        const price = query.get('total');
        this.safeSetState({idramAmount: `${price ? Math.round(+price) : price}`});
    };


    private getBonusDetails = async () => {
        const result = await BonusCardController.GetDetailsWithHistory({
            pageNumber: 1,
            pageSize: 1
        });

        this.safeSetState({bonusDetails: result.data});
    };

    private sendPromoCode = async (val: any, promo = false) => {
        if (!promo) {
            this.safeSetState({anotherPromo: true})
        }
        const {resultInfo} = this.state
        if (resultInfo != null) {
            resultInfo.totalPrice = resultInfo.totalPriceWithoutPromo;
            this.safeSetState({resultInfo});
        }
        if (!Storage.profile && !promo) {
            this.safeSetState({promocodeMessage: true})
        }
        val && val !== "" && Connection.POST2({
            body: {
                "promoCode": val,
                "productIds": this.state.data?.items.map(item => item.productId)
            },
            action: 'PromoCodeResult',
            controller: "Order",
        }).then(res => {
            res.data === responseEnum.success ? this.succesPromoValid(res.data, promo) : this.setPromoValid(res, promo)
        }).catch(err => console.log(err));
        // Connection.GET({
        //     action: `GetByUser/${val}`,
        //     controller:"Basket",
        // }).then(res=>res.data===responseEnum.success ? this.safeSetState({promoResponseText: res}):this.setPromoValid(res));
        //

        // const { initialTotalDiscountedPrice} = this.state;
        //
        // Connection.GET({
        //     action: `GetByUser/${val}`,
        //     controller:"Basket",
        // }).then(res=>this.safeSetState({
        //     resultInfo: res.data,
        //     initialTotalDiscountedPrice: Math.round(initialTotalDiscountedPrice || res.data.totalDiscountedPrice)
        //
        // }));


        // return result.then(response => response.json()).then(res=> {
        //     console.log(res)
        //     res.data===CardinalDirection.East ? this.safeSetState({promoResponseText: res}):this.setPromoValid(res)
        // })
    };

    private getResultInfo = async (bonus = 0, notSendPromocode?: any) => {
        const {form, initialTotalDiscountedPrice} = this.state;
        const result = notSendPromocode ? await OrderController.GetResult({
            usingBonus: bonus,
            deliveryType: form.deliveryType,
        }) : await OrderController.GetResult({
            usingBonus: bonus,
            deliveryType: form.deliveryType,
            promoCode: this.state.form.promoCode
        })
        result.data && this.safeSetState({
            resultInfo: result.data,
            initialTotalDiscountedPrice: Math.round(initialTotalDiscountedPrice || result.data.totalDiscountedPrice + result.data.deliveryFee)
        });
        if (result.data && form.promoCode === Environment.PROMOCODE_NAME) {
            this.safeSetState({
                visaPromoTotal: result.data.totalDiscountedPrice,
                visaPromoBonus: result.data.receivedBonus
            })
        }
    };
    private changeField = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, type = false) => {
        if (type) {
            this.safeSetState({promoResponseText: null, visaPromocodeBool: false, inputBool: true})
        }
        const {form} = this.state;
        form[e.currentTarget.name] = e.currentTarget.value;
        this.safeSetState({form});
    };

    private bonusChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const {form} = this.state;

        form.useBonusAmount = +e.currentTarget.value;
        Connection.AbortAll();

        this.safeSetState({form}, () => this.getResultInfo(form.useBonusAmount));
    };
    private fetchAddressesList = async () => {
        const result = await UserAddressController.GetList();

        result.data.length && this.safeSetState({addressList: result.data}, this.checkForDefaultAddress);
    };

    private checkForDefaultAddress = () => {
        const {form, addressList} = this.state;
        const defaultAddress = addressList.find(item => item.isDefault === true);
        if (defaultAddress) {
            form.addressText = defaultAddress.addressText;
            form.addressLat = defaultAddress.addressLat;
            form.addressLng = defaultAddress.addressLng;
            form.addressBuilding = defaultAddress.building;
            form.addressEntrance = defaultAddress.entrance;
            form.addressApartment = defaultAddress.apartment;
            form.addressFloor = defaultAddress.floor;
            this.safeSetState({form});
        }
    };

    private addressChange = (value: string) => {
        const { form } = this.state;
        form.addressText = value;
        form.addressLat = 0;
        form.addressLng = 0;
        this.safeSetState({ form });
    };

    private addressSelect = (place: IYandexPlace) => {
        const {form} = this.state;
        delete form.userAddressId;
        form.addressText = place.name;
        form.addressLat = place.position[0];
        form.addressLng = place.position[1];
        this.safeSetState({form});
    };

    private addTelcellForm = (data: any) => {
        CREATE_FORM_AND_SUBMIT(data.telCell.url, {
            action: data.telCell.action,
            issuer: data.telCell.issuer,
            currency: data.telCell.currency,
            price: data.telCell.price,
            product: data.telCell.product,
            issuer_id: data.telCell.issuerId,
            valid_days: data.telCell.validDays,
            security_code: data.telCell.securityCode,
        });
    }

    private dateFromChange = (chosen: Moment) => {
        const {form} = this.state;
        form.deliveryDateFrom = chosen.toISOString();
        this.safeSetState({form});
    };

    private submit = async (e: React.SyntheticEvent) => {
        // const {form, initialTotalDiscountedPrice,promoResponseText} = this.state;
        // if (promoResponseText.date==="success"){
        //     const result = await OrderController.GetResult({
        //         usingBonus: 0,
        //         deliveryType: form.deliveryType,
        //         promoCode:this.state.form.promoCode
        //     })
        //     result.data && this.safeSetState({
        //         resultInfo: result.data,
        //         initialTotalDiscountedPrice: Math.round(initialTotalDiscountedPrice || result.data.totalDiscountedPrice)
        //
        //     });
        // }

        e.preventDefault();


        this.safeSetState({submited: true, isFinishPage: true}, () => {
            this.formValidation.valid && this.safeSetState({isPayment: true}, () => {
                window.scrollTo(0, 0);
            });
        });
        if (this.state.submited) {
            const data: GtagSubmitData = {
                COUPON: this.state.form.promoCode || "",
                VALUE: this.state.resultInfo?.totalPrice,
            }
        }
    };

    private openAddressChoose = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.safeSetState({chooseAddressOpen: true});
    };

    private openPharmacyChoose = () => {
        this.safeSetState({choosePharmacyOpen: true})
    };

    private closeAddressChoose = (chosen?: IUserAddressListResponseModel) => {
        const {form} = this.state;

        if (chosen) {
            form.userAddressId = chosen.id;
            form.addressApartment = chosen.apartment;
            form.addressBuilding = chosen.building;
            form.addressEntrance = chosen.entrance;
            form.addressFloor = chosen.floor;
            form.addressText = chosen.addressText;
            form.addressLat = chosen.addressLat;
            form.addressLng = chosen.addressLng;
            this.safeSetState({form, chooseAddressOpen: false});
        } else this.safeSetState({chooseAddressOpen: false});
    };

    private clearAddress = () => {
        const {form} = this.state;
        form.branchId = undefined;
        form.userAddressId = undefined;
        form.addressApartment = '';
        form.addressBuilding = '';
        form.addressEntrance = '';
        form.addressFloor = '';
        form.addressText = '';
        form.addressLat = 0;
        form.addressLng = 0;
        this.safeSetState({form, chosenBranch: null});
    };

    private choosePharmacy = (pharmacy: IPharmacyBranchListResponseModel) => {
        if (pharmacy) {
            const {form} = this.state;
            form.branchId = pharmacy.id;
            form.addressText = pharmacy.addressText;
            form.addressLat = pharmacy.addressLat;
            form.addressLng = pharmacy.addressLng;
            this.safeSetState({form, chosenBranch: pharmacy, choosePharmacyOpen: false});
        } else this.safeSetState({choosePharmacyOpen: false});
    };

    private changeDeliveryType = (chosen: IDropdownOption<OrderDeliveryTypeEnum>) => {
        const {form} = this.state;
        form.deliveryType = chosen.value;
        this.safeSetState({form});
        this.getResultInfo()
    };
    private changeDeliveryTypeRadio = (chosen: OrderDeliveryTypeEnum) => {
        const {form} = this.state;
        form.deliveryType = chosen;
        this.safeSetState({form, showMyAddresses: chosen === 1});
    };

    private changeDeliveryTimeType = (chosen: IDropdownOption<OrderDeliveryTimeTypeEnum>) => {
        const {form} = this.state;
        if (chosen.value === OrderDeliveryTimeTypeEnum.Asap) {
            form.deliveryDateFrom = undefined;
        }
        this.safeSetState({dateType: chosen.value, form});
    };
    private changeDeliveryTimeTypeRadio = (chosen: OrderDeliveryTimeTypeEnum) => {
        const {form} = this.state;
        if (chosen === OrderDeliveryTimeTypeEnum.Asap) {
            form.deliveryDateFrom = undefined;
        }
        this.safeSetState({dateType: chosen, form});
    };

    private validateDeliveryDate = (dateItem: moment.Moment) => {
        const currentDayStarting = new Date();

        dateItem.hours(currentDayStarting.getHours());
        dateItem.minutes(currentDayStarting.getMinutes());
        dateItem.seconds(currentDayStarting.getSeconds());
        dateItem.milliseconds(currentDayStarting.getMilliseconds());

        return dateItem.isSameOrAfter(currentDayStarting);
    };

    private toggleUsingBonus = async () => {
        const {isUsingBonus, form} = this.state;

        if (isUsingBonus) {
            form.useBonusAmount = 0;
            Connection.AbortAll();
            this.safeSetState({form}, () => this.getResultInfo(form.useBonusAmount));
        }

        this.safeSetState({isUsingBonus: !isUsingBonus});
    };

    private finishCheckout = (e: React.SyntheticEvent) => {
        const {visaPromocodeBool, cardType} = this.state;
        e.preventDefault();
        const {form} = this.state;
        const query = new URLSearchParams(window.location.search);
        const card = query.get('card');
        form.paymentType = Number(query.get('paymentType'));
        form.creditCardId = card ? Number(card) : undefined;
        let total = this.state.resultInfo?.totalPriceWithoutPromo
        if (form.paymentType === PaymentTypeEnum.Ameria && visaPromocodeBool && CardTypeEnum.Visa === cardType) {
            total = this.state.resultInfo?.totalPrice
        }
        this.safeSetState({submitLoading: true, form}, async () => {
            const result = await OrderController.Create(form);
            if (result.success) {
                const data = {
                    value: total,
                    shipping: form.addressText,
                    coupon: form.promoCode,
                    items: this.state.data?.items
                }
                const newData = this.state.data?.items.filter(item => item.productId !== 1262);
                await GtagData(newData, "add_shipping_info")
                await GtagData(newData, "add_payment_info")
                this.gtagEvent("ecommerce", data);
                GtagData(newData, "purchase")
                this.safeSetState({submitLoading: false, idramNId: result.data.id});
                const finishState: Partial<IState> = {submitLoading: false};
                if (form.paymentType === PaymentTypeEnum.Idram) {
                    document.getElementById('currentId')?.click();
                }

                // if (form.paymentType === PaymentTypeEnum.Telcell) {
                //     this.addTelcellForm(result.data)
                // }

//                if (form.paymentType === PaymentTypeEnum.IPay && result.data.vposUrl) {
                if ((form.paymentType === PaymentTypeEnum.IPay || form.paymentType === PaymentTypeEnum.Ameria) && result.data.vposUrl) {
                    window.open(result.data.vposUrl, '_top');
                } else {
                    if (form.paymentType !== PaymentTypeEnum.Idram) {
                        window.location.href = Environment.SITE_URL.slice(0, -1) + ROUTES.THANK_FOR_PAYMENT
                    }
                }
                this.updateBasketCount()
            } else this.safeSetState({submitLoading: false});
        });
    };


    private gtagEvent = (eventName: string, data: object | null) => {
        window.gtag("event", eventName, data);
    }
    private deleteBasketItem = async (item: IBasketListResponseModel) => {
        const result = await BasketController.Delete(item.productId, item.isPackage);
        if (result.success) {
            this.updateBasketCount();
            this.fetchData();
        }
    };
    private updateBasketCount = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    private fetchData = async () => {
        if (this.state.resultInfo?.isBonusVisa === 1) {
            await this.sendPromoCode(Environment.PROMOCODE_NAME, true)
            this.safeSetState({inputBool: false});
        }
        const result = await BasketController.GetList();
        this.safeSetState({data: result.data}, async () => {
            const {data} = this.state;

            if (data) {
                const photoResult = await Promise.all(data.items.map(item => PhotoStorage.getURL(item.productPhoto).then(url => ({
                    ...item,
                    productPhoto: url
                }))));

                data.items = photoResult;
                this.safeSetState({data});
            }
        });
    };

    private changeCount = async (index: number, count: number) => {
        const {data} = this.state;
        let modifyResult: IResponse<IBasketChangeResponseModel>;
        if (data) {
            if (count) {
                Connection.AbortAll();
                modifyResult = await BasketController.Change({
                    productId: data.items[index].productId,
                    productQuantity: count,
                    isPackage: data.items[index].isPackage
                });
                data.items[index].productQuantity = count;
            } else {
                modifyResult = await BasketController.Delete(data.items[index].productId, data.items[index].isPackage);
                data && data.items.splice(index, 1);
                this.updateBasketCount();
            }

            if (modifyResult.data) {
                this.safeSetState({data: modifyResult.data});
                window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
            }
        }
    };

    private goToCheckout = async () => {
        const {data} = this.state;

        if (data) {
            const price = data && (data.totalDiscountedPrice || data?.totalPrice);

            if (data.items.some(item => !item.productStockQuantity)) this.safeSetState({outOfStockConfirm: true});
            else if (data && price < 2000) this.safeSetState({priceNotEnoughModalOpen: true});
            else window.routerHistory.push(`${ROUTES.CHECKOUT}?total=${price}`);
        }
    };

    private closeOutOfStockConfirm = () => this.safeSetState({outOfStockConfirm: false});
    private deleteOutOfStock = async () => {
        const {data} = this.state;

        if (data) {
            const updatingItems = data.items.filter(item => !item.productStockQuantity).map(item => ({
                productId: item.productId,
                productQuantity: 0,
                isPackage: item.isPackage
            }));

            const result = await BasketController.ChangeList(updatingItems);
            result.success && this.safeSetState({outOfStockConfirm: false}, () => {
                this.updateBasketCount();
                this.fetchData();
            });
        }
    };

    private deleteAll = async () => {
        const result = await BasketController.DeleteAll();
        if (result.data) {
            this.safeSetState({data: result.data});
            window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
        }
    };

    private saveCart = async () => {
        const {data} = this.state;

        if (data) {
            const alertify = await import('alertifyjs');
            const basketIds = data.items.map(item => item.id);
            const result = await BasketController.Save(basketIds);
            result.success && alertify.success(Settings.translations.basket_save_success);
        }
    };

    private togglePriceNotEnoughModal = () => {
        const {priceNotEnoughModalOpen} = this.state;
        this.safeSetState({priceNotEnoughModalOpen: !priceNotEnoughModalOpen});
    };

    private setPromoValid = (res?: any, promo = false) => {
        this.safeSetState({visaPromocodeMessage: false})
        this.getResultInfo(0, true)
        this.safeSetState({promoResponseText: "promoNotValid"})
        const {promoValid} = this.state;
        res && res.data && res.data !== responseEnum.success && this.safeSetState(
            res.data === responseEnum.invalid_card ? {promoMessage: res.data} :
                res.data === responseEnum.no_such_promo ? {promoMessage: res.data} :
                    res.data === responseEnum.card_missing ? {promoMessage: res.data} :
                        res.data === responseEnum.promo_limit_expired ? {promoMessage: res.data} :
                            res.data === responseEnum.does_not_make_sense ? {promoMessage: res.data} :
                                res.data === responseEnum.promo_percent_is_smoller ? {promoMessage: res.data} :
                                    res.data === responseEnum.delevery_does_not_make_sense ? {promoMessage: res.data} :
                                        res.data === responseEnum.not_valid_time ? {promoMessage: res.data} :
                                            res.data === responseEnum.not_valid_promo_limit_expiered ? {promoMessage: res.data} :
                                                res.data === responseEnum.card_limit_expired ? {promoMessage: res.data} : {promoMessage: ""});
        this.safeSetState({promoValid: !promoValid});
        if (promo) {
            this.safeSetState({promoValid: false});
            this.safeSetState({promoMessage: ""})
            this.safeSetState({visaPromocodeBool: false})
        }
    };


    private succesPromoValid = (val: any, promo = false) => {
        const {isUsingBonus, form} = this.state;
        if (promo) {
            this.safeSetState({visaPromocodeMessage: true})
            form.promoCode = Environment.PROMOCODE_NAME
            this.safeSetState({form})
            this.safeSetState({promoResponseText: ""})
            this.safeSetState({promoMessage: ""})
        }
        this.getResultInfo(form.useBonusAmount === 0 ? 0 : form.useBonusAmount);
        this.safeSetState({promoResponseText: val})
        if (form.promoCode !== Environment.PROMOCODE_NAME) {
            this.safeSetState({promoCode: true})
        }
    }

    private toggleAuthModal = () => {
        const {authModalOpen} = this.state;
        this.safeSetState({successModalOpen: false, authModalOpen: !authModalOpen});
    };

    private navigateToHome = () => window.routerHistory.push(ROUTES.HOME);
    private toggleAuth = () => {
        const {authOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen,
        });
    };
    private toggleShowPersonalInfo = () => {
        const {showPersonalInfo} = this.state;
        this.safeSetState({
            showPersonalInfo: !showPersonalInfo,
        });
    }
    private clearPromocode = () => {
        const {form} = this.state;
        this.state.form.promoCode = "";
        this.safeSetState({form});
        this.safeSetState({promoResponseText: "", cleared: true})
        this.getResultInfo()
    }

    public render() {
        const {
            data,
            promoValid,
            promoMessage,
            outOfStockConfirm,
            cardType,
            form,
            dateType,
            addressList,
            submitLoading,
            resultInfo,
            bonusDetails,
            chosenBranch,
            choosePharmacyOpen,
            chooseAddressOpen,
            authModalOpen,
            successModalOpen,
            initialTotalDiscountedPrice,
            isUsingBonus,
            isPayment,
            isFinishPage,
            promocodeMessage,
            visaPromocodeMessage,
            authOpen,
            smallWindow,
            showPersonalInfo,
            showMyAddresses,
            inputBool,
            visaPromocodeBool,
            visaPromocodeMessageGuest,
            promoCode,
            visaPromoTotal,
            visaPromoBonus,
            cleared,
            anotherPromo
        } = this.state;
        const query = new URLSearchParams(window.location.search);
        const paymentType = query.get('paymentType');
        // @ts-ignore
        // @ts-ignore
        // kody groxy kap chuni kesic popoxutyunner en uzel))))
        // @ts-ignore
        return (
            <>
                <section className="P-Step-Wrapper G-mt-30">
                    <div className="P-steps G-mb-30">
                        <p className="P-disabled">1</p>
                        <span/>
                        <p className={!isPayment ? '' : 'P-disabled'}>2</p>
                        <span/>
                        <p className={!isPayment ? 'P-disabled' : ''}>3</p>
                    </div>
                </section>
                <section className="G-page P-checkout-page">
                    <div className="P-cart-left-box">
                        {!isPayment ?
                            <h1 className="G-page-title">{Settings.translations.order_checkout}</h1>
                            :
                            <h1 className="G-page-title">{Settings.translations.payment_method}</h1>
                        }

                        {!isPayment ? <div className="G-main-form">
                                {!smallWindow &&
                                    <>
                                        {!Settings.guest && <LoaderContent
                                            className="P-select-address G-mb-20"
                                            loading={false}
                                            onClick={this.openAddressChoose}
                                            id={'chose_address_btn'}
                                        >{Settings.translations.choose_address}</LoaderContent>}
                                    </>
                                }
                                {chooseAddressOpen &&
                                    <ChooseAddress selectedId={form.userAddressId} data={addressList}
                                                   onClose={this.closeAddressChoose}/>}
                                <form className="P-checkout-form">
                                    <div className="P-main-info G-half-width">
                                        {!smallWindow ?
                                            <>
                                                <div className="G-main-form-field">
                                                    <input
                                                        name="firstName"
                                                        value={form.firstName || ''}
                                                        className={`G-main-input ${this.formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
                                                        placeholder={Settings.translations.first_name}
                                                        onChange={this.changeField}
                                                    />
                                                </div>
                                                <div className="G-main-form-field">
                                                    <input
                                                        name="lastName"
                                                        value={form.lastName || ''}
                                                        className={`G-main-input ${this.formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
                                                        placeholder={Settings.translations.last_name}
                                                        onChange={this.changeField}
                                                    />
                                                </div>
                                                <div className="G-main-form-field G-phone-input-wrapper">
                                                    <p className="G-input-country-code">+{countryCode}</p>
                                                    <input
                                                        name="phoneNumber"
                                                        value={form.phoneNumber || ''}
                                                        className={`G-main-input P-input-phone ${this.formValidation.errors.phoneNumber ? 'G-invalid-field' : ''}`}
                                                        placeholder={Settings.translations.phone_number}
                                                        onChange={this.changeField}/>
                                                </div>
                                                <div className="G-main-form-field">
                                                    <input
                                                        name="email"
                                                        value={form.email || ''}
                                                        className={`G-main-input ${this.formValidation.errors.email ? 'G-invalid-field' : ''}`}
                                                        placeholder={Settings.translations.email}
                                                        onChange={this.changeField}
                                                    />
                                                </div>
                                                <div className="G-main-form-field G-phone-input-wrapper P-checkout-select">
                                                    <Select<OrderDeliveryTypeEnum>
                                                        options={OrderDeliveryTypeDropdown()}
                                                        className="G-main-select P-checkout-select-item"
                                                        value={form.deliveryType}
                                                        onChange={this.changeDeliveryType}
                                                    />
                                                </div>
                                                <div className="G-main-form-field G-phone-input-wrapper P-checkout-select">
                                                    <Select<OrderDeliveryTimeTypeEnum>
                                                        options={OrderDeliveryTimeTypeDropdown()}
                                                        className="G-main-select P-checkout-select-item"
                                                        value={dateType}
                                                        onChange={this.changeDeliveryTimeType}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <div className={`personal-info-wrapper step-content`}>
                                                    <h3 onClick={this.toggleShowPersonalInfo}>
                                                        <span>{Settings.translations.personal_data}</span>
                                                        <img className={`${showPersonalInfo ? 'arrow-up' : 'arrow-down'}`}
                                                             src={Arrow} alt="arrow"/>
                                                    </h3>
                                                    {showPersonalInfo && <>
                                                        <div className="G-main-form-field">
                                                            <input
                                                                name="firstName"
                                                                value={form.firstName || ''}
                                                                className={`G-main-input ${this.formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
                                                                placeholder={Settings.translations.first_name}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div className="G-main-form-field">
                                                            <input
                                                                name="lastName"
                                                                value={form.lastName || ''}
                                                                className={`G-main-input ${this.formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
                                                                placeholder={Settings.translations.last_name}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div className="G-main-form-field G-phone-input-wrapper">
                                                            <p className="G-input-country-code">+{countryCode}</p>
                                                            <input
                                                                name="phoneNumber"
                                                                value={form.phoneNumber || ''}
                                                                className={`G-main-input P-input-phone ${this.formValidation.errors.phoneNumber ? 'G-invalid-field' : ''}`}
                                                                placeholder={Settings.translations.phone_number}
                                                                onChange={this.changeField}/>
                                                        </div>
                                                        <div className="G-main-form-field">
                                                            <input
                                                                name="email"
                                                                value={form.email || ''}
                                                                className={`G-main-input ${this.formValidation.errors.email ? 'G-invalid-field' : ''}`}
                                                                placeholder={Settings.translations.email}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div
                                                            className="G-main-form-field G-phone-input-wrapper P-checkout-select">
                                                            <Select<OrderDeliveryTypeEnum>
                                                                options={OrderDeliveryTypeDropdown()}
                                                                className="G-main-select P-checkout-select-item"
                                                                value={form.deliveryType}
                                                                onChange={this.changeDeliveryType}
                                                            />
                                                        </div>
                                                        <div
                                                            className="G-main-form-field G-phone-input-wrapper P-checkout-select">
                                                            <Select<OrderDeliveryTimeTypeEnum>
                                                                options={OrderDeliveryTimeTypeDropdown()}
                                                                className="G-main-select P-checkout-select-item"
                                                                value={dateType}
                                                                onChange={this.changeDeliveryTimeType}
                                                            />
                                                        </div>
                                                    </>
                                                    }

                                                </div>
                                                <div className={`step-content`}>
                                                    <h3>{Settings.translations.delivery_method}</h3>
                                                    <div className={`web-app-fields-wrapper `}>
                                                        <div
                                                            className={`G-main-form-field web-app-fields ${form.deliveryType === OrderDeliveryTypeEnum.Delivery ? 'active' : ''}`}>
                                                            <input className={`G-main-input`} type="radio"
                                                                   name={'deliveryType'}
                                                                   value={OrderDeliveryTypeEnum.Delivery}
                                                                   onChange={() => this.changeDeliveryTypeRadio(OrderDeliveryTypeEnum.Delivery)}/>
                                                            <img src={LocationHomeIcon} alt="delivery address"/>
                                                            {Settings.translations.delivery}
                                                        </div>
                                                        <div
                                                            className={`G-main-form-field web-app-fields ${form.deliveryType === OrderDeliveryTypeEnum.Pickup ? 'active' : ''}`}>
                                                            <input className={`G-main-input`} type="radio"
                                                                   name={'deliveryType'}
                                                                   value={OrderDeliveryTypeEnum.Pickup}
                                                                   onChange={() => this.changeDeliveryTypeRadio(OrderDeliveryTypeEnum.Pickup)}/>
                                                            <img src={TruckIcon} alt="pickup"/>
                                                            {Settings.translations.pickup}
                                                        </div>
                                                    </div>
                                                    {!Settings.guest && showMyAddresses && <LoaderContent
                                                        className="P-select-address G-mb-20"
                                                        loading={false}
                                                        onClick={this.openAddressChoose}
                                                        id={'chose_address_btn'}
                                                    >{Settings.translations.choose_address}</LoaderContent>}
                                                    {form.deliveryType === OrderDeliveryTypeEnum.Delivery &&
                                                        <>
                                                            <Addresses
                                                                defaultValue={form.addressText || ''}
                                                                onChange={this.addressChange}
                                                                onPlaceSelect={this.addressSelect}
                                                                placeholder={Settings.translations.address}
                                                                className={`${this.formValidation.errors.address ? 'G-invalid-field' : ''}`}
                                                            />

                                                            <div className="G-main-form-field G-main-form-field-closer">
                                                                <input
                                                                    name="addressBuilding"
                                                                    value={form.addressBuilding || ''}
                                                                    className="G-main-input"
                                                                    placeholder={Settings.translations.building}
                                                                    onChange={this.changeField}
                                                                />
                                                            </div>
                                                            <div className="G-main-form-field G-main-form-field-closer">
                                                                <input
                                                                    name="addressEntrance"
                                                                    value={form.addressEntrance || ''}
                                                                    className="G-main-input"
                                                                    placeholder={Settings.translations.entrance}
                                                                    onChange={this.changeField}
                                                                />
                                                            </div>
                                                            <div className="G-main-form-field G-main-form-field-closer">
                                                                <input
                                                                    name="addressApartment"
                                                                    value={form.addressApartment || ''}
                                                                    className="G-main-input"
                                                                    placeholder={Settings.translations.apartment}
                                                                    onChange={this.changeField}
                                                                />
                                                            </div>
                                                            <div className="G-main-form-field G-main-form-field-closer">
                                                                <NumberInput
                                                                    max={50}
                                                                    value={form.addressFloor || ''}
                                                                    className="G-main-input"
                                                                    placeholder={Settings.translations.floor}
                                                                    onChange={this.changeField}
                                                                    name="addressFloor"
                                                                />
                                                            </div>
                                                        </>
                                                    }


                                                    {form.deliveryType === OrderDeliveryTypeEnum.Pickup &&
                                                        <div className="G-main-form-field P-relative">
                                                            <input
                                                                value={chosenBranch ? chosenBranch.name : ''}
                                                                readOnly={true}
                                                                className={`G-main-input G-cursor-pointer ${this.formValidation.errors.branchId ? 'G-invalid-field' : ''}`}
                                                                placeholder={Settings.translations.choose_pharmacy}
                                                                onClick={this.openPharmacyChoose}
                                                            />
                                                            <img src={LocationImage} onClick={this.openPharmacyChoose}
                                                                 className="G-cursor-pointer P-location-image" alt=""/>
                                                        </div>}
                                                </div>

                                            </>
                                        }
                                        {!smallWindow && <>
                                            {dateType === OrderDeliveryTimeTypeEnum.DeliveryDate &&
                                                <div className="P-delivery-date G-flex G-align-center">
                                                    <div className="G-main-form-half-field">
                                                        <DateTime
                                                            onChange={this.dateFromChange}
                                                            isValidDate={this.validateDeliveryDate}
                                                            inputProps={{
                                                                value: form.deliveryDateFrom ? formatDate(form.deliveryDateFrom, true) : '',
                                                                readOnly: true,
                                                                className: `G-main-input ${this.formValidation.errors.deliveryDateFrom ? 'G-invalid-field' : ''}`,
                                                                placeholder: Settings.translations.choose_date
                                                            }}
                                                        />
                                                    </div>
                                                </div>}
                                        </>
                                        }
                                    </div>
                                    <div className="P-delivery-form G-half-width G-ml-20">
                                        {!smallWindow ?
                                            <>
                                                {form.deliveryType === OrderDeliveryTypeEnum.Delivery &&
                                                    <>
                                                         <Addresses
                                                            defaultValue={form.addressText || ''}
                                                            onChange={this.addressChange}
                                                            onPlaceSelect={this.addressSelect}
                                                            placeholder={Settings.translations.address}
                                                            className={`${this.formValidation.errors.address ? 'G-invalid-field' : ''}`}
                                                        />
                                                        <div className="G-main-form-field G-main-form-field-closer">
                                                            <input
                                                                name="addressBuilding"
                                                                value={form.addressBuilding || ''}
                                                                className="G-main-input"
                                                                placeholder={Settings.translations.building}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div className="G-main-form-field G-main-form-field-closer">
                                                            <input
                                                                name="addressEntrance"
                                                                value={form.addressEntrance || ''}
                                                                className="G-main-input"
                                                                placeholder={Settings.translations.entrance}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div className="G-main-form-field G-main-form-field-closer">
                                                            <input
                                                                name="addressApartment"
                                                                value={form.addressApartment || ''}
                                                                className="G-main-input"
                                                                placeholder={Settings.translations.apartment}
                                                                onChange={this.changeField}
                                                            />
                                                        </div>
                                                        <div className="G-main-form-field G-main-form-field-closer">
                                                            <NumberInput
                                                                max={50}
                                                                value={form.addressFloor || ''}
                                                                className="G-main-input"
                                                                placeholder={Settings.translations.floor}
                                                                onChange={this.changeField}
                                                                name="addressFloor"
                                                            />
                                                        </div>
                                                    </>
                                                }


                                                {form.deliveryType === OrderDeliveryTypeEnum.Pickup &&
                                                    <div className="G-main-form-field P-relative">
                                                        <input
                                                            value={chosenBranch ? chosenBranch.name : ''}
                                                            readOnly={true}
                                                            className={`G-main-input G-cursor-pointer ${this.formValidation.errors.branchId ? 'G-invalid-field' : ''}`}
                                                            placeholder={Settings.translations.choose_pharmacy}
                                                            onClick={this.openPharmacyChoose}
                                                        />
                                                        <img src={LocationImage} onClick={this.openPharmacyChoose}
                                                             className="G-cursor-pointer P-location-image" alt=""/>
                                                    </div>}
                                            </>
                                            : ''}
                                        {smallWindow &&
                                            <div className={`date-of-order-wrapper step-content`}>
                                                <h3>{Settings.translations.date_of_order}</h3>
                                                <div className={`web-app-fields-wrapper`}>
                                                    <div
                                                        className={`G-main-form-field web-app-fields ${dateType === OrderDeliveryTimeTypeEnum.Asap ? 'active' : ''}`}>
                                                        <input className={`G-main-input`} type="radio" name={'deliveryTime'}
                                                               value={OrderDeliveryTimeTypeEnum.Asap}
                                                               onChange={() => this.changeDeliveryTimeTypeRadio(OrderDeliveryTimeTypeEnum.Asap)}/>
                                                        {Settings.translations.asap}
                                                    </div>
                                                    <div
                                                        className={`G-main-form-field web-app-fields ${dateType === OrderDeliveryTimeTypeEnum.DeliveryDate ? 'active' : ''}`}>
                                                        <input className={`G-main-input`} type="radio" name={'deliveryTime'}
                                                               value={OrderDeliveryTimeTypeEnum.DeliveryDate}
                                                               onChange={() => this.changeDeliveryTimeTypeRadio(OrderDeliveryTimeTypeEnum.DeliveryDate)}/>
                                                        {Settings.translations.delivery_date}
                                                    </div>
                                                </div>
                                                {dateType === OrderDeliveryTimeTypeEnum.DeliveryDate &&
                                                    <div className="P-delivery-date G-flex G-align-center">
                                                        <div className="G-main-form-half-field date-wrapper">
                                                            <DateTime
                                                                onChange={this.dateFromChange}
                                                                isValidDate={this.validateDeliveryDate}
                                                                inputProps={{
                                                                    value: form.deliveryDateFrom ? formatDate(form.deliveryDateFrom, true) : '',
                                                                    readOnly: true,
                                                                    className: `G-main-input ${this.formValidation.errors.deliveryDateFrom ? 'G-invalid-field' : ''}`,
                                                                    placeholder: Settings.translations.choose_date
                                                                }}
                                                            />
                                                        </div>
                                                    </div>}
                                            </div>
                                        }
                                        <div className="G-main-form-field P-comment-field step-content">
                                    <textarea
                                        name="comment"
                                        value={form.comment || ''}
                                        className="G-main-input P-form-comment"
                                        placeholder={Settings.translations.comment}
                                        onChange={this.changeField}
                                    />
                                        </div>
                                    </div>
                                </form>
                            </div> :
                            <div>
                                <PaymentMethod resultInfo={resultInfo} form={form}
                                               callback={(e: React.SyntheticEvent) => this.finishCheckout(e)}
                                               setCardType={(e: number) => this.setState({cardType: e})}/>
                            </div>
                        }

                        {
                            successModalOpen && <SuccessModal onClose={this.navigateToHome}>
                                {Settings.guest ? <>
                                    <h3>{Settings.translations.guest_order_success}</h3>
                                    <button id={'sign_up_btn'}
                                            className="G-main-button G-normal-link G-mt-30 P-register-button"
                                            onClick={this.toggleAuthModal}>{Settings.translations.sign_up}</button>
                                </> : <>
                                    <h3>{Settings.translations.order_success}</h3>
                                    <Link className="G-main-button G-normal-link G-mt-30"
                                          to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                                </>}
                            </SuccessModal>
                        }
                        {authModalOpen && <Shared.Auth onClose={this.toggleAuthModal}/>}
                        {choosePharmacyOpen && <ChoosePharmacy onClose={this.choosePharmacy}/>}
                        <form action="https://money.idram.am/payment.aspx" method="POST">
                            <input type="hidden" name="EDP_LANGUAGE" value="EN"/>
                            <input type="hidden" name="EDP_REC_ACCOUNT" value="110000601"/>
                            <input type="hidden" name="EDP_DESCRIPTION" value="Order description"/>
                            <input type="hidden" name="EDP_AMOUNT"
                                // value={this.state.form.deliveryType === OrderDeliveryTypeEnum.Delivery ? this.state.resultInfo?.totalPrice : this.state.resultInfo?.totalDiscountedPrice || ''}/>
                                   value={this.state.form.deliveryType === OrderDeliveryTypeEnum.Delivery ? this.state.resultInfo?.totalPrice : this.state.resultInfo?.totalDiscountedPrice || ''}/>
                            <input type="hidden" name="EDP_BILL_NO" value={this.state.idramNId || ""}/>
                            <input type="submit" value="submit" id="currentId" className="G-dn"/>
                        </form>
                    </div>
                    <div className="P-cart-right-box">
                        <div className={`P-data-block-wrapper ${isPayment ? 'P-data-block-finish' : ''}`}>
                            {bonusDetails && <div className="G-main-form-field P-bonus-field G-mb-25">
                                <CheckBox checked={isUsingBonus} onClick={this.toggleUsingBonus}/>
                                <span className="G-ml-12">{Settings.translations.use_bonus_points}</span>
                                {isUsingBonus && <>
                                <span
                                    className="G-ml-auto G-text-bold G-clr-orange P-bonus-point-checked">{bonusDetails.bonusCardDetails.amount}</span>
                                    <NumberInput
                                        max={Math.min(initialTotalDiscountedPrice, bonusDetails.bonusCardDetails.amount)}
                                        value={form.useBonusAmount || ''}
                                        className="G-main-input G-full-width G-mt-20"
                                        onChange={this.bonusChange}
                                    />
                                </>}
                            </div>}

                            {resultInfo && <>
                                <h3 className="G-mb-25 G-mt-auto G-text-semi-bold G-flex G-flex-justify-between">{Settings.translations.price}
                                    <span
                                        className="G-text-semi-bold">{formatPrice(resultInfo.totalDiscountedPriceWithoutPromo)}</span>
                                </h3>
                                {!isFinishPage ? <>
                                    {<h3
                                        className="G-clr-green G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">{Settings.translations.gained_bonus}

                                        <span
                                            className="G-clr-green G-text-semi-bold">
                                            {
                                                visaPromocodeMessage && promoCode ?
                                                   Number(form?.useBonusAmount) > resultInfo.receivedBonus ? '0 ' : resultInfo.receivedBonus :
                                                   Number(form?.useBonusAmount) > resultInfo.receivedBonusWithoutPromo ? '0 ' :  resultInfo.receivedBonusWithoutPromo
                                            }
                                        {Settings.translations.point}
                                        </span>

                                    </h3>}
                                    {resultInfo.isBonus > 0 &&
                                        <h3 className="G-clr-green G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span
                                        className="G-clr-green G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_master_card}
                                        <img src={MasterCard} className="logo-image" width="30" alt="MASTER CARD"/>
                                    </span>
                                            <span
                                                className="G-clr-green G-text-semi-bold master-card-bonus">{resultInfo.receivedBonus * resultInfo.multiply} {Settings.translations.point}</span>
                                        </h3>}
                                    {visaPromocodeMessage &&
                                        <h3 className="G-clr-orange G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span
                                        className="G-clr-orange G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_visa_card}<br/>
                                        <img src={VisaCard} className="logo-image" width="50" alt="VISA CARD"/>
                                    </span>
                                            <span
                                                className="G-clr-orange G-text-semi-bold master-card-bonus">{formatPrice(visaPromoTotal)}</span>
                                        </h3>}
                                </> : <>
                                    <h3
                                        className="G-clr-green G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">{Settings.translations.gained_bonus}

                                        <span
                                            className="G-clr-green G-text-semi-bold">{
                                            visaPromocodeMessage && (visaPromocodeMessage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && !cleared) || promoCode && !cleared ?
                                                Number(form?.useBonusAmount) > resultInfo.receivedBonus ? '0 ' : resultInfo.receivedBonus :
                                                (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && cleared) ?
                                                    visaPromoBonus :
                                                    Number(form?.useBonusAmount) > resultInfo.receivedBonusWithoutPromo ? '0 ' :  resultInfo.receivedBonusWithoutPromo} {Settings.translations.point
                                        }</span>
                                    </h3>
                                    {resultInfo.isBonus > 0 && cardType === CardTypeEnum.MasterCard &&
                                        <h3 className="G-clr-green G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span
                                        className="G-clr-green G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_master_card}
                                        <img src={MasterCard} className="logo-image" width="30" alt="MASTER CARD"/>
                                    </span>
                                            <span
                                                className="G-clr-green G-text-semi-bold master-card-bonus">{resultInfo.receivedBonus * resultInfo.multiply} {Settings.translations.point}</span>
                                        </h3>}
                                    {visaPromocodeMessage &&
                                        <h3 className="G-clr-orange G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span
                                        className="G-clr-orange G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_visa_card}
                                        <br/>
                                        <img src={VisaCard} className="logo-image" width="50" alt="VISA CARD"/>
                                    </span>
                                            <span
                                                className="G-clr-orange G-text-semi-bold master-card-bonus">{formatPrice(visaPromoTotal)}</span>
                                        </h3>}
                                </>
                                }

                                {!!form.useBonusAmount &&
                                    <h3 className="G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">{Settings.translations.used_bonus}
                                        <span className="G-text-semi-bold">{form.useBonusAmount}</span></h3>}
                                {form.deliveryType === OrderDeliveryTypeEnum.Delivery ?
                                    <div>
                                        <h3 className="G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">{Settings.translations.delivery}
                                            <span
                                                className="G-text-semi-bold">{formatPrice(resultInfo.deliveryFee)}</span>
                                        </h3>
                                        <h2 className="G-mt-10 G-flex G-fs-22 G-flex-justify-between G-clr-orange">{Settings.translations.total}
                                            <span className="G-fs-22">{
                                                (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && !cleared) || promoCode && Storage.profile && !cleared ?
                                                    formatPrice(resultInfo.totalPrice) :
                                                    (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && cleared) ?
                                                        formatPrice(visaPromoTotal + resultInfo.deliveryFee) :
                                                        formatPrice(resultInfo.totalPriceWithoutPromo)
                                            }</span>
                                        </h2></div> :
                                    <div>
                                        {/*<h3 className="G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">{Settings.translations.delivery}*/}
                                        {/*    <span className="G-text-semi-bold">{formatPrice(resultInfo.deliveryFee)}</span></h3>*/}

                                        <h2 className="G-mt-10 G-flex G-fs-22 G-flex-justify-between G-clr-orange">{Settings.translations.total}
                                            <span
                                                className="G-fs-22">{
                                                (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && !cleared) || promoCode && Storage.profile && !cleared ?
                                                    formatPrice(resultInfo.totalDiscountedPrice) :
                                                    (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && cleared) ?
                                                        formatPrice(visaPromoTotal + resultInfo.deliveryFee) :
                                                        formatPrice(resultInfo.totalDiscountedPriceWithoutPromo)
                                            } </span>
                                        </h2>
                                    </div>}
                                {visaPromocodeMessage && visaPromocodeMessageGuest && !Storage.profile && isFinishPage && Number(paymentType) === PaymentTypeEnum.Ameria && cardType === CardTypeEnum.Visa &&
                                    <p className={'promocodeMessage'}>{Settings.translations.accumulative_bonus_visa_card_guest}<a
                                        onClick={this.toggleAuth}>{Settings.translations.promocode_message_2}</a>{Settings.translations.promocode_message_3}
                                    </p>}
                            </>}
                            <div>
                                {window.location.href.includes('success')
                                    ? <SuccessModal onClose={() => window.location.href = '/'}>
                                        <h3>{Settings.translations.order_success}</h3>
                                        <Link className="G-main-button G-normal-link G-mt-30"
                                              to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                                    </SuccessModal>
                                    : window.location.href.includes('fail')
                                        ? <SuccessModal onClose={() => window.location.href = '/'} bool1={true}
                                                        bool2={true}>
                                            <h3>{Settings.translations.order_fail}</h3>
                                        </SuccessModal> : null
                                }
                                {data && data.items.length ?
                                    <div>
                                        {/*<div className="P-data-block G-flex-column">*/}
                                        {/*    <div className="G-flex G-flex-justify-between G-flex-align-center G-mb-25">*/}
                                        {/*        <span className="G-fs-normal G-text-bold">{Settings.translations.total}</span>*/}
                                        {/*        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">*/}
                                        {/*            {!!data.totalDiscountedPrice && data.totalDiscountedPrice !== data.totalPrice &&*/}
                                        {/*            <del>{formatPrice(data.totalPrice)}</del>}*/}
                                        {/*            <h1*/}
                                        {/*                className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.totalDiscountedPrice || data.totalPrice)}</h1>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="G-flex G-flex-justify-between G-flex-align-center G-mb-25">*/}
                                        {/*        <span className="G-fs-normal G-clr-main G-text-bold">{Settings.translations.accumulative_bonus}</span>*/}
                                        {/*        <h1 className="G-clr-main G-text-bold G-fs-normal G-mt-5">{data.totalBonus} {Settings.translations.point}</h1>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div> : <></>
                                }
                                {promoValid && !visaPromocodeBool && <PromoNotValid onClose={this.setPromoValid}
                                                                                    promoMessage={this.state.promoMessage}/>}
                                {!isPayment && <div>
                                    <h3 className="G-text-semi-bold G-mb-15_promo G-mt-50_promo G-flex G-flex-justify-between">{Settings.translations.promoCode}</h3>
                                    <div className="promoDiv_wrap">
                                        {this.state.promoResponseText === 0 && !visaPromocodeBool ?
                                            <div className={"iconPromo"}><img src={Yes_Promo} alt=""/>
                                            </div> : this.state.promoResponseText === "promoNotValid" && visaPromocodeBool ?
                                                <div className={"iconPromo"}><img src={No_Promo} alt=""/></div> : null}

                                        <div className={'promoDiv'}>
                                            <input type={'text'}
                                                   value={promocodeMessage || visaPromocodeBool ? '' : form.promoCode || ''}
                                                   className="G-main-input promoInput"
                                                   placeholder={Settings.translations.promo_code}
                                                   onChange={(event) => this.changeField(event, true)}
                                                   name="promoCode"
                                                   disabled={promocodeMessage}
                                            />
                                            {promocodeMessage &&
                                                <p className={'promocodeMessage'}>{Settings.translations.promocode_message_1}<a
                                                    onClick={this.toggleAuth}>{Settings.translations.promocode_message_2}</a>{Settings.translations.promocode_message_3}
                                                </p>
                                            }
                                        </div>
                                        {(!this.state.promoResponseText && typeof this.state.promoResponseText !== "number") || !inputBool ?
                                            <LoaderContent
                                                className="G-main-button bot arrow promoBtn"
                                                loading={submitLoading}
                                                onClick={() => this.sendPromoCode(this.state.form.promoCode)}
                                                id={'aply_btn'}
                                            >{Settings.translations.aply}</LoaderContent> :
                                            <div className="x_promoBtn" onClick={this.clearPromocode}>
                                                <img src={x_promo} alt=""/>
                                            </div>}
                                    </div>
                                </div>}
                                <div>
                                    <LoaderContent
                                        className="G-main-button P-pay-button bot"
                                        loading={submitLoading}
                                        id="order_process_end_btn"
                                        onClick={!isPayment ? this.submit : this.finishCheckout}
                                    >{!isPayment ? Settings.translations.next_step : Settings.translations.pay}</LoaderContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
            </>
        );
    }
};
export default Checkout;
