import * as React from 'react';

import Modal from '../modal';
// import bannerDesktopImage from '../../assets/images/bannerImage.jpg'
import bannerDesktopImage from '../../assets/images/bannerImageNew.jpg'
import visaCardBig from '../../assets/images/VisaCardBig.jpg'
import visaCardSmall from '../../assets/images/VisaCardSmall.jpg'
import bannerDesktopVisaImage from '../../assets/images/bannerVisaImage.jpg'
import bannerSmallDesktopVisaImage from '../../assets/images/bannerVisaSmallImage.jpg'
// import bannerSmallDesktopImage from '../../assets/images/bannerSmallImage.jpg'
import bannerSmallDesktopImage from '../../assets/images/bannerSmallImageNew.jpg'
import visaBig from '../../assets/images/visa_big.jpg'
import ArtboardBig from '../../assets/images/atboard_big.png'
import visaSmall from '../../assets/images/visa_small.jpg'
import ArtboardSmall from '../../assets/images/atboard_small.png'
import Screen from 'components/screen';
import './style-visa.scss';
import './style.scss';
import { Link } from 'react-router-dom';
import ClickOutside from '../click-outside';
import simLogo from "../../assets/images/cards/sim.svg";


interface IProps {
  onClose(): void;
  children?: string | React.ReactNode;
  link?: {
    path: string;
    name: string;
  };
  bool1?: boolean;

  bool2?: boolean;
};
const BannerModal = React.memo(({ onClose, children, link ,bool1,bool2}: IProps) => (
  <Modal onClose={onClose} className=" P-banner-modal-visa" >
   {/*<Modal onClose={onClose} className=" P-banner-modal" >*/}
   {/*    <ClickOutside >*/}
   {/*        {!bool1 && <i className="icon-Group-5535"/>}*/}
   {/*        <Screen.Min_Tablet>*/}
   {/*            {bool2 && <img style={{width:'100%',height:'100%',objectFit: 'cover'}} src={bannerDesktopImage}/>}*/}
   {/*        </Screen.Min_Tablet>*/}
   {/*        <Screen.Tablet>*/}
   {/*            {bool2 && <img style={{width:'100%',height:'100%',objectFit: 'cover'}} src={bannerSmallDesktopImage}/>}*/}
   {/*        </Screen.Tablet>*/}
   {/*        {children}*/}
   {/*        {!!link && <Link to={link.path} className="G-main-button P-link-item G-full-width G-no-text-decoration G-text-center">{link.name}</Link>}*/}
   {/*    </ClickOutside>*/}
      <ClickOutside >
          {!bool1 && <i className="icon-Group-5535"/>}
          <Screen.Min_Tablet>
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${visaBig}')` }} />}*/}
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${ArtboardBig}')` }} />}*/}
              {bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${bannerDesktopImage}')` }} />}
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${visaCardBig}')` }} />}*/}
          </Screen.Min_Tablet>
          <Screen.Tablet>
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${visaSmall}')` }} />}*/}
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${ArtboardSmall}')` }} />}*/}
              {bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${bannerSmallDesktopImage}')` }} />}
              {/*{bool2 &&  <div className={`image`} style={{ backgroundImage: `url('${visaCardSmall}')` }} />}*/}
          </Screen.Tablet>
          {children}
          {!!link && <Link to={link.path} className="G-main-button P-link-item G-full-width G-no-text-decoration G-text-center">{link.name}</Link>}
      </ClickOutside>
  </Modal>
));

export default BannerModal;
