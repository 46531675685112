import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import ProductController from 'platform/api/product';
import {IProductDetailsResponseModel} from 'platform/api/product/models/response';
import Info from './components/info';
import Images from './components/images';
import Bundles from './components/bundles';
import Similar from './components/similar';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';

import './style.scss';
import Settings from "../../../../../platform/services/settings";
import GtagData from "../../../../../platform/services/gtagData";


interface IRouteParams {
    id: string,
    slug: string
}

interface IState {
    data?: IProductDetailsResponseModel;
}

type IProps = RouteComponentProps<IRouteParams>;

@generic<IProps>(withRouter)
@byRoute(ROUTES.PRODUCTS.DETAILS)
class Details extends HelperComponent<IProps, {}> {

    public state: IState = {};

    private goBack = () => window.routerHistory.goBack();
    private navigateToCategory = async () => {
        const {id} = this.props.match.params;
        const {slug} = this.props.match.params;
        const result = await ProductController.GetDetails(+id,slug);
        const query = new URLSearchParams(window.location.search);
        query.set('categoryIds', `${result.data.category.id}`);
        window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
    }

    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: IProps) {
        const {id} = this.props.match.params;
        id !== prevProps.match.params.id && this.safeSetState({data: undefined}, this.fetchData);
    }

    private fetchData = async () => {
        const {id} = this.props.match.params;
        const {slug} = this.props.match.params;
        const result = await ProductController.GetDetails(+id,slug);
        if (result.data.id !== 1262 && result.data.stockQuantity){
            GtagData(result.data,"view_item")
        }
        this.safeSetState({data: result.data});
    }

    private changeDetails = (data: IProductDetailsResponseModel) => this.safeSetState({data});

    public render() {
        const {data} = this.state;

        return data ? (


                <section className="G-page P-product-details-page">
                    {data ? <>
                        <div>
                            <ul className="Breadcrumbs">
                                <li className='fromRoutes'><Link to={ROUTES.MN}> {Settings.translations.home}</Link>
                                </li>
                                <li className='fromRoutes'><Link
                                    to={ROUTES.PRODUCTS.MAIN}> {Settings.translations.products}</Link></li>
                                <li className='fromRoutes' onClick={this.navigateToCategory}>{data.category.name}</li>
                                <li className='activeRoute'>{data.title}</li>
                            </ul>
                        </div>

                        {window.routerHistory.length > 2 &&
                            <i className="G-back-icon icon-Group-5529" onClick={this.goBack}/>}
                        <div className="P-content">
                            {!!data.images.length && <Images data={data} onChange={this.changeDetails}/>}
                            <Info data={data} />
                            <Bundles data={data}/>
                        </div>
                        <Similar data={data}/>
                    </> : <PageLoader/>}
                </section>
        ) : <PageLoader/>;
    }
}

export default Details;
