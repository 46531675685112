import * as React from 'react';
import { Link } from 'react-router-dom';

import Settings from 'platform/services/settings';
import ROUTES from 'platform/constants/routes';
import { IProductListResponseModel } from 'platform/api/product/models/response';
import { getMediaPath, truncateText, formatPrice } from 'platform/services/helper';
import DiscountLabel from '../discount-label';
import FavoriteController from 'platform/api/favorite';
import BasketController from 'platform/api/basket';
import LoaderContent from 'components/loader-content';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import UserController from 'platform/api/user';
import ConfirmModal from 'components/confirm-modal';

import './style.scss';
import PhotoStorage from 'platform/services/photoStorage';
import { PromotionTypeEnum } from 'platform/constants/enums';
import SearchHistory from 'platform/services/searchHistory';
import GtagData from "../../../../../platform/services/gtagData";
import NewProduct from "../new-product";

interface IProps {
  data: IProductListResponseModel;
  specialProductId?: number | null;
  categoryName?: string | null;
}

const ListItem = React.memo((props: IProps) => {
  const [loadingImage, setLoadingImage] = React.useState('');
  const [data, setData] = React.useState(props.data);
  const [cartLoading, setCartLoading] = React.useState<boolean>(false);
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(1);
  const [windowWidth, setWindowWidth] = React.useState(false);

  React.useEffect(() => {
    data.imagePath && PhotoStorage.getURL(data.imagePath).then(url => setLoadingImage(url));
    if (window.innerWidth < 901){
      setWindowWidth(true);
    }
  }, []);


  const toggleFavorite = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const result = await FavoriteController.AddOrRemove(data.id);
    result.success && setData({ ...data, isFavorite: !data.isFavorite });
    if (!data.isFavorite && data.id !== 1262){
        //tslint:disable-next-line:no-string-literal
        data['google_product_category'] = props.categoryName
        await GtagData(data, "add_to_wishlist")
      }
  }

  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setCartLoading(true);

    await BasketController.Change({
      productId: data.id,
      productQuantity: count,
      isPackage: true,
    });

    window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    if (data.id !== 1262 && data.stockQuantity) {
      //tslint:disable-next-line:no-string-literal
      data['google_product_category'] = props.categoryName
      GtagData(data, "add_to_cart")
    }
    setCartLoading(false);
  }

  const addSpecialProduct = async () => {
    setConfirmModal(false);
    const result = await UserController.UpdatePreferredProductList({
      newProductId: data.id,
      oldProductId: props.specialProductId ? +props.specialProductId : undefined,
    });
    if (result && result.success) {
      window.routerHistory.push(ROUTES.PROFILE.SPECIAL_PRODUCTS);
    }
  }

  const checkForSearch = () => {
    const query = new URLSearchParams(window.location.search);
    const productSearchData = {
      ...data,
      producer: {
        id: data.productId,
        name: data.productName
      },
    };
    query.has('text') && SearchHistory.add(productSearchData.id);
  }

  const isDiscount = !!data?.promotion?.result && data.promotion.promotionType === PromotionTypeEnum.Discount;
  const lang:any=Settings.language
  const windowExtraSmall = window.innerWidth < 450;
  return (
    <>
      <Link className="P-products-list-item"
            to={ROUTES.PRODUCTS.DETAILS.replace(':id', data.id).replace(':slug', data.slugs[lang])}
            onClick={checkForSearch}>
        {!!data.promotion.percent &&
            <DiscountLabel percent={data.promotion.percent} type={data.promotion.promotionType}/>}
          {data.isNew && <NewProduct/>}
        <div className="P-image" style={{background: `url('${getMediaPath(loadingImage)}') center/contain no-repeat`}}/>
        {/*small media */}
        {windowWidth ? <>
              <div className={'info-wrapper'}>
                {!Settings.guest && <i
                    onClick={toggleFavorite}
                    className={`P-favorite ${data.isFavorite ? 'P-active icon-Group-5520' : 'icon-Group-5518'}`}
                />}

                {!Settings.guest && props.specialProductId && <i
                    onClick={(e) => {
                      e.preventDefault(), setConfirmModal(true)
                    }}
                    className={`P-special-add icon-Group-5532`}
                />}
                <h3 className="P-product-name">{windowExtraSmall ? truncateText(data.title, 40) : truncateText(data.title, 100)}
                  {!data.stockQuantity && <h4 className="G-clr-red">{Settings.translations.out_of_stock}</h4>}
                </h3>
                <div className={`price-content`}>
                  <div className="P-price" onClick={(e: React.SyntheticEvent) => e.preventDefault()}>
                    <div className="P-product-price-container">
                      {isDiscount && <del className="P-without-discount-price">{formatPrice(data.price)}</del>}
                      <span>{formatPrice(isDiscount ? data.promotion.result : data.price)}</span>
                    </div>
                  </div>
                  <LoaderContent
                      loading={cartLoading}
                      className="G-main-button"
                      onClick={addToCart}
                      id={'share_btn'}
                  >{Settings.translations.add}</LoaderContent>
                </div>
              </div>
            </>
            : <>
          {!Settings.guest && <i
              onClick={toggleFavorite}
              className={`P-favorite ${data.isFavorite ? 'P-active icon-Group-5520' : 'icon-Group-5518'}`}
          />}

          {!Settings.guest && props.specialProductId && <i
              onClick={(e) => {
                e.preventDefault(), setConfirmModal(true)
              }}
              className={`P-special-add icon-Group-5532`}
          />}
          <h3 className="P-product-name">{windowWidth ? truncateText(data.title, 22) : truncateText(data.title)}
            {!data.stockQuantity && <h4 className="G-clr-red">{Settings.translations.out_of_stock}</h4>}
          </h3>
          <div className="P-price" onClick={(e: React.SyntheticEvent) => e.preventDefault()}>
            <div className="P-product-price-container">
              {isDiscount && <del className="P-without-discount-price">{formatPrice(data.price)}</del>}
              <span>{formatPrice(isDiscount ? data.promotion.result : data.price)}</span>
            </div>
          </div>

          <LoaderContent
              loading={cartLoading}
              className="G-main-button"
              onClick={addToCart}
              id={'share_btn'}
          >{Settings.translations.add}</LoaderContent>
        </>}

      </Link>

      {confirmModal && <ConfirmModal
        text={Settings.translations.special_product_confirm}
        onConfirm={addSpecialProduct}
        onClose={() => setConfirmModal(false)}
      />}
    </>
  );
});

export default ListItem;
