export enum BannerTypeEnum {
  Empty,
  Blog,
  News,
  Product,
  Category,
  Brand,
  ActiveIngredient
};
export const BannerType =
{ 
   0: "EMPTY", 
   1: "BLOG", 
   2: "NEWS", 
   3: "PRODUCTS", 
   4: "CATEGORY", 
   5: "BRAND", 
   6: "ACTIVEINGREDIENT" 
}