import * as React from 'react';

import CountInput from 'components/count-input';
import Settings from 'platform/services/settings';
import { IProductDetailsResponseModel } from 'platform/api/product/models/response';
import HelperComponent from 'platform/classes/helper-component';
import LoaderContent from 'components/loader-content';
import BasketController from 'platform/api/basket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PharmaciesAvailablity from './components/pharmacies-availablity';
import { formatPrice } from 'platform/services/helper';
import ROUTES from 'platform/constants/routes';
import { PromotionTypeEnum } from 'platform/constants/enums';
import { IGetProductPromotionByQuantityModel } from 'platform/api/product/models/request';
import ProductController from 'platform/api/product';

import PinImage from 'assets/images/pin.png';

import './style.scss';
import {NavLink} from "react-router-dom";
import ConfirmModal from "../../../../../../../components/confirm-modal";
import UserController from "../../../../../../../platform/api/user";
import DiscountLabel from "../../../../../../shared/products/components/discount-label";
import {Shared} from "../../../../../../index";
import GtagData from "../../../../../../../platform/services/gtagData";

interface IProps {
  data: IProductDetailsResponseModel;
  onChange?(data: IProductDetailsResponseModel | null): void;
  type?: PromotionTypeEnum;
}

interface IState {
  count: number;
  price:number,
  discountPrice:number,
  cartLoading: boolean;
  isSelectedPackage: boolean;
  promotionModel: IGetProductPromotionByQuantityModel;
  pharmaciesAvailablityOpen: boolean;
  confirmModal: boolean;
  appWidth:boolean
};

class Info extends HelperComponent<IProps, IState> {

  public state: IState = {
    count: 1,
    price:1,
    discountPrice:1,
    cartLoading: false,
    pharmaciesAvailablityOpen: false,
    isSelectedPackage: true,
    confirmModal: false,
    promotionModel: {
      productId: null,
      quantity: 1,
      isPackage: false
    },
    appWidth: false
  };

  public componentDidMount() {
    const { data } = this.props;
    const promotionQuantities=data.promotion.availablePromotions.promotionQuantities
    let { discountPrice, count, price} = this.state;
    price=data.price
    if (data.packagePromotion.promotionType>0) {
      discountPrice = data.packagePromotion.result
      price=data.packagePrice
    }else{
      discountPrice =data.packagePrice
      price=data.packagePrice
    }
    if (promotionQuantities.length > 0 ){
      if (promotionQuantities[0].percent === 50){
        count=2;
        discountPrice=discountPrice*count
        price=price*count
      }
    }
    if (window.innerWidth < 901) {
      this.safeSetState({ appWidth:true});
    }
    this.safeSetState({ price,count,discountPrice });
  }

  private onCountChange = async (count: number) => {
    const { data } = this.props;
    let { discountPrice, price} = this.state;
    const { isSelectedPackage } = this.state;
    if (data.packagePromotion.promotionType>0){
      discountPrice=data.packagePromotion.result*count
      price=data.packagePrice*count
    }else{
      discountPrice=data.price*count
    }
    if (data.unitQuantity > 1 && isSelectedPackage){
      price=data.packagePrice*count
      discountPrice=data.packagePrice*count
    }
    else if(data.unitQuantity > 1 && !isSelectedPackage){
      price=data.price*count
      discountPrice=data.price*count
    }
    if (data.packagePromotion.promotionType>0 && data.unitQuantity > 1 && isSelectedPackage){
      discountPrice=data.packagePromotion.result*count
    }
    if (!count && data) {
      await BasketController.Delete(data.id);
      window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    }

    this.safeSetState({ count, discountPrice, price});
  }
  private getProductPromotionByQuantity = async () => {
    const { promotionModel } = this.state;
    const result = await ProductController.GetProductPromotionByQuantity(promotionModel);
    if (result.success) {
      //
    }
  }
  private gtagEvent = (eventName: string, data: object|null ) => {
    window.gtag("event", eventName, data);
  }
  private changeCart = () => this.safeSetState({ cartLoading: true }, async () => {
    const { data } = this.props;
    const { count, isSelectedPackage } = this.state;

    await BasketController.ChangeWithAdd({
      productId: data.id,
      productQuantity: count || 1,
      isPackage: isSelectedPackage,
    })
    if (data.id !== 1262 && data.stockQuantity){
      await GtagData(data, "add_to_cart")
    }
    window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    this.safeSetState({ cartLoading: false, count: count || 1 });
  });

  private togglePharmaciesAvailablity = () => {
    const { pharmaciesAvailablityOpen } = this.state;
    this.safeSetState({ pharmaciesAvailablityOpen: !pharmaciesAvailablityOpen });
  }

  private togglePackage = () => {
    let { count} = this.state;
    const { data } = this.props;
    const { isSelectedPackage } = this.state;
    count = isSelectedPackage ? data.stepCount : 1;
    let { price } = this.state;
    let { discountPrice } = this.state;
    if(isSelectedPackage){
      count = 1
      price = data.price * count ;
      price = data.price * count ;
      discountPrice = data.price * count
      this.safeSetState({discountPrice, price});
    }
    else{
      count = data.stepCount
      if (data.packagePromotion.promotionType > 0) {
        discountPrice = data.packagePromotion.result * count
        price = data.packagePrice * count
      }else{
        price = data.packagePrice
        discountPrice = data.packagePrice
      }
      this.safeSetState( {discountPrice, price});
    }
    this.safeSetState({ isSelectedPackage: !isSelectedPackage,count });
  }

  private UnitCount = () => {
    const { data } = this.props;
    const { isSelectedPackage } = this.state;
    if (data.havePackage) {
      return <span onClick={this.togglePackage}>
        <span className={`${isSelectedPackage ? 'P-selected-count-type' : ''}`}>{Settings.translations.package}</span>
        &nbsp; / &nbsp;
        <span className={`${!isSelectedPackage ? 'P-selected-count-type' : ''}`}>{Settings.translations.item}</span>
      </span>
    } else return <span className="P-selected-count-type">{data.unitName}</span>
  }

  private navigateToCategory = () => {
    const { id } = this.props.data.category;
    const query = new URLSearchParams(window.location.search);
    query.set('categoryIds', `${id}`);
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
  }

  private navigateToBrand = () => {
    const { id } = this.props.data.brand;
    const query = new URLSearchParams(window.location.search);
    query.set('brandIds', `${id}`);
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
  }

  private navigateToProducer = () => {
    const { id } = this.props.data.producer;
    const query = new URLSearchParams(window.location.search);
    query.set('producerIds', `${id}`);
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
  }

  private navigateToActiveIngredients = () => {
    const { activeIngredients } = this.props.data;
    const query = new URLSearchParams(window.location.search);
    query.set('activeIngredientIds', activeIngredients.map(item => item.id).join(','));
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
  }

  private get discountedPrice() {
    const { data } = this.props;
    const { isSelectedPackage } = this.state;
    return Math.round((isSelectedPackage ? data.packagePromotion.result : data.promotion.result) * 10) / 10
  };

  private get price() {
    const { data } = this.props;
    const { isSelectedPackage } = this.state;

    return isSelectedPackage ? data.packagePrice : data.price;
  }
  private addSpecialProduct = async () => {
    const { data, onChange } = this.props;
    const result = await UserController.UpdatePreferredProductList({ newProductId: data.id });
    if (onChange) {
      result && result.success && onChange({
        ...data,
        isSpecial: !data.isSpecial,
      });
    }
    this.closeConfirmModal();
  }
  private openConfirmModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.safeSetState({ confirmModal: true });
  }

  private closeConfirmModal = () => {
    this.safeSetState({ confirmModal: false });
  }
  public render() {
    const { data ,type} = this.props;
    const { count, cartLoading, pharmaciesAvailablityOpen, isSelectedPackage, price,discountPrice,confirmModal,appWidth} = this.state;
    return (
        <div className="P-product-details-info">

          {appWidth &&
              <div className={'app-mobile-wrapper'}>
                {data.packagePromotion.percent > 0 && <div
                    className={`P-discount ${data.packagePromotion.promotionType === PromotionTypeEnum.Discount ? 'G-clr-orange' : 'G-clr-main'}`}>
                  {data.packagePromotion.promotionType === PromotionTypeEnum.Discount ? '-' : '+'}
                  {data.packagePromotion.percent} <span>% </span>
                  {data.packagePromotion.promotionType === PromotionTypeEnum.Discount ? Settings.translations.off : Settings.translations.bonus}
                </div>}
                <h2 className={`P-name`}>
                  {data.title}
                </h2>
                <div className={'prices-wrapper'}>
                  {appWidth &&
                      <div className="P-count-info"><this.UnitCount /></div>
                  }
                  <div className={`P-cart-actions-wrapper ${data.avgPrice ? 'P-avg-active' : ''}`}>
                    {pharmaciesAvailablityOpen &&
                        <PharmaciesAvailablity onClose={this.togglePharmaciesAvailablity} data={data}/>}
                    <span className="G-clr-orange G-ml-auto P-price">
            <span/>
                      {isSelectedPackage && data.packagePromotion.result > 0 && discountPrice < price &&
                          <del>{formatPrice(price)}</del>}
                      {(!isSelectedPackage ? data.packagePromotion : data.promotion).promotionType === PromotionTypeEnum.Discount ?
                          <>  {formatPrice(discountPrice)} </> : <>
                            <span
                                className={`bonus`}> {this.discountedPrice > 0 && !data.havePackage ? `${Settings.translations.bonus} ${this.discountedPrice * count}` : ''}</span> {formatPrice(discountPrice)}</>}
          </span>
                    {data.avgPrice && <div className={`P-avg-price`}>

              <span className="G-clr-main G-ml-auto P-price">
              <span>{Settings.translations.average_price_of_previous_month} </span>
              <span className={`G-text-bold avg-value`}>
                {formatPrice(data.avgPrice)}</span>
          </span>
                    </div>}
                  </div>
                </div>

                <div className="P-cart-actions">
                  {data.stockQuantity && <>
                    <CountInput
                        min={isSelectedPackage ? data.stepCount : data.minCount}
                        step={isSelectedPackage ? data.stepCount : 1}
                        value={count}
                        onChange={this.onCountChange}
                    />

                    <LoaderContent
                        loading={cartLoading}
                        className="G-main-button"
                        onClick={this.changeCart}
                        id={'add_to_cart_'+data.id}
                    >{Settings.translations.add_to_cart}</LoaderContent>
                  </>}
                </div>
                <div className={'special-product-wrapper'}>
                  {!Settings.guest && !data.isSpecial && <span
                      onClick={e => this.openConfirmModal(e)}
                      className={`P-special-add`}> + {Settings.translations.add_special_product}</span>}
                  <NavLink
                      to={ROUTES.PROFILE.SPECIAL_PRODUCTS}
                      className={'P-link '}
                  >
                    {Settings.translations.show_all}
                  </NavLink>
                </div>
              </div>
          }
          {!appWidth &&
              <h2 className={`P-name`}>
                {data.title}
              </h2>
           }
          {!data.stockQuantity && <h3 className="G-clr-red">{Settings.translations.out_of_stock}</h3>}
          <h3 className="P-unit">{data.unitQuantity} {data.unitName}</h3>
          {data.category && <h3 className="P-row">
            {Settings.translations.category}
            <span className="P-value G-cursor-pointer" onClick={this.navigateToCategory}>
            {data.category.name}
              <i className="icon-Group-5513 P-arrow-icon" />
          </span>
          </h3>}
          {data.producer && <h3 className="P-row">
            {Settings.translations.producer}
            <span className="P-value G-cursor-pointer" onClick={this.navigateToProducer}>
            {data.producer.name}
              <i className="icon-Group-5513 P-arrow-icon" />
          </span>
          </h3>}
          {data.brand && <h3 className="P-row">
            {Settings.translations.brand}
            <span className="P-value G-cursor-pointer" onClick={this.navigateToBrand}>
            {data.brand.name}
              <i className="icon-Group-5513 P-arrow-icon" />
          </span>
          </h3>}
          {data.stockQuantity && <h3 className="P-row">
            {Settings.translations.availability_at_the_nearest_pharmacy}
            <span className="P-value P-pin G-cursor-pointer" onClick={this.togglePharmaciesAvailablity}>
            <img src={PinImage} alt="pin" />
            <i className="icon-Group-5513 P-arrow-icon" />
          </span>
          </h3>}
          {data.activeIngredients && !!data.activeIngredients.length && <h3 className="P-row">
            {Settings.translations.active_ingredients}
            <span className="P-value G-cursor-pointer" onClick={this.navigateToActiveIngredients}>
            {data.activeIngredients.map(item => item.name).join(', ')}
              <i className="icon-Group-5513 P-arrow-icon" />
          </span>
          </h3>}
          {data.description && <>
            <h3>{Settings.translations.description}</h3>
            <p className="P-description">{data.description}</p>
          </>}
          {!appWidth &&
              <>
                <div className="P-count-info">
                  <this.UnitCount/>
                </div>
                <div className="P-cart-actions">
                  {data.stockQuantity && <>
                    <CountInput
                        min={isSelectedPackage ? data.stepCount : data.minCount}
                        step={isSelectedPackage ? data.stepCount : 1}
                        value={count}
                        onChange={this.onCountChange}
                    />

                    <LoaderContent
                        loading={cartLoading}
                        className="G-main-button"
                        onClick={this.changeCart}
                        id={'add_to_cart_' + data.id}
                    >{Settings.translations.add_to_cart}</LoaderContent>
                  </>}
                  <div className={`P-cart-actions-wrapper ${data.avgPrice ? 'P-avg-active' : ''}`}>
                    {pharmaciesAvailablityOpen &&
                        <PharmaciesAvailablity onClose={this.togglePharmaciesAvailablity} data={data}/>}
                    <span className="G-clr-orange G-ml-auto P-price">
            <span/>
                      {isSelectedPackage && data.packagePromotion.result > 0 && discountPrice < price &&
                          <del>{formatPrice(price)}</del>}
                      {(!isSelectedPackage ? data.packagePromotion : data.promotion).promotionType === PromotionTypeEnum.Discount ?
                          <>  {formatPrice(discountPrice)} </> : <>
                            <span
                                className={`bonus`}> {this.discountedPrice > 0 && !data.havePackage ? `${Settings.translations.bonus} ${this.discountedPrice * count}` : ''}</span> {formatPrice(discountPrice)}</>}
          </span>
                    {data.avgPrice && <div className={`P-avg-price`}>

              <span className="G-clr-main G-ml-auto P-price">
              <span>{Settings.translations.average_price_of_previous_month} </span>
              <span className={`G-text-bold avg-value`}>
                {formatPrice(data.avgPrice)}</span>
          </span>
                    </div>}
                  </div>
                </div>
              </>
          }
          {confirmModal && <ConfirmModal
              title={Settings.translations.special_product}
              text={Settings.translations.special_product_confirm}
              onConfirm={this.addSpecialProduct}
              onClose={this.closeConfirmModal}
          />}
        </div>
    );
  };
};

export default Info;
