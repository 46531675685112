import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';
import LanguageSwitcher from './components/language-switcher';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import SearchInput from 'components/search-input';
import {Shared} from 'modules';
import Categories from './components/categories';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Storage from 'platform/services/storage';
import environment from 'platform/services/environment';
import {getMediaPath} from 'platform/services/helper';
import WholesaleContent from './components/wholesale-content';
import Socket from 'platform/services/socket';
import NotificationController from 'platform/api/notification';
import Notifications from './components/notifications';
import BasketController from 'platform/api/basket';
import ProductController from 'platform/api/product';
import Screen from 'components/screen';
import LogoImage from 'assets/images/logo.png';
import DownloadImage from 'assets/images/download.png';
import ProfileImage from 'assets/images/icons/profile.svg';
import PersonImage from 'assets/images/person.png';
import burgerMenu from 'assets/images/menu.svg';
import Broadcast from '../../platform/services/broadcast';
import MobileMenu from './components/mobile-menu';
import {IProductSearcResponseModel} from 'platform/api/product/models/response';
import SearchPopup from './components/search';
import Connection from 'platform/services/connection';
import SearchHistory from 'platform/services/searchHistory';
import './style.scss';
import './responsive.scss';
import HelperComponent from 'platform/classes/helper-component';
import {contactHeaderPhoneNumber} from "../../platform/constants/contact-info";
import AppStoreImage from "../../assets/images/app_store.png";
import GooglePlayImage from "../../assets/images/google_play.png";
import { memoize } from 'lodash';

interface IState {
    authOpen: boolean;
    isApps: boolean;
    categoryOpen: boolean;
    searchValue: string;
    notificationOpen: boolean;
    mobileSearchOpen: boolean;
    searchOpen: boolean;
    searchLoader: boolean;
    searchResult: IProductSearcResponseModel | null;
    searchHistoryShown: boolean;
    notificationIconNumber: number;
    cartIconNumber: number;
    mobileMenuOpen: boolean;
    scrollDown:boolean;
    // notificationModal:boolean;
    // selectedItem?: INotificationListResponseModel;
};


class Header extends HelperComponent<{}, IState> {

    public state: IState = {
        authOpen: false,
        categoryOpen: false,
        isApps: false,
        searchValue: '',
        mobileSearchOpen: false,
        notificationOpen: false,
        searchLoader: false,
        searchOpen: false,
        searchResult: null,
        searchHistoryShown: false,
        notificationIconNumber: 0,
        cartIconNumber: 0,
        mobileMenuOpen: false,
        scrollDown:false,
        // notificationModal:false,
    };

    private timer: any;
    private header = React.createRef<HTMLDivElement>();
    private categoryOpenLink = React.createRef<HTMLAnchorElement>();
    private navLinkProps = {
        className: 'P-link',
        activeClassName: 'P-active',
        exact: true
    };

    public componentDidMount() {
        if (window.innerWidth < 901) {
            window.addEventListener('scroll', this.handleScroll);
        }
        this.fetchCart();
        // this.fetchNotification();
        window.addEventListener(DispatcherChannels.CartItemsUpdate, this.fetchCart);
        Broadcast.subscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        Storage.profile && this.configureNotifications();
        this.connectNotificationsSocket();

    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener(DispatcherChannels.CartItemsUpdate, this.fetchCart);
        if (window.innerWidth < 901) {
            window.removeEventListener('scroll', this.handleScroll);
        }
        Broadcast.unsubscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    private storageUpdate = () => {
        this.forceUpdate();
    };

    public async configureNotifications() {
        this.timer = setInterval(async () => { // Temporary TODO -> change to socket
            const result = await NotificationController.GetUnseenList();
            if (result && result.success) {
                this.safeSetState({notificationIconNumber: result.data.unseen});
            }
        }, 10000)
    }

    private connectNotificationsSocket() {
        Socket.connection && Socket.connection.on('NewMessage', () => {
            const {notificationIconNumber} = this.state;
            this.safeSetState({notificationIconNumber: notificationIconNumber + 1});
        });
    }
    // private loadingTimeout: number;
    // private fetchNotification = async () => {
    //     const body = {
    //         pageSize: infinityScrollPageLimit,
    //         pageNumber: 1,
    //     };
    //     const resultNotification = await NotificationController.GetList(body);
    //     const lastNotificationId = resultNotification.data.list[0].id;
    //     console.log(resultNotification.data.list[0])
    //     const lastNotification = window.localStorage.getItem('LastNotification') || 0;
    //     // tslint:disable-next-line:radix
    //     if (lastNotification === 0 || lastNotificationId > parseInt(lastNotification)) {
    //         window.localStorage.setItem('LastNotification', JSON.stringify(lastNotificationId));
    //         // banner modal open Timeout
    //         await NotificationController.Seen(+lastNotificationId);
    //         this.loadingTimeout = window.setTimeout(() => this.safeSetState({notificationModal: true}), 5000);
    //         this.safeSetState({selectedItem: resultNotification.data.list[0]});
    //
    //     } else {
    //         this.state.notificationModal = false
    //     }
    // };

    private fetchCart = async () => {
            const result = await BasketController.GetCount();
            if (!result){
                window.localStorage.setItem('token', '');
            }else{
                this.safeSetState({ cartIconNumber: result.data });
            }
    };

    private toggleAuth = () => {
        const {authOpen, mobileMenuOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen,
            mobileMenuOpen: !mobileMenuOpen || false // Close if from mobile
        });
    };

    private openCategories = () => {
        this.safeSetState({categoryOpen: true});
        document.addEventListener('mousemove', this.closeCategories);
    };

    private closeCategories = (e?: MouseEvent) => {
        const {categoryOpen} = this.state;
        const canBeClosed = !e || (
            this.categoryOpenLink.current &&
            !this.categoryOpenLink.current.contains(e.target as Node)
        );

        if (categoryOpen && canBeClosed) {
            this.safeSetState({categoryOpen: false});
            document.removeEventListener('mousemove', this.closeCategories);
        }
    };

    private searchChange = async (value: string) => {
        if (value.trim()) {
            this.safeSetState({
                searchLoader: true,
                searchOpen: false,
                searchValue: value,
                searchResult: null
            }, async () => {
                Connection.AbortAll();
                const data = await ProductController.Search(value);
                if (data.aborted) {
                    return
                }

                const {searchLoader} = this.state;

                // If searchLoader has changed, don't show the result
                if (data?.data?.products.length) {

                    this.safeSetState({
                        searchResult: data.data,
                        searchHistoryShown: false,
                        searchOpen: true
                    });
                } else {
                    this.safeSetState({
                        searchResult: data.data,
                        searchHistoryShown: true,
                        searchOpen: false,
                    });
                    setTimeout(() => this.safeSetState({searchOpen: true}));
                }

                if (!data?.aborted) {
                    this.safeSetState({searchLoader: false});
                }
            });
        } else {
            Connection.AbortAll();
            this.safeSetState({
                searchValue: '',
                searchOpen: false,
                searchLoader: false,
                searchResult: {
                    products: SearchHistory.items,
                    categories: []
                },
                searchHistoryShown: true
            });
        }
    };

    private closeSearch = () => this.safeSetState({searchOpen: false, searchHistoryShown: false});
    private closeMobileSearch = () => this.safeSetState({mobileSearchOpen: false});

    private toggleMobileMenu = () => {
        const {mobileMenuOpen} = this.state;
        this.safeSetState({mobileMenuOpen: !mobileMenuOpen});
    };

    private toggleNotifications = (e?: Event | React.SyntheticEvent) => {
        e && e.stopPropagation();
        const {notificationOpen} = this.state;
        if (!notificationOpen) {
            this.configureNotifications();
        }
        this.safeSetState({notificationOpen: !notificationOpen});
    };

    private onNotificationSeenChange = (all: boolean) => {
        const {notificationIconNumber} = this.state;
        if (notificationIconNumber && notificationIconNumber > 0) {
            this.safeSetState({notificationIconNumber: all ? 0 : notificationIconNumber - 1});
        }
    };

    private searchFocus = async (e: React.SyntheticEvent) => {
        //the localStorage data type has changed, that's why this check is written
        // can be deleted later
        if ('id' in SearchHistory.items){
            window.localStorage.removeItem('searchHistory');
        }
        //
        e.stopPropagation();
        const body = {
            productText: '',
            categoryIds: [],
            categoryId: 0,
            brandIds: [],
            producerIds: [],
            productIds: SearchHistory.items,
            activeIngredientIds: [],
            sortBy: undefined,
            hasDiscount: false,
            pageSize:30,
            pageNumber:1,
        };
        const result = await ProductController.GetList(body);
        const {searchValue} = this.state;
        if (!searchValue) SearchHistory.items.length && this.safeSetState({
            searchOpen: true,
            searchHistoryShown: true,
            searchResult: {
                // products: SearchHistory.items,
                products: result ? result.data.list : [],
                categories: []
            }
        }); else this.safeSetState({searchOpen: true, searchHistoryShown: false});
    };

    private showMobileSearch = () => {
        const {mobileSearchOpen} = this.state;
        this.safeSetState({mobileSearchOpen: !mobileSearchOpen});
    }

    private searchSubmit = () => {
        const {searchValue} = this.state;

        if (searchValue) {
            this.safeSetState({searchOpen: false, searchLoader: false});
            window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?text=${searchValue}`);
            window.dispatchEvent(new Event(DispatcherChannels.ProductFilterChange));
        } else {
            window.routerHistory.push(ROUTES.PRODUCTS.MAIN);
            window.dispatchEvent(new Event(DispatcherChannels.ProductFilterChange));
        }
    };

    private openProducts = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.ProductFilterChange, {detail: {makeEmpty: true}}));

    private handleScroll = memoize(() => {
        const { pageYOffset: position } = window;
        this.safeSetState({ scrollDown: position > 10 });
    });
    public render() {
        const {
            authOpen,
            categoryOpen,
            cartIconNumber,
            notificationIconNumber,
            notificationOpen,
            searchOpen,
            isApps,
            searchValue,
            searchResult,
            searchLoader,
            searchHistoryShown,
            scrollDown,
        } = this.state;

        const lang:any=Settings.language
        const mainRoute = window.location.pathname === '/'+lang || window.location.pathname === '/'+lang+'/'
        const showHeader =  (window.innerWidth >= 900 && !mainRoute) || mainRoute
        return (<>
                {showHeader &&
                <header id={`header`} ref={this.header} className={`G-flex G-flex-align-center ${scrollDown  ? 'scroll-down' : '' }`}>
                    <div className="header-inner G-flex G-flex-align-center G-flex-justify-center">
                        <Screen.SmallDesktop>
                            {((matches: boolean) => !matches ? <>
                                <div>
                                    <Link to={ROUTES.MN} className="P-logo G-mr-auto">
                                        <img alt={'logo'} width={'170px'} height={'59px'} src={LogoImage} className="G-full-width"/>
                                    </Link>
                                    <a href={`tel:${contactHeaderPhoneNumber}`} className={`headerPhone`}><i
                                        className="G-clr-orange icon-Group-5522"/> <b>{contactHeaderPhoneNumber}</b></a>
                                </div>

                                {environment.WHOLESALE ? <WholesaleContent/> : <>
                                    <div className="P-search-wrapper P-desktop-search-box">
                                        <SearchInput
                                            onClick={this.searchFocus}
                                            onFocus={this.searchFocus}
                                            onClose={this.closeMobileSearch}
                                            onChange={this.searchChange}
                                            onSubmit={this.searchSubmit}
                                            clearSearch={true}
                                            loading={searchLoader}
                                            disableRemoveOnNavigate={true}
                                            withSubmit={true}
                                        />
                                        {searchOpen && searchResult && <SearchPopup
                                            onClose={this.closeSearch}
                                            onSubmit={this.searchSubmit}
                                            data={searchResult}
                                            searchText={searchValue}
                                            historyShown={searchHistoryShown}
                                        />}

                                    </div>
                                    <Link
                                        to={ROUTES.PRODUCTS.MAIN}
                                        innerRef={this.categoryOpenLink}
                                        onMouseOver={this.openCategories}
                                        onClick={this.openProducts}
                                        className={`P-link ${categoryOpen ? 'P-active' : ''}`}
                                    >
                                        {Settings.translations.online_pharmacy}
                                        {categoryOpen && <Categories onClose={this.closeCategories}/>}
                                    </Link>
                                    <NavLink {...this.navLinkProps}
                                             to={ROUTES.PHARMACIES.MAIN}>{Settings.translations.pharmacies}</NavLink>
                                    {/*<NavLink {...this.navLinkProps}*/}
                                    {/*         to={ROUTES.CLINIC.MAIN}>{Settings.translations.clinic}</NavLink>*/}
                                    <NavLink {...this.navLinkProps}
                                             to={ROUTES.BLOG.MAIN}>{Settings.translations.blog}</NavLink>
                                    {Storage.profile &&
                                        <NavLink {...this.navLinkProps}
                                                 to={ROUTES.PROFILE.PRESCRIPTIONS.MAIN}>{Settings.translations.prescription}</NavLink>
                                    }
                                    {/*{Storage.profile &&*/}
                                    {/*    <NavLink {...this.navLinkProps}*/}
                                    {/*             to={ROUTES.CLINIC.DOCTORS}>{Settings.translations.doctor_consultation}</NavLink>*/}


                                </>}

                                <div className={`appParent`}>
                                    <button className={`openApps`} id={'apps_btn'}
                                            onClick={e => this.safeSetState({isApps: !isApps})}><img
                                        width={'14px'} height={'14px'} className={`downloadImg`} src={DownloadImage} alt=""/> {Settings.translations.apps}
                                    </button>
                                    {
                                        isApps ? <div className={`dropdownApp`} id={`dropdownApp`}>
                                            <img alt={'App Store'} src={AppStoreImage} className={`appHeader`}
                                                 onClick={() => window.open('https://apps.apple.com/ru/app/alfa-pharm-drugstore-chain/id1453737637', '_blank')}/>
                                            <img alt={'Google Play'} src={GooglePlayImage} className={`appHeader`}
                                                 onClick={() => window.open('https://play.google.com/store/apps/details?id=am.gtest.alfapharm&hl=ru&gl=US', '_blank')}/>
                                        </div> : ''
                                    }
                                </div>
                                {Storage.profile ? <Link to={ROUTES.PROFILE.MAIN} className="P-profile">
                                    <div
                                        style={{background: `url('${Storage.profile.photoPath ? getMediaPath(Storage.profile.photoPath) : PersonImage}') center/cover`}}
                                        className="P-image"/>
                                    {/* <h5>{Storage.profile.firstName}</h5> */}
                                </Link> : <span
                                    onClick={this.toggleAuth}
                                    className="P-link P-login"
                                >{Settings.translations.log_in}</span>}

                                {Storage.profile &&
                                    <a onClick={this.toggleNotifications}
                                       className="P-link P-icon G-normal-link P-notification ">
                                        <i className="icon-Group-5515"/>
                                        {!!notificationIconNumber &&
                                            <span>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}
                                    </a>}
                                <Link to={ROUTES.CART} className="P-link P-icon G-normal-link P-cart">
                                    <i className="icon-Group-5503"/>
                                    {!!cartIconNumber && <span>{cartIconNumber > 99 ? '99+' : cartIconNumber}</span>}
                                </Link>
                                <LanguageSwitcher/>


                                {/*// className="G-clr-orange icon-Group-5522"/></a>*/}

                                {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
                                {notificationOpen &&
                                    <Notifications onClose={this.toggleNotifications}
                                                   onSeenChange={this.onNotificationSeenChange}/>

                                }


                            </> : <this.Mobile/>)}
                        </Screen.SmallDesktop>
                    </div>
                </header>
            }
            {/*{this.state.selectedItem && this.state.notificationModal ? <NotificationAnswer selectedItem={this.state.selectedItem} onClose={() => this.setState({notificationModal: false})} /> : null}*/}
        </>

        );
    }

    private Mobile = () => {
        const {
            authOpen,
            cartIconNumber,
            mobileSearchOpen,
            notificationOpen,
            mobileMenuOpen,
            searchOpen,
            searchValue,
            searchResult,
            searchLoader,
            searchHistoryShown
        } = this.state;

        return <div className="P-mobile-header">
            <Link to={ROUTES.PROFILE.BONUS_CARD} className="app-mobile-header-icons">
                <i className="icon-Group-5510" />
            </Link>
            <div className="P-burger-menu">
                <img src={burgerMenu} alt="menu" className="G-cursor" onClick={this.toggleMobileMenu}/>
            </div>
            <Link to={ROUTES.MN} className="P-logo P-logo-mobile">
                <img alt={'Logo'} src={LogoImage} className="G-full-width"/>
            </Link>
            <a href={`tel:${contactHeaderPhoneNumber}`} className={'app-mobile-header-icons'}><i
                className="icon-Group-5522"/> </a>

            <div className="P-mobile-header-icons">
                {Storage.profile ? <Link to={ROUTES.PROFILE.MAIN} className="P-profile P-link P-icon G-normal-link">
                    <div
                        style={{background: `url('${Storage.profile.photoPath ? getMediaPath(Storage.profile.photoPath) : PersonImage}') center/cover`}}
                        className="P-image"
                    />
                </Link> : <span
                    onClick={this.toggleAuth}
                    className="P-link P-login P-login-mobile"
                ><img alt={'Profile Image'} src={ProfileImage}/></span>
                }
                <a onClick={this.showMobileSearch} className="P-link P-icon G-normal-link P-notification">
                    <i className="icon-Group-5502"/>
                </a>
                {mobileSearchOpen &&
                    <div className="P-search-wrapper-mobile">
                        <SearchInput
                            onClick={this.searchFocus}
                            onFocus={this.searchFocus}
                            // onClose={this.closeMobileSearch}
                            onChange={this.searchChange}
                            onSubmit={this.searchSubmit}
                            loading={searchLoader}
                            clearSearch={true}
                            disableRemoveOnNavigate={true}
                            withSubmit={true}
                        />

                        {searchOpen && <SearchPopup
                            onClose={this.closeSearch}
                            onSubmit={this.searchSubmit}
                            data={searchResult}
                            searchText={searchValue}
                            historyShown={searchHistoryShown}
                        />}

                    </div>
                }
                <Link to={ROUTES.CART} className="P-link P-icon G-normal-link P-cart">
                    <i className="icon-Group-5503"/>
                    {!!cartIconNumber && <span>{cartIconNumber > 99 ? '99+' : cartIconNumber}</span>}
                </Link>
            </div>
            {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
            {notificationOpen &&
                <Notifications onClose={this.toggleNotifications} onSeenChange={this.onNotificationSeenChange}/>}
            {mobileMenuOpen && <MobileMenu onClose={this.toggleMobileMenu} onOpenNotification={this.toggleNotifications}
                                           onAuthOpen={this.toggleAuth}/>}
        </div>;
    };
}

export default Header;
