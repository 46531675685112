import * as React from 'react';
import {NavLink} from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import NotificationController from 'platform/api/notification';
import BasketController from 'platform/api/basket';
import ProductController from 'platform/api/product';
import {IProductSearcResponseModel} from 'platform/api/product/models/response';
import Connection from 'platform/services/connection';
import SearchHistory from 'platform/services/searchHistory';
import './style.scss';
import HelperComponent from 'platform/classes/helper-component';
import SearchInput from "../search-input";
import SearchIcon from "../../assets/images/icons/search.svg";
import HomeIcon from "../../assets/images/icons/home.svg";
import CartIcon from "../../assets/images/icons/cart.svg";
import DocIcon from "../../assets/images/icons/doc.svg";
import UserIcon from "../../assets/images/icons/user.svg";
import PharmaciesIcon from "../../assets/images/icons/pharmacies-grey.svg"
import SearchPopup from "../header/components/search";
import AppMobileMenu from "../header/components/app-mobile-menu";
import {Shared} from "../../modules";
import Storage from 'platform/services/storage';
import Broadcast from "../../platform/services/broadcast";
import Socket from "../../platform/services/socket";


interface IState {
    authOpen: boolean;
    isApps: boolean;
    categoryOpen: boolean;
    searchValue: string;
    mobileSearchOpen: boolean;
    searchOpen: boolean;
    searchLoader: boolean;
    searchResult: IProductSearcResponseModel | null;
    searchHistoryShown: boolean;
    notificationIconNumber: number;
    cartIconNumber: number;
    mobileMenuOpen: boolean;
    scrollDown: boolean;
    appMobileMenuOpen: boolean;
    // notificationModal:boolean;
    // selectedItem?: INotificationListResponseModel;
};
declare global {
    interface Window {
        routerHistory: any;
    }
}


class FooterApp extends HelperComponent<{}, IState> {

    public state: IState = {
        authOpen: false,
        categoryOpen: false,
        isApps: false,
        searchValue: '',
        mobileSearchOpen: false,
        searchLoader: false,
        searchOpen: false,
        searchResult: null,
        searchHistoryShown: false,
        notificationIconNumber: 0,
        cartIconNumber: 0,
        mobileMenuOpen: false,
        scrollDown: false,
        appMobileMenuOpen: false,
        // notificationModal:false,
    };
    private navLinkProps = {
        className: 'P-link',
        activeClassName: 'P-active',
        exact: true
    };
    private timer: any;

    public componentDidMount() {
        this.fetchCart()
        Storage.profile && this.configureNotifications();
        window.addEventListener(DispatcherChannels.CartItemsUpdate, this.fetchCart);
        Broadcast.subscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        this.connectNotificationsSocket();
    }

    private connectNotificationsSocket() {
        Socket.connection && Socket.connection.on('NewMessage', () => {
            const {notificationIconNumber} = this.state;
            this.safeSetState({notificationIconNumber: notificationIconNumber + 1});
        });
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener(DispatcherChannels.CartItemsUpdate, this.fetchCart);
        Broadcast.unsubscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    private storageUpdate = () => {
        this.forceUpdate();
    };

    public async configureNotifications() {
        this.timer = setInterval(async () => { // Temporary TODO -> change to socket
            const result = await NotificationController.GetUnseenList();
            if (result && result.success) {
                this.safeSetState({notificationIconNumber: result.data.unseen});
            }
        }, 10000)
    }


    private fetchCart = async () => {
        const result = await BasketController.GetCount();
        this.safeSetState({cartIconNumber: result.data})
    };


    private searchChange = async (value: string) => {
        if (value.trim()) {
            this.safeSetState({
                searchLoader: true,
                searchOpen: false,
                searchValue: value,
                searchResult: null
            }, async () => {
                Connection.AbortAll();
                const data = await ProductController.Search(value);
                if (data.aborted) {
                    return
                }


                // If searchLoader has changed, don't show the result
                if (data?.data?.products.length) {

                    this.safeSetState({
                        searchResult: data.data,
                        searchHistoryShown: false,
                        searchOpen: true
                    });
                } else {
                    this.safeSetState({
                        searchResult: data.data,
                        searchHistoryShown: true,
                        searchOpen: false,
                    });
                    setTimeout(() => this.safeSetState({searchOpen: true}));
                }

                if (!data?.aborted) {
                    this.safeSetState({searchLoader: false});
                }
            });
        } else {
            Connection.AbortAll();
            this.safeSetState({
                searchValue: '',
                searchOpen: false,
                searchLoader: false,
                searchResult: {
                    products: SearchHistory.items,
                    categories: []
                },
                searchHistoryShown: true
            });
        }
    };

    private closeSearch = () => {
        document.body.style.overflowY = 'scroll'
        this.safeSetState({searchOpen: false, searchHistoryShown: false, mobileSearchOpen: false});
    }

    private searchFocus = async (e: React.SyntheticEvent) => {
        if ('id' in SearchHistory.items) {
            window.localStorage.removeItem('searchHistory');
        }
        //
        e.stopPropagation();
        const body = {
            productText: '',
            categoryIds: [],
            brandIds: [],
            producerIds: [],
            productIds: SearchHistory.items,
            activeIngredientIds: [],
            sortBy: undefined,
            hasDiscount: false,
            pageSize: 30,
            pageNumber: 1,
        };
        const result = await ProductController.GetList(body);
        const {searchValue} = this.state;
        if (!searchValue) SearchHistory.items.length && this.safeSetState({
            searchOpen: true,
            searchHistoryShown: true,
            searchResult: {
                // products: SearchHistory.items,
                products: result ? result.data.list : [],
                categories: []
            }
        }); else this.safeSetState({searchOpen: true, searchHistoryShown: false});
    };

    private showMobileSearch = () => {
        const {mobileSearchOpen} = this.state;
        const modal = document.querySelector('.P-search-wrapper-mobile.show') as HTMLElement;
        document.body.style.overflowY = modal ? 'scroll' : 'hidden';
        this.safeSetState({mobileSearchOpen: !mobileSearchOpen});
    }

    private searchSubmit = (searchValue) => {
        console.log('searchValue')
        console.log(searchValue)
            if (searchValue) {
                this.safeSetState({searchOpen: false, searchLoader: false});
                window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?text=${searchValue}`);
                window.dispatchEvent(new Event(DispatcherChannels.ProductFilterChange));
            }
            else {
                window.routerHistory.push(ROUTES.PRODUCTS.MAIN);
                window.dispatchEvent(new Event(DispatcherChannels.ProductFilterChange));
            }
    };
    private toggleMobileMenu = () => {
        const {appMobileMenuOpen} = this.state;
        this.safeSetState({appMobileMenuOpen: !appMobileMenuOpen});
    };

    private toggleAuth = () => {
        const {authOpen, mobileMenuOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen,
            mobileMenuOpen: !mobileMenuOpen || false // Close if from mobile
        });
    };


    public render() {
        const {
            searchOpen,
            searchValue,
            searchResult,
            searchLoader,
            searchHistoryShown,
            mobileSearchOpen,
            cartIconNumber,
            appMobileMenuOpen,
            authOpen,
            notificationIconNumber
        } = this.state;
        return (<>
                <section className={'section-footer-app'}>
                    <div>
                        <NavLink className={'P-link'}
                                 to={ROUTES.MN} onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                            <img src={HomeIcon} alt="HOME"/>
                            <span> {Settings.translations.home_app}</span>
                        </NavLink>
                        <NavLink className={'P-link'}
                                 to={ROUTES.CART} onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                            {!!cartIconNumber &&
                                <span className={'cart-count'}>{cartIconNumber > 99 ? '99+' : cartIconNumber}</span>}
                            <img src={CartIcon} alt="CART"/>
                            <span> {Settings.translations.cart}</span>
                        </NavLink>
                    </div>
                    <div className="search-wrapper" onClick={this.showMobileSearch}>
                        <img src={SearchIcon} alt="search"/>
                    </div>
                    <div>
                        {Storage.profile ?
                            <NavLink
                                className={'P-link'}
                                to={ROUTES.PROFILE.PRESCRIPTIONS.MAIN}
                                onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                                <img src={DocIcon} alt="PRESCRIPTIONS"/>
                                <span> {Settings.translations.recipes}</span>
                            </NavLink> :
                            <NavLink
                                className="P-link P-icon-pharmacy"
                                to={ROUTES.PHARMACIES.MAIN}
                                onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                                <img src={PharmaciesIcon} alt="pharmacy"/>
                                <span>  {Settings.translations.pharmacies}</span>
                            </NavLink>
                        }

                        <div className={'profile-content'} onClick={this.toggleMobileMenu}>
                            {notificationIconNumber > 0 &&
                                <span
                                    className={'notif-count'}>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}
                            <img src={UserIcon} alt="PROFILE"/>
                            <span> {Settings.translations.profile}</span>
                        </div>
                    </div>

                </section>
                <div className={`P-search-wrapper-mobile ${mobileSearchOpen ? 'show' : 'hide'}`}>
                       <span className="P-mobile-menu-close"><i
                           className="icon-Group-5032 G-clr-orange G-cursor-pointer"
                           onClick={this.closeSearch}/></span>
                    <SearchInput
                        onClick={this.searchFocus}
                        onFocus={this.searchFocus}
                        onChange={this.searchChange}
                        onSubmit={this.searchSubmit}
                        loading={searchLoader}
                        clearSearch={true}
                        disableRemoveOnNavigate={true}
                        withSubmit={true}
                    />

                    {searchOpen && <SearchPopup
                        onClose={this.closeSearch}
                        onSubmit={()=>this.searchSubmit(searchValue)}
                        data={searchResult}
                        searchText={searchValue}
                        historyShown={searchHistoryShown}
                    />}

                </div>
                {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
                {appMobileMenuOpen && <AppMobileMenu onClose={this.toggleMobileMenu} onAuthOpen={this.toggleAuth}/>}
            </>

        );
    }
}

export default FooterApp;
