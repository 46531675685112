import * as React from 'react';
import * as moment from 'moment';
// @ts-ignore
import {createBrowserHistory} from 'history';
import {Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import Header from './components/header';
import RouteService from './platform/services/routes';
import PageLoader from './components/page-loader';
import ROUTES from './platform/constants/routes';
import Storage from './platform/services/storage';
import Settings from './platform/services/settings';
import Footer from './components/footer';
import FooterApp from './components/footer-app';
import ConfirmModal, {IProps as IConfirmModalProps} from './components/confirm-modal';
import Socket from './platform/services/socket';
import HelperComponent from './platform/classes/helper-component';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import {initYMapsScript} from 'platform/services/yandex';
import './modules';
import 'moment/locale/hy-am';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import './assets/styles/index.scss';
import PaymentController from './platform/api/payment';
import Maps from "./components/maps";
import logoWhite from './assets/images/alpha-logo-white.png'
import Logo from './assets/images/logo.png'
import SuccessModal from "./components/success-modal";
import BannerModal from "./components/banner-modal";
import PageNotFound from "./modules/pages/page-not-found";
import Environment from "./platform/services/environment";
import Wheel from "./components/spin-wheel";
import VisaCard from "./assets/images/visaImage.svg";

interface IState {
    confirmOpen: boolean;
    confirmProps: IConfirmModalProps;
    initialLoading: boolean;
    generalAPILoaded: boolean;
    successPayment: boolean;
    bannerModal:boolean;
    spinModal:boolean;
    showAppFooter:boolean;
    pageLoaded:boolean;
};

class App extends HelperComponent<{}, IState> {
    public state: IState = {
        generalAPILoaded: false,
        initialLoading: false,
        confirmOpen: false,
        successPayment: false,
        bannerModal:false,
        spinModal: false,
        showAppFooter:false,
        pageLoaded:false,
        // bannerModal:false,
        confirmProps: {}
    };
    private loadingTimeout: number;

    public componentWillUnmount() {
        super.componentWillUnmount();
        this.loadingTimeout && clearTimeout(this.loadingTimeout);
    }

    public async componentDidMount() {
        const realTime=new Date();
        const storageTime= window.localStorage.getItem('BannerOpenTime') || 0;
        // const storageTime= window.localStorage.getItem('SpinModalTime') || 0;
        let spinCount = Number(localStorage.getItem('spinCount')) || 0;
        localStorage.setItem('spinCount',JSON.stringify(++spinCount));
        if (document.readyState === 'complete') {
            this.onPageFullyLoaded();
        } else {
            window.addEventListener('load', this.onPageFullyLoaded);
        }
        // tslint:disable-next-line:radix
        if (storageTime===0 || realTime.setDate(realTime.getDate())>parseInt(storageTime)){
            const time=realTime.setDate(realTime.getDate() + 1);
            window.localStorage.setItem('BannerOpenTime', JSON.stringify(time));
            // window.localStorage.setItem('SpinModalTime', JSON.stringify(time));
            window.localStorage.setItem('spinCount', JSON.stringify(1))
            //banner modal open Timeout
            this.loadingTimeout = window.setTimeout(() => this.safeSetState({ bannerModal: true }), 10000);
            // this.loadingTimeout = window.setTimeout(() => this.safeSetState({ spinModal: true }), 10000);
        }else{
            // this.state.bannerModal=false
            // spinCount <= 3 ? this.loadingTimeout = window.setTimeout(() => this.safeSetState({ spinModal: true }), 10000) : this.state.spinModal = false ;
            spinCount <= 3 ? this.loadingTimeout = window.setTimeout(() => this.safeSetState({ bannerModal: true }), 10000) : this.state.bannerModal = false ;
            // this.state.spinModal=false
        }
        if (window.innerWidth < 901){
            this.safeSetState({showAppFooter:true})
        }
        //? Check card
        await this.checkForPaymentSuccess();

        //? Library config
        // ReactGA.plugin.require('ec');
        // ReactGA.initialize('UA-187311863-1');
        // ReactGA.pageview(window.location.pathname + window.location.search);

        const alertify = await import('alertifyjs');
        moment.locale(Settings.shortCode);
        alertify.set('notifier', 'position', 'bottom-center');
        alertify.set('notifier', 'delay', 5);

        //? For SSR to fully load Browser API (general for 'window')

        window.abortableRequests = [];
        window.routerHistory = createBrowserHistory();
        window.routerHistory.listen(() => {
            if (location.pathname !== '/products') {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                });
            }
        });

        window.addEventListener(DispatcherChannels.ToggleConfirm, this.toggleConfirm);

        this.safeSetState({generalAPILoaded: true});

        //? Seed

        try {
            initYMapsScript();
            await Socket.connect();
        } catch { /* */
        }

        //? Backend initial data fetch

        const success = await Storage.fetchDefault();
        if (success) this.safeSetState({initialLoading: true});
        else window.location.reload();

        //? Check for invitation

        const query = new URLSearchParams(window.location.search);
        const referralCode = query.get('referral');
        if (referralCode) {
            Settings.referralCode = referralCode;
        }
    }
    private onPageFullyLoaded = () => {
        this.safeSetState({pageLoaded: true});
        // Remove the load event listener to avoid memory leaks
        window.removeEventListener('load', this.onPageFullyLoaded);
    }
    private checkForPaymentSuccess = async () => {
        const query = new URLSearchParams(window.location.search);
        const orderId = query.get('orderId');
        const isCard = query.get('isCard');

        if (orderId) {
            if (isCard) {
                await PaymentController.saveCard(orderId);
                query.delete('orderId');
                query.delete('key');
                query.delete('isCard');
                window.history.replaceState({path: window.location.pathname}, '', `?${query}`);
            } else {
                const result = await PaymentController.confirm(orderId);

                if (result.success) {
                    window.location.href = Environment.SITE_URL.slice(0, -1)+ROUTES.THANK_FOR_PAYMENT
                } else {

                    // window.location.h
                    // ref = '/checkout?step=3';
                    window.location.href = Environment.SITE_URL+Settings.language+'/profile/orders?FailPayment';
                }
            }
        }
    };

    private toggleConfirm = (e: CustomEvent) => {
        const {confirmOpen} = this.state;
        this.safeSetState({confirmOpen: !confirmOpen, confirmProps: e.detail || {}});
    };
    public map = <Maps/>
    public render() {
        const {generalAPILoaded,pageLoaded, initialLoading, confirmOpen, confirmProps,showAppFooter} = this.state;
        // @ts-ignore
        // @ts-ignore
        return generalAPILoaded ? (
            <Router history={window.routerHistory}>
                {this.state.bannerModal && pageLoaded ?
                    // <BannerModal bool2={true} bool1={true} onClose={() => this.setState({bannerModal: false})}>
                    //     <div className='text-wrapper'>
                    //         <img src={logoWhite} alt="" width="100px" height="80px" />
                    //         <h1 className="get-it">{Settings.translations.get_it}</h1>
                    //         <div><b>13%</b> <div className="sub">{Settings.translations.off}</div></div>
                    //         <p>{Settings.translations.the_first_orders}</p>
                    //         <div className="welcome-wrapper">WELCOME</div>
                    //         <h2>{Settings.translations.thePromoCode}*</h2>
                    //     </div>
                    //     <div className="bottom-text">
                    //         <p>*{Settings.translations.banner_bottom_text}</p>
                    //     </div>
                    // </BannerModal>: null}
                   <BannerModal bool2={true} bool1={true} onClose={() => this.setState({bannerModal: false})} />: null}
                    {
                        // window.location.href.includes("SuccessPayment") ? <SuccessModal bool2={false} bool1={true}
                        //                                                                 onClose={() => window.location.href = Environment.SITE_URL+Settings.language}>
                        //         {Settings.guest ? <>
                        //             <h3>{Settings.translations.guest_order_success}</h3>
                        //             <button id={'history'} className="G-main-button G-normal-link G-mt-30 P-register-button"
                        //                     onClick={() => {
                        //                         window.location.href = Environment.SITE_URL+Settings.language+"/profile/orders"
                        //                     }}>{Settings.translations.order_history}</button>
                        //         </> : <>
                        //             <h3>{Settings.translations.order_success}</h3>
                        //             <Link className="G-main-button G-normal-link G-mt-30"
                        //                   to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                        //         </>}
                        //     </SuccessModal> :
                        window.location.href.includes("FailPayment") ? <SuccessModal bool2={true} bool1={false}
                                                                                 onClose={() => window.location.href = Environment.SITE_URL+Settings.language}>
                        {Settings.guest ? <>
                            <h3>{Settings.translations.order_fail}</h3>
                            <button id={'history'} className="G-main-button G-normal-link G-mt-30 P-register-button"
                                    onClick={() => {
                                        window.location.href = Environment.SITE_URL+Settings.language+"/profile/orders"
                                    }}>{Settings.translations.order_history}</button>
                        </> : <>
                            <h3>{Settings.translations.order_fail}</h3>
                            <Link className="G-main-button G-normal-link G-mt-30"
                                  to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                        </>}
                    </SuccessModal> : null}

                {this.state.spinModal ?
                    <Wheel onClose={() => this.setState({spinModal: false})}/>
                   : null}

                {initialLoading ? <>
                    <Header/>
                    <section className="G-page-content">
                        <Switch>
                            {RouteService.subscribeUnauthorized(routes => routes.map(item => <Route
                                exact={true}
                                key={item.path}
                                path={item.path}
                                component={item.component}
                            />))}
                            {!!Settings.token && !Settings.guest && RouteService.subscribeAuthorized(routes => routes.map(item =>
                                <Route
                                    exact={true}
                                    key={item.path}
                                    path={item.path}
                                    component={item.component}
                                />))}

                            {/*<Route path={"/en/checkout/idram/success"} component={Sucsess}/>*/}
                            {/*<Route path={"/en/checkout/idram/fail"} component={Fail}/>*/}
                            {window.location.href === Environment.SITE_URL  &&
                                <Redirect to={ROUTES.MN}/>
                            }

                            <Route component={PageNotFound} />
                        </Switch>
                    </section>
                    {confirmOpen && <ConfirmModal {...confirmProps} />}
                    <Footer/>
                    {showAppFooter &&
                        <FooterApp/>
                    }

                </> : <PageLoader/>}
            </Router>
        ) : null;
    }
}
export default App;
