import React from 'react';
import HelperComponent from "../../../../../../platform/classes/helper-component";
import './style.scss'

interface IState {
    activeTab: number;
}
interface IProps {
    tabs: { title: string; content: React.ReactNode }[];
}
class Tabs extends HelperComponent<IProps,IState> {
    public state: IState = {
        activeTab: 0,
    };

    public handleTabClick = (index) => {
        this.safeSetState({ activeTab: index });
    };

    public render() {
        const { activeTab } = this.state;
        const { tabs } = this.props;

        return (
            <div>
                <div className="tab-buttons">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => this.handleTabClick(index)}
                            className={index === activeTab ? 'active' : ''}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    {tabs[activeTab].content}
                </div>
            </div>
        );
    }
}

export default Tabs;
