export const cities = {
    yerevan:{
        name:{
          hy:'Երևան',
          ru:'Ереван',
          en:'Yerevan',
        },
        region:null,
        regionByCity:139,
        url:'https://api.alfapharm.am/api/pharmacyBranch/regionByCity/139'
    },
    tsaghkadzor:{
        name:{
          hy:'Ծաղկաձոր',
          ru:'Цахкадзор',
          en:'Tsaghkadzor',
        },
        region:243,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/243'
    },
    dilijan:{
        name:{
          hy:'Դիլիջան',
          ru:'Дилиджан',
          en:'Dilijan',
        },
        region:252,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/252'
    },
    gyumri:{
        name:{
          hy:'Գյումրի',
          ru:'Гюмри',
          en:'Gyumri',
        },
        region:226,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/226'
    },
    kapan:{
        name:{
          hy:'Կապան',
          ru:'Капан',
          en:'Kapan',
        },
        region:233,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/233'
    },
    sevan:{
        name:{
          hy:'Սևան',
          ru:'Севан',
          en:'Sevan',
        },
        region:245,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/245'
    },
    vanadzor:{
        name:{
          hy:'Վանաձոր',
          ru:'Ванадзор',
          en:'Vanadzor',
        },
        region:224,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/224'
    },
    abovyan:{
        name:{
            hy:'Աբովյան',
            ru:'Абовян',
            en:'Abovyan',
        },
        region:289,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/289'
    },
    hrazdan:{
        name:{
            hy:'Հրազդան',
            ru:'Раздан',
            en:'Hrazdan',
        },
        region:290,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/290'
    },
    charentsavan:{
        name:{
          hy:'Չարենցավան',
          ru:'Чаренцаван',
          en:'Charentsavan',
        },
        region:247,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/247'
    },
    tashir:{
        name:{
            hy:'Տաշիր',
            ru:'Ташир',
            en:'Tashir',
        },
        region:288,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/288'
    },
    armavir:{
        name:{
          hy:'Արմավիր',
          ru:'Армавир',
          en:'Armavir',
        },
        region:231,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/231'
    },
    ashtarak:{
        name:{
          hy:'Աշտարակ',
          ru:'Аштарак',
          en:'Ashtarak',
        },
        region:249,
        regionByCity:null,
        url:'https://api.alfapharm.am/api/pharmacyBranch/region/249'
    },
}