import * as React from 'react';
import './style.scss';
import newProduct from "../../../../../assets/images/icons/newProduct.svg";
import Settings from "../../../../../platform/services/settings";

interface IProps {
    className?: string;
}

const NewProduct = React.memo(({ className }: IProps) => (
    <span className={`newProduct ${className ?? ''}`} style={{ backgroundImage: `url(${newProduct})` }}>
        {Settings.translations.NEW}
    </span>
));

export default NewProduct;