import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import Settings from 'platform/services/settings';
import HelperPureComponent from 'platform/classes/helper-pure-component';

import './style.scss';
import {Link} from "react-router-dom";

@byRoute(ROUTES.HOW_TO_ORDER_ONLINE)
class HowToUseApp extends HelperPureComponent<{}, {}> {

    private iframeProps = {
        frameBorder: 0,
        allowfullscreen: "allowfullscreen",
        mozallowfullscreen: "mozallowfullscreen",
        msallowfullscreen: "msallowfullscreen",
        oallowfullscreen: "oallowfullscreen",
        webkitallowfullscreen: "webkitallowfullscreen",
    }

    public render() {

        return (
            <div>
                <ul className="Breadcrumbs">
                    <li className='fromRoutes'><Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
                    <li className='activeRoute'> {Settings.translations.how_to_order_online}</li>
                </ul>
                <section className="G-page P-how-to-use-page">
                    <h1 className="G-page-title">{Settings.translations.how_to_order_online}</h1>
                    <h3>{Settings.translations.how_to_order_online_text}</h3>
                    <ul>
                        <li>{Settings.translations.how_to_order_online_text_li_1}</li>
                        <li>{Settings.translations.how_to_order_online_text_li_2}</li>
                        <li>{Settings.translations.how_to_order_online_text_li_3}</li>
                        <li>{Settings.translations.how_to_order_online_text_li_4}</li>
                        <li>{Settings.translations.how_to_order_online_text_li_5}</li>
                    </ul>
                    <br/>
                    <h3>{Settings.translations.how_to_order_online_text_2}</h3>
                    <ul>
                        <li>{Settings.translations.how_to_order_online_text_2_li_1}</li>
                        <li>{Settings.translations.how_to_order_online_text_2_li_2}</li>
                        <li>{Settings.translations.how_to_order_online_text_2_li_3}</li>
                        <li>{Settings.translations.how_to_order_online_text_2_li_4}</li>
                    </ul>
                    <br/>
                    <h3><a href='www.alfapharm.am'
                           target='_blank'>www.alfapharm.am</a>{Settings.translations.how_to_order_online_text_3}</h3>
                    <ul>
                        <li>{Settings.translations.how_to_order_online_text_3_li_1}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_2}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_3}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_4}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_5}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_6}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_7}</li>
                        <li>{Settings.translations.how_to_order_online_text_3_li_8}</li>

                    </ul>
                    <br/>
                    <p>Ալֆա-Ֆարմի օնլայն դեղատան առաքման ծառայությունը գործում է ք․ Երևանում, իսկ մարզերում պատվերները
                        առաքվում են Ալֆա-Ֆարմի մասնաճյուղեր՝ կարող եք պատվիրել Ձեզ անհրաժեշտ միջոցները և մոտենալ
                        դեղատնից ստանալ։ Առաքման արժեքը ք․ Երևանում կախված գնման չափից և օրվա ժամից կարող է փոփոխվել՝
                        մասնավորապես․</p>
                    <ul>
                        <li>մինչև 10․000 դրամ գնման դեպքում առավոտյան 09։00-ից մինչև երեկոյան 21։00-ն առաքման արժեքը կազմում է 600 դրամ,</li>
                        <li>մինչև 10․000 դրամ գնման դեպքում 21։00-ից մինչև առավոտյան 09։00-ն գրանցված պատվերների առաքման արժեքը 400 դրամ է,</li>
                        <li>10․000 դրամը գերազանցող պատվերների դեպքում առաքման արժեքը 200 դրամ է՝ անկախ օրվա ժամից։</li>
                        <li>դեպի մարզերի կամ ք․ Երևանի Ալֆա-Ֆարմի մասնաճյուղեր պատվերների առաքումն իրականացվում է անվճար,</li>
                    </ul>

                    <br/>
                    <h4>Օնլայն դեղատնից պատվեր գրանցելու համար գնման նվազագույն չափը պետք է կազմի 2000 դրամ։</h4>
                    <br/>
                    <h3>{Settings.translations.how_to_order_online_information_text_1}</h3>
                    <br/>
                    <p>
                        <a href='https://www.arlis.am/DocumentView.aspx?DocID=112738' target='_blank'
                           style={{color: '#0000ee'}}>
                            {Settings.translations.how_to_order_online_information_text_2}
                        </a>
                        {Settings.translations.how_to_order_online_information_text_3}
                    </p>
                    <ul>
                        <li>{Settings.translations.how_to_order_online_information_text_4}</li>
                        <li>{Settings.translations.how_to_order_online_information_text_5}</li>
                        <li>{Settings.translations.how_to_order_online_information_text_6}</li>
                    </ul>
                    <br/>
                    <p><b>{Settings.translations.how_to_order_online_information}</b></p>
                </section>
            </div>
        );
    }
};

export default HowToUseApp;
