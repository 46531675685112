import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import ListItem from './components/list-item';
import { IProductListResponseModel } from 'platform/api/product/models/response';

import './style.scss';
import ProductController from 'platform/api/product';
import Slider from "react-slick";
import Environment from "../../../../../platform/services/environment";
import Skeleton from "../../../../../components/Skeleton";

interface IState {
  data: IProductListResponseModel[];
}

class ProductOfTheDay extends HelperComponent<{}, IState> {

  public state: IState = {
    data: [],
  };


  public componentDidMount() {
    this.fetchData();
  }
  private fetchData = async () => {
    const body = {
      productText: '',
      categoryIds: [Environment.ProductOfTheDay],
      brandIds: [],
      producerIds: [],
      activeIngredientIds: [],
      sortBy: 1,
      hasDiscount: false,
      pageSize:15,
      pageNumber:1,
    };
    const result = await ProductController.GetList(body);
    this.safeSetState({ data: result && result.data && result.data.list  || [] });
  }
  public settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  }
  public render() {

    const { data } = this.state;
    return data.length ? (
      <section className=" P-home-product-of-the-day">
        <div className="P-list-wrapper">
          <Slider
            {...this.settings}
            arrows={true}
            swipe={false}
          >
            {data.map(item => <div key={item.id}>
              <ListItem data={item} />
            </div>)}
          </Slider>
        </div>
      </section>
    ) : <Skeleton className={'product-of-day-skeleton'} count={1} type={'card'} />;
  }

}


export default ProductOfTheDay;