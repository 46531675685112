import * as React from 'react';
import HelperComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import Table from 'components/table';
import {
    IBonusCardHistoryExtItemResponseModel, IBonusCardHistoryExtResponseModel
} from 'platform/api/bonusCard/models/response';
import {formatDate} from 'platform/services/helper';
import Pagination from 'components/pagination';
import BonusCardController from 'platform/api/bonusCard';
import EmptyState from "../../../../../../components/empty-state";
import * as animationData from "../../../../../../assets/animations/EmptyOrderList.json";
import * as loadingData from 'assets/animations/loading.json';

interface IState {
    data?: any;
    isLoading?: boolean;
    smallMedia?: boolean;
    itemsPerPage?: number;
};

class HistoryExt extends HelperComponent<IState, {}> {

    public state: IState = {
        data: [],
        isLoading: true,
        smallMedia: false,
        itemsPerPage: 15
    };

    public componentDidMount() {
        if (window.innerWidth <= 900) {
            this.safeSetState({smallMedia: true})
        }
        this.fetchData
    }

    private columnConfig = [
        {
            name: Settings.translations.bonus_card,
            cell: (row: IBonusCardHistoryExtItemResponseModel) => row.cardNumber,
        },
        {
            name: Settings.translations.accumulated_points,
            cell: (row: IBonusCardHistoryExtItemResponseModel) => row.accumulation,
        },

        {
            name: Settings.translations.used_points,
            cell: (row: IBonusCardHistoryExtItemResponseModel) => row.consumption,
        },
        {
            name: Settings.translations.balance,
            cell: (row: IBonusCardHistoryExtItemResponseModel) => row.balance,
        },
        {
            name: Settings.translations.date,
            cell: (row: IBonusCardHistoryExtItemResponseModel) => formatDate(row.date)?.split(' | ')?.[0],
        },
    ];
    public handleItemsPerPageChange = (itemsPerPage: number) => {
        this.setState({itemsPerPage});
    };
    private fetchData = async (pageNumber: number, itemsPerPage: number) => {
        const body = {
            pageNumber,
            pageSize: itemsPerPage,
        };

        const result = await BonusCardController.GetBonusCardHistoryExt(body);
        this.safeSetState({data: result.data.list, isLoadingExt: false})
        return result.success && result.data
    }


    public render() {
        const {data, isLoading, smallMedia} = this.state;

        return (
            <>
                <h2 className="G-clr-main G-mb-30">{Settings.translations.bonus_card}</h2>
                <Table className={'BonusHistoryExtTable'} isBonusHistory={true} columnConfig={this.columnConfig} data={data}/>
                {!data &&
                    <EmptyState animationData={isLoading ? loadingData : animationData} height={175}
                                text={isLoading ? '' : Settings.translations.bonus_card_empty}/>}
                <Pagination<IBonusCardHistoryExtResponseModel>
                    showItemsPerPage={true}
                    fetchData={this.fetchData}
                    onItemsPerPageChange={this.handleItemsPerPageChange}
                />
            </>
        );
    }
}

export default HistoryExt;
