import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import Connection from 'platform/services/connection';
import { IPagingResponse } from 'platform/constants/interfaces';

import './style.scss';
import Settings from "../../platform/services/settings";

interface IState {
  pageCount: number;
  selectedPage: number;
  numberValue: number;
  itemsPerPage: number;
}

interface IProps<Data> {
  page?: number;
  count?: boolean;
  interval: number;
  fetchData: (page: number, itemsPerPage: number) => Promise<IPagingResponse<Data>>;
  pageChangeListener?: string;
  classNameList?: string;
  defaultItemsPerPage?: number;
  showItemsPerPage?: boolean;
  onItemsPerPageChange?: (itemsPerPage: number) => void;  // New callback prop
}

class Pagination<Data> extends HelperComponent<IProps<Data>, IState> {

  public state: IState = {
    pageCount: 1,
    selectedPage: 1,
    numberValue: 1,
    itemsPerPage: this.props.defaultItemsPerPage || 15
  }

  public static defaultProps = {
    interval: 3,
    defaultItemsPerPage: 15,
    showItemsPerPage: false
  };

  public componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { page, pageChangeListener } = this.props;

    pageChangeListener && window.addEventListener(pageChangeListener, this.outsidePageChange);

    this.safeSetState({ selectedPage: page || Number(query.get('page')) || 1 }, async () => {
      await this.getList();
      this.checkSelectedPage();
    });
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
    const { pageChangeListener } = this.props;
    pageChangeListener && window.removeEventListener(pageChangeListener, this.outsidePageChange);
  }

  private outsidePageChange = async (e: CustomEvent) => {
    await this.selectPage(e.detail, true);
    this.checkSelectedPage();
  }

  private get interval() { return this.props.interval + 1; }

  private checkSelectedPage = () => {
    const { pageCount } = this.state;
    const query = new URLSearchParams(window.location.search);
    const x = Number(query.get('page'));

    if (x > (pageCount || 1)) {
      this.selectPage(pageCount);
      this.safeSetState({ selectedPage: pageCount });
    } else this.safeSetState({ selectedPage: x || 1 });
  }

  public getList = async (outside = false) => {
    const { selectedPage, itemsPerPage, pageCount } = this.state;
    const { fetchData } = this.props;

    pageCount > 1 && !outside && Connection.AbortAll();
    const result = await fetchData(selectedPage, itemsPerPage);
    result && this.safeSetState({ pageCount: result.pageCount });
  }

  public selectPage = async (selectedPage: number, outside = false) => this.safeSetState({ selectedPage }, async () => {
    const query = new URLSearchParams(window.location.search);
    query.set('page', (selectedPage || 1).toString());

    window.history.replaceState({ path: window.location.pathname }, '', `?${query}`);
    await this.getList(outside);
  });

  private handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const itemsPerPage = Number(e.target.value);
    this.safeSetState({ itemsPerPage, selectedPage: 1 }, async () => {
      const { onItemsPerPageChange } = this.props;
      onItemsPerPageChange && onItemsPerPageChange(itemsPerPage);
      await this.getList();
    });
  }

  public getByNumber = (e: React.SyntheticEvent<HTMLInputElement>) => this.safeSetState({ numberValue: Math.round(+e.currentTarget.value) });

  public confirmNumber = (e: any) => {
    const { pageCount, numberValue } = this.state;
    e.key === 'Enter' && pageCount >= numberValue && numberValue >= 1 && this.selectPage(numberValue);
  }

  public render() {
    const { selectedPage, pageCount, itemsPerPage } = this.state;
    const { count, classNameList, showItemsPerPage } = this.props;

    return (
        <div className={`ABM-pagination-wrap ${classNameList}`}>
          {pageCount > 1 && <div className="ABM-pages-wrap">
            {(selectedPage === 1 || selectedPage - 1 === 1 || selectedPage - 2 === 1) ? null :
                <div className="ABM-page" onClick={() => this.selectPage(1)}>{1}</div>
            }
            {selectedPage - 3 > 1 ? <span>...</span> : null}
            {selectedPage - 2 >= 1 ?
                <div className="ABM-page" onClick={() => this.selectPage(selectedPage - 2)}>{selectedPage - 2}</div>
                : null}
            {selectedPage - 1 >= 1 ?
                <div className="ABM-page" onClick={() => this.selectPage(selectedPage - 1)}>{selectedPage - 1}</div>
                : null}
            <div className={`ABM-page ABM-selected`} onClick={() => this.selectPage(selectedPage)}>{selectedPage}</div>
            {selectedPage + 1 <= pageCount ?
                <div className="ABM-page" onClick={() => this.selectPage(selectedPage + 1)}>{selectedPage + 1}</div>
                : null}
            {selectedPage + 2 <= pageCount ?
                <div className="ABM-page" onClick={() => this.selectPage(selectedPage + 2)}>{selectedPage + 2}</div>
                : null}
            {selectedPage + 3 < pageCount ? <span>...</span> : null}

            {(selectedPage === pageCount || selectedPage + 1 === pageCount || selectedPage + 2 === pageCount) ? null :
                <div className="ABM-page" onClick={() => this.selectPage(pageCount)}>{pageCount}</div>
            }

            {showItemsPerPage && (
                <div className="ABM-items-per-page">
                  <label htmlFor="itemsPerPage">{Settings.translations.items_per_page}:</label>
                  <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={this.handleItemsPerPageChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
            )}

            {!!count && <input
                type="number"
                className="ABM-count-input"
                onChange={this.getByNumber}
                onKeyPress={this.confirmNumber}
            />}
          </div>}
        </div>
    );
  }
}

export default Pagination;
