import * as React from 'react';
import {NavLink} from 'react-router-dom';
import Storage from 'platform/services/storage';
import PersonImage from '../../../../assets/images/person.png';
import {getMediaPath} from 'platform/services/helper';
import HelperComponent from 'platform/classes/helper-component';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import DispatcherChannels from 'platform/constants/dispatcher-channels';

import './index.scss';
import LanguageSwitcher from '../language-switcher';
import ContactUs from "../../../../modules/pages/home/components/contact-us";
import {contactEmail, contactHeaderPhoneNumber} from "../../../../platform/constants/contact-info";
import NotificationController from "../../../../platform/api/notification";
import Notifications from "../notifications";
import Socket from "../../../../platform/services/socket";

interface IState {
    search: string;
    categoryOpen?: any;
    additionalMenu: boolean,
    contactWrapper: boolean,
    notificationIconNumber: number,
    notificationOpen: boolean,
}

interface IProps {
    onClose(): void;

    onAuthOpen(): void;
}

class AppMobileMenu extends HelperComponent<IProps, IState> {

    public state: IState = {
        search: '',
        categoryOpen: false,
        additionalMenu: false,
        contactWrapper: false,
        notificationIconNumber: 0,
        notificationOpen: false,
    };

    public componentDidMount() {
        document.body.style.overflow = 'hidden';
        Storage.profile && this.configureNotifications();
        this.connectNotificationsSocket();
    }
    private connectNotificationsSocket() {
        Socket.connection && Socket.connection.on('NewMessage', () => {
            const {notificationIconNumber} = this.state;
            this.safeSetState({notificationIconNumber: notificationIconNumber + 1});
        });
    }
    private timer: any;
    public async configureNotifications() {
        this.timer = setInterval(async () => { // Temporary TODO -> change to socket
            const result = await NotificationController.GetUnseenList();
            if (result && result.success) {
                this.safeSetState({notificationIconNumber: result.data.unseen});
            }
        }, 10000)
    }
    public componentWillUnmount() {
        super.componentWillUnmount();
        document.body.style.overflow = 'initial';
    }

    private logout = () => {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.ToggleConfirm, {detail: {text: Settings.translations.log_out_question}}));
        window.addEventListener(DispatcherChannels.UserConfirmed, Settings.logout);
        window.addEventListener(DispatcherChannels.UserCanceled, this.logoutCanceled);
    };
    private categoryOpenLink2 = React.createRef<HTMLAnchorElement>();

    private showNotification = () => {
        const {onClose} = this.props;
        onClose();
        this.toggleNotifications
    };

    private showAdditionalMenu = () => {
        const {additionalMenu} = this.state;
        this.safeSetState({additionalMenu: !additionalMenu})
    };
    private showContactWrapper = () => {
        const {contactWrapper, additionalMenu} = this.state;
        this.safeSetState({contactWrapper: !contactWrapper, additionalMenu: !additionalMenu})
    };

    private closeCategories = (e?: MouseEvent) => {
        const {categoryOpen} = this.state;
        const canBeClosed = !e || (
            this.categoryOpenLink2.current &&
            !this.categoryOpenLink2.current.contains(e.target as Node)
        );

        if (categoryOpen && canBeClosed) {
            this.safeSetState({categoryOpen: false});
            document.removeEventListener('mousemove', this.closeCategories);
        }
    };

    private logoutCanceled = () => {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.ToggleConfirm));
        window.removeEventListener(DispatcherChannels.UserConfirmed, Settings.logout);
        window.removeEventListener(DispatcherChannels.UserCanceled, this.logoutCanceled);
    };
    private toggleNotifications = (e?: Event | React.SyntheticEvent) => {
        e && e.stopPropagation();
        const {notificationOpen} = this.state;
        if (!notificationOpen) {
            this.configureNotifications();
        }
        if (window.innerWidth < 901) {
            this.safeSetState({appMobileMenuOpen: true});
        }
        this.safeSetState({notificationOpen: !notificationOpen});
    };

    private onNotificationSeenChange = (all: boolean) => {
        const {notificationIconNumber} = this.state;
        if (notificationIconNumber && notificationIconNumber > 0) {
            this.safeSetState({notificationIconNumber: all ? 0 : notificationIconNumber - 1});
        }
    };
    public render() {
        const {onClose, onAuthOpen} = this.props;
        const {additionalMenu, contactWrapper, notificationIconNumber,notificationOpen} = this.state;

        return (
            <>
                <div className="P-app-mobile-menu-wrapper">
                    <div className="P-mobile-menu">
                        <LanguageSwitcher/>
                        <span className="P-mobile-menu-close"><i className="icon-Group-5032 G-clr-orange G-cursor-pointer"
                                                                 onClick={onClose}/></span>
                        {Storage.profile ? <div className="P-menu-profile-name G-text-center">

                            <div className={'user-info'}>
                                <div
                                    style={{background: `url('${Storage.profile.photoPath ? getMediaPath(Storage.profile.photoPath) : PersonImage}') center/cover`}}
                                    className="P-image"
                                />
                                <span
                                    className="G-fs-18 G-text-bold">{Storage.profile.firstName} {Storage.profile.lastName}</span>
                                <span className="G-fs-18">{Storage.profile.phoneNumber}</span>
                            </div>
                        </div> : <div className="P-menu-profile-name G-text-center">

                            <div className={'user-info'}>
                                <div
                                    style={{background: `url('${PersonImage}') center/cover`}}
                                    className="P-image"
                                />
                                <span
                                    onClick={onAuthOpen}
                                    className="P-link P-login P-login-mobile G-main-ghost-button G-ml-auto G-mr-auto"
                                >{Settings.translations.log_in}</span>
                            </div>
                        </div>}

                        {Storage.profile ?
                            <div className="P-mobile-menu-content">
                                {!Settings.token &&
                                    <a className="P-link" onClick={onAuthOpen}>{Settings.translations.log_in}</a>}
                                <div className={'menu-items-wrapper'}>
                                    <NavLink
                                        className="P-link P-icon-profile"
                                        to={ROUTES.PROFILE.MAIN}
                                        onClick={onClose}
                                    >{Settings.translations.profile_page}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-special_products"
                                        activeClassName="P-active"
                                        to={`${ROUTES.PRODUCTS.MAIN}?sortBy=6&hasDiscount=true&page=1`}
                                        onClick={onClose}
                                    >{Settings.translations.special_offers}</NavLink>
                                    <div
                                        className="P-link P-icon-notifications"
                                        onClick={this.toggleNotifications}
                                    >
                                        {notificationIconNumber > 0 &&
                                            <span className={'notif-count'}>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}
                                        {Settings.translations.notifications}
                                    </div>
                                    {/*{Storage.profile &&*/}
                                    {/*    <a onClick={this.toggleNotifications}*/}
                                    {/*       className="P-link P-icon G-normal-link P-notification ">*/}
                                    {/*        <i className="icon-Group-5515"/>*/}
                                    {/*        {!!notificationIconNumber &&*/}
                                    {/*            <span>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}*/}
                                    {/*    </a>}*/}
                                    <NavLink
                                        className="P-link P-icon-bonus_card"
                                        to={ROUTES.PROFILE.BONUS_CARD}
                                        onClick={onClose}
                                    > {Settings.translations.bonus_card}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-orders"
                                        to={ROUTES.PROFILE.ORDERS.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.orders}</NavLink>
                                    {/*hishecum*/}

                                    <NavLink
                                        className="P-link P-icon-prescription"
                                        to={ROUTES.PROFILE.PRESCRIPTIONS.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.prescription}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-favorites"
                                        to={ROUTES.PROFILE.FAVORITES.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.favorites}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-my_registrations"
                                        to={ROUTES.PROFILE.MY_REGISTRATIONS.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.my_registrations}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-addresses"
                                        to={ROUTES.PROFILE.ADDRESSES.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.addresses}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-my_wallet"
                                        to={ROUTES.PROFILE.MY_WALLET}
                                        onClick={onClose}
                                    > {Settings.translations.my_wallet}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-invite"
                                        to={ROUTES.PROFILE.MY_INVITES}
                                        onClick={onClose}
                                    > {Settings.translations.invite}</NavLink>
                                    {/*ayl*/}
                                    <div
                                        className="P-link P-icon-artboard"
                                        onClick={this.showAdditionalMenu}
                                    >{Settings.translations.other_offers}</div>

                                    {Storage.profile && <div onClick={this.logout} className="P-link P-icon-logout">
                                        {Settings.translations.log_out}
                                    </div>}

                                    {/*<NavLink*/}
                                    {/*    className="P-link P-icon-bonus_card"*/}
                                    {/*    activeClassName="P-active"*/}
                                    {/*    to={ROUTES.PROFILE.BONUS_CARD}*/}
                                    {/*    onClick={onClose}*/}
                                    {/*> {Settings.translations.bonus_card}</NavLink>*/}


                                    {/*<Link*/}
                                    {/*    to={ROUTES.PRODUCTS.MAIN}*/}
                                    {/*    innerRef={this.categoryOpenLink2}*/}
                                    {/*    onMouseOver={this.openCategories}*/}
                                    {/*    onClick={()=> {*/}
                                    {/*      this.safeSetState({ categoryOpen: !this.state.categoryOpen });*/}
                                    {/*    }}*/}
                                    {/*    className="P-link P-icon-online-pharmacy"*/}
                                    {/*>*/}
                                    {/*  {Settings.translations.online_pharmacy}*/}
                                    {/*  {this.state.categoryOpen && <Categories onClose={this.closeCategories} />}*/}
                                    {/*</Link>*/}


                                </div>

                                {Storage.profile && <hr className="G-my-15"/>}

                            </div> : <div className="P-mobile-menu-content">
                                {!Settings.token &&
                                    <a className="P-link" onClick={onAuthOpen}>{Settings.translations.log_in}</a>}
                                <div className={'menu-items-wrapper guest-wrapper'}>
                                    <NavLink
                                        className="P-link P-icon-pharmacy"
                                        to={ROUTES.PHARMACIES.MAIN}
                                        onClick={onClose}
                                    >{Settings.translations.pharmacies}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-news"
                                        to={`${ROUTES.NEWS.MAIN}?sortBy=6&hasDiscount=true&page=1`}
                                        onClick={onClose}
                                    >{Settings.translations.news}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-blog"
                                        activeClassName="P-active"
                                        to={ROUTES.BLOG.MAIN}
                                        onClick={onClose}
                                    >{Settings.translations.blog}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-about-us"
                                        to={ROUTES.ABOUT_US}
                                        onClick={onClose}
                                    > {Settings.translations.about_us}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-career"
                                        to={ROUTES.VACANCIES.MAIN}
                                        onClick={onClose}
                                    > {Settings.translations.vacancies}</NavLink>
                                    <NavLink
                                        className="P-link P-icon-useful-links"
                                        to={ROUTES.HOW_TO_ORDER_ONLINE}
                                        onClick={onClose}
                                    > {Settings.translations.useful_links}</NavLink>
                                    <div
                                        className="P-link P-icon-contact-us"
                                        onClick={this.showContactWrapper}
                                    >{Settings.translations.contact}</div>
                                </div>
                            </div>
                        }

                        <div className="P-mobile-menu-layer" onClick={onClose}/>
                    </div>

                    <div className={`additional-menu ${additionalMenu ? 'show' : 'hide'}`}>
                        <div className={`header-line`}>
              <span className="P-additional-menu-close G-clr-main" onClick={this.showAdditionalMenu}>
                <i className="G-back-icon icon-Group-5529  G-cursor-pointer"/>
                  {Settings.translations.other_offers}
              </span>
                        </div>
                        <div className="P-mobile-menu-content">
                            {!Settings.token &&
                                <a className="P-link" onClick={onAuthOpen}>
                                    {Settings.translations.log_in}
                                </a>}
                            <div className={'menu-items-wrapper'}>
                                <NavLink
                                    className="P-link P-icon-pharmacy"
                                    to={ROUTES.PHARMACIES.MAIN}
                                    onClick={onClose}
                                >{Settings.translations.pharmacies}</NavLink>
                                <NavLink
                                    className="P-link P-icon-news"
                                    to={`${ROUTES.NEWS.MAIN}?sortBy=6&hasDiscount=true&page=1`}
                                    onClick={onClose}
                                >{Settings.translations.news}</NavLink>
                                <NavLink
                                    className="P-link P-icon-blog"
                                    activeClassName="P-active"
                                    to={ROUTES.BLOG.MAIN}
                                    onClick={onClose}
                                >{Settings.translations.blog}</NavLink>
                                <NavLink
                                    className="P-link P-icon-about-us"
                                    to={ROUTES.ABOUT_US}
                                    onClick={onClose}
                                > {Settings.translations.about_us}</NavLink>
                                <NavLink
                                    className="P-link P-icon-career"
                                    to={ROUTES.VACANCIES.MAIN}
                                    onClick={onClose}
                                > {Settings.translations.vacancies}</NavLink>
                                <NavLink
                                    className="P-link P-icon-useful-links"
                                    to={ROUTES.HOW_TO_ORDER_ONLINE}
                                    onClick={onClose}
                                > {Settings.translations.useful_links}</NavLink>
                                <div
                                    className="P-link P-icon-contact-us"
                                    onClick={this.showContactWrapper}
                                >{Settings.translations.contact}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`contact-wrapper ${contactWrapper ? 'show' : 'hide'}`}>
                        <div className={`header-line`}>
              <span className="P-additional-menu-close G-clr-main" onClick={this.showContactWrapper}>
                <i className="G-back-icon icon-Group-5529  G-cursor-pointer"/>
                  {Settings.translations.contact}
              </span>
                        </div>
                        <div className={`contact-us-detail`}>
                            <a href={`tel:${contactHeaderPhoneNumber}`} className={`headerPhone`}>
                                <i className="G-clr-orange icon-Group-5522"/>
                                <b>{contactHeaderPhoneNumber}</b>
                            </a>
                            <a href={`mailTo:${contactEmail}`}>
                                <i className="G-clr-orange icon-Group-5524"/> {contactEmail}
                            </a>
                            <a>
                                <i className="G-clr-orange icon-Group-5527"/>
                                {Settings.translations.contact_address}
                            </a>
                        </div>
                        <ContactUs/>
                    </div>
                </div>
                {notificationOpen &&
                    <Notifications onClose={this.toggleNotifications}
                                   onSeenChange={this.onNotificationSeenChange}/>

                }
            </>
        );
    }
}

export default AppMobileMenu;
