import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import HelperPureComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import './style.scss';
import {Shared} from 'modules';
import {NavLink} from "react-router-dom";

interface IState {
    authModalOpen: boolean;
}

@byRoute([ROUTES.THANK_FOR_PAYMENT])
class ThankForPayment extends HelperPureComponent<{}, IState> {
    public state: IState = {
        authModalOpen: false,
    };
    private toggleAuthModal = () => {
        const {authModalOpen} = this.state;
        this.safeSetState({authModalOpen: !authModalOpen});
    };

    public render() {
        const {authModalOpen} = this.state;
        return (
            <section className="G-page P-thank-for-payment-page">
                <div className="P-thank-for-payment">
                    <i className="icon-Group-5535"/>
                    {Settings.guest ? <>
                        <h3 className="text">{Settings.translations.guest_order_success}</h3>
                        <button id={'sign_up'} className="G-main-button G-normal-link G-mt-30 P-register-button"
                                onClick={this.toggleAuthModal}>{Settings.translations.sign_up}
                        </button>
                    </> : <>
                        <h3>{Settings.translations.order_success}</h3>
                        <NavLink className="G-main-button G-normal-link G-mt-30"
                                 to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}
                        </NavLink>
                    </>}
                </div>
                {authModalOpen && <Shared.Auth onClose={this.toggleAuthModal}/>}
            </section>
        );
    }

}

export default ThankForPayment;