import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';
import './style.scss';
import Settings from "../../../../../platform/services/settings";
import {IPharmacyBranchListResponseModel} from "../../../../../platform/api/pharmacyBranch/models/response";
import {cities} from "./cities";
import PharmacyBranchController from "../../../../../platform/api/pharmacyBranch";
import PageNotFound from "../../../page-not-found";
import {formatTime, getViewEnum} from "../../../../../platform/services/helper";
import {WeekDaysEnum} from "../../../../../platform/constants/enums";

interface IRouteParams {
    slug: string,
}

interface IState {
    data?: IPharmacyBranchListResponseModel[];
    isPage: boolean,
    pageName:string
}

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byRoute(ROUTES.PHARMACIES.DETAILS)
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {
    public state: IState = {
        isPage: false,
        pageName: ''
    };

    public componentDidMount() {
        this.fetchData();
    }

    // private goBack = () => window.routerHistory.goBack();
    // private goBack = () => window.location.href = window.location.origin + '/pharmacies';

    private fetchData = async () => {
        const {slug} = this.props.match.params;
        const cityObject = cities[slug];
        const lang:any=Settings.language
        const pageName = cityObject.name[lang]
        if (cityObject) {
            this.safeSetState({isPage: true});
            const id = cityObject.region ? cityObject.region : cityObject.regionByCity
            const result = cityObject.region ? await PharmacyBranchController.GetByRegion(id) : await PharmacyBranchController.GetByCity(id);
            this.safeSetState({data: result.data,pageName});
        }
    }
    private weeksViewEnum = getViewEnum(WeekDaysEnum);


    public render() {
        const {data, isPage,pageName} = this.state;

        return !isPage ? (
            <PageNotFound/>
        ) : data ? (
            <>
                <ul className="Breadcrumbs">
                    <li className='fromRoutes'> <Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
                    <li className='fromRoutes'> <Link to={ROUTES.PHARMACIES.MAIN}> {Settings.translations.pharmacies}</Link></li>
                    <li className='activeRoute'> {pageName}</li>
                </ul>
                <section className={'G-page'}>
                    <h1 className={'G-page-title'}>{pageName}</h1>
                    <div className={'info-wrapper'}>
                        {data.length ? data.map((item, index) =>
                                <div className="P-info-window" key={index}>
                                    <h3 className="G-clr-orange G-text-center P-name">{item.name}</h3>
                                    <h4 className="P-info-row G-flex-center">
                                        <i className="icon-Group-5522 G-clr-orange"/> <span>{item.contactPhoneNumber}</span>
                                    </h4>
                                    <h4 className="P-info-row">
                                        <i className="icon-Group-5554 G-clr-orange"/>
                                        <div>
                                            {item.workingPlan?.map((i, inx, arr) =>
                                                <span key={inx}>
                                        {Settings.translations[this.weeksViewEnum[i.startDay]]}
                                                    {i.endDay && i.startDay !== i.endDay ? '-' + Settings.translations[this.weeksViewEnum[i.endDay]] : ''}
                                                    &nbsp;&nbsp;
                                                    {i.isDayOff ? Settings.translations.day_off : `${formatTime(i.startTime)}-${formatTime(i.endTime)}`}
                                    </span>)}
                                        </div>
                                    </h4>
                                </div>)
                            : <h3>{Settings.translations.no_search_result}</h3>}
                    </div>
                </section>
            </>
            )
            : <PageLoader/>;
    }
};


export default Details;
