import * as React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';


import HelperPureComponent from 'platform/classes/helper-pure-component';
import ShadowText from 'components/shadow-text';
import Settings from 'platform/services/settings';
import BannerController from 'platform/api/banner';
import { IBannerListResponseModel } from 'platform/api/banner/models/response';

import { BannerTypeEnum,BannerType } from 'platform/api/banner/constants/enums';
import ROUTES from 'platform/constants/routes';

import './style.scss';
import { BackArrow, NextArrow } from 'components/slider-arrows';
import ProductOfTheDay from "../product-of-the-day";
import BonusCardController from "../../../../../platform/api/bonusCard";

interface IState {
  data: IBannerListResponseModel[];
}

class Carousel extends HelperPureComponent<{}, IState> {

  public state: IState = {
    data: [],
  };

  public componentDidMount() { this.fetchData(); }

  private fetchData = async () => {
    const result = await BannerController.GetList();
    this.safeSetState({ data: result.data });
  }

  private onItemClick = (item: IBannerListResponseModel) => {
    const urls = {
      [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId),
      [BannerTypeEnum.News]: ROUTES.NEWS.DETAILS.replace(':id', item.dataId),
      [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId).replace(':slug', item.slug),
      [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
      [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
      [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
    };

    window.routerHistory.push(urls[item.type]);
  }

  private onBanerItemClick = (item: IBannerListResponseModel) => {
    const lang:any=Settings.language
    if (item.slugs !== null ){
      const urls = {
        [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
        [BannerTypeEnum.News]: ROUTES.NEWS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
        [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
        [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
        [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
      };
      return urls[item.type]
    }else{
      const urls = {
        [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.News]: ROUTES.NEWS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
        [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
        [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
      };
      return urls[item.type]
    }
  }

  public render() {
    const { data } = this.state;

    return (
        <div className='G-parent'>
          <Slider
              dots={true}
              autoplay={true}
              autoplaySpeed={5000}
              slidesToShow={1}
              swipeToSlide={true}
              slidesToScroll={1}
              arrows={true}
              className="P-home-carousel-slider">
            {data.map(item =>
                <div key={item.id}>
                  <Link to={this.onBanerItemClick(item)} >
                    <div className=" P-home-carousel-slide  bg-position-bottom" style={{ background: `url('${item.photoPath}') center/cover  no-repeat`}}>
                      <ShadowText className="P-shadow-text-without-offset">{item.title}</ShadowText>
                      {!!item.type && item.dataId && <button id={'see_more_btn'} className="G-main-button" onClick={() => this.onItemClick(item)}>{Settings.translations.see_more}</button>}
                    </div>
                  </Link>
                </div>
            )}
          </Slider>
          <ProductOfTheDay />
          </div>

    );
  };
};

export default Carousel;
