import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';
import { IFAQListResponseModel } from 'platform/api/faq/models/response';

import './style.scss';
import FAQController from "../../../../../platform/api/faq";

interface IRouteParams { id:string,slug: string };

interface IState {
  data?: IFAQListResponseModel;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byRoute(ROUTES.FAQ.DETAILS)
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {
  public state: IState = {};

  public componentDidMount() { this.fetchData(); }

  private goBack = () => window.routerHistory.goBack();

  private fetchData = async () => {
    const { slug } = this.props.match.params;
    const { id } = this.props.match.params;
    const result = await FAQController.GetDetails(id,slug);
    this.safeSetState({ data: result.data });
  }

  public render() {
    const { data } = this.state;
    return data ? (
        <>

            <ul className="Breadcrumbs">
                <li className='fromRoutes'> <Link to={ROUTES.MN}> Home</Link></li>
                <li className='fromRoutes'><Link to={ROUTES.FAQ.MAIN}> FAQ</Link> </li>
                <li className='activeRoute'> { data.title}</li>
            </ul>
          <section className="G-page P-faq-details-page">
            {window.routerHistory.length > 2 && <i className="G-back-icon icon-Group-5529" onClick={this.goBack} />}
            <div className="P-content">
                <h2 className="P-name">{data.title}</h2>
              <p className="P-description" dangerouslySetInnerHTML={{ __html: data.description}} />
            </div>
          </section>
        </>

    ) : <PageLoader />;
  }
};

export default Details;
