import React from 'react';
import Settings from "../../../platform/services/settings";
import './style.scss';
import {NavLink, Route} from "react-router-dom";
import ROUTES from "../../../platform/constants/routes";



const PageNotFound = () => {
    return <div className={'pageNotFound'}>
        <h1 className="text">404</h1>
        <h1>{Settings.translations.page_not_found}</h1>
        <NavLink exact={true} className={'G-main-button mt-5'}
                 to={ROUTES.MN}>{Settings.translations.home}</NavLink>
    </div>;
}



export default PageNotFound;