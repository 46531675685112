import * as React from 'react';
import HelperComponent from "platform/classes/helper-component";
import {IProductFilterRequestModel} from 'platform/api/product/models/request';
import ROUTES from 'platform/constants/routes';
import Storage from 'platform/services/storage';
import {buildFilters, priceConfig} from '../../services/helper';
import Brands from './components/brands';
import Producers from './components/producers';
import ActiveIngredients from './components/active-ingredients';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PriceRange from './components/price-range';
import Settings from 'platform/services/settings';
import Screen from 'components/screen';
import './style.scss';
import Broadcast from 'platform/services/broadcast';
import FilterImage from 'assets/images/icons/filter.png';
import {ICategoryListResponseModel} from "../../../../../../../platform/api/category/models/response";
import ChildCategories from "./components/child-categories";


interface IProps {
    onChange(): void;
};

interface IState {
    categoryOpen: boolean;
    mobileOpen: boolean;
    filterOpen: boolean;
    openSearch: boolean;
    maxPrice: number;
    body: IProductFilterRequestModel;
    lists: ICategoryListResponseModel[][];
    childCategories: ICategoryListResponseModel[][];
    childCategoryOpen: boolean;
};

class Filter extends HelperComponent<IProps, IState> {
    public state: IState = {
        categoryOpen: false,
        mobileOpen: false,
        filterOpen: false,
        openSearch: false,
        maxPrice: priceConfig.maxPrice,
        body: {
            brandIds: [],
            producerIds: [],
            categoryIds: [],
            activeIngredientIds: [],
        },
        lists: [
            [ ...Storage.categories]
        ],
        childCategories:[],
        childCategoryOpen:true
    };

    public componentDidMount() {
        window.scrollTo(0, 0);
        this.safeSetState({body: buildFilters()});
        window.addEventListener(DispatcherChannels.ProductFilterChange, this.outsideFilterChange);
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener(DispatcherChannels.ProductFilterChange, this.outsideFilterChange);
    }

    public openCloseSearch = () => {
        this.safeSetState({openSearch: !this.state.openSearch});
    }

    private outsideFilterChange = (e: CustomEvent) => {
        const body = buildFilters(e.detail?.makeEmpty);
        this.bodyChange(body);
    }

    private cancel = () => {
        const query = new URLSearchParams(window.location.search);
        const {body} = this.state;

        body.brandIds = [];
        body.activeIngredientIds = [];
        body.categoryIds = [];
        body.producerIds = [];
        body.hasDiscount = false;
        body.stockQuantity = false;
        delete body.minPrice;
        delete body.maxPrice;

        query.delete('brandIds');
        query.delete('activeIngredientIds');
        query.delete('producerIds');
        query.delete('minPrice');
        query.delete('maxPrice');
        query.delete('hasDiscount');
        query.delete('stockQuantity');
        query.delete('categoryIds');

        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        window.scrollTo(0, 0);

        this.bodyChange({...body});
        this.safeSetState({childCategoryOpen:false});
        Broadcast.dispatch(DispatcherChannels.ProductFilterClear);
    }

    private chooseMainCategory = (id: number) => {
        const {body} = this.state;
        const {onChange} = this.props;

        const query = new URLSearchParams(window.location.search);
        query.set('categoryIds', `${id}`);

        body.categoryIds = [id];
        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        // window.location.href = window.location.origin + window.location.pathname + `?${query.toString()}`
        this.safeSetState({body}, onChange);
    }

    private reset = () => {
        window.location.href = ROUTES.PRODUCTS.MAIN;
    }

    private bodyChange = (body: IProductFilterRequestModel) => {
        const {onChange} = this.props;
        this.safeSetState({body}, onChange);
    }

    private toggleMobile = () => {
        const {mobileOpen} = this.state;
        this.safeSetState({mobileOpen: !mobileOpen});
    }
    private toggleFilter = () => {
        const {filterOpen} = this.state;
        this.safeSetState({filterOpen: !filterOpen});
    }

    private toggleDiscount = () => {
        const {body} = this.state;
        const {onChange} = this.props;

        body.hasDiscount = !body.hasDiscount;
        const query = new URLSearchParams(window.location.search);

        if (body.hasDiscount) query.set('hasDiscount', 'true');
        else query.delete('hasDiscount');

        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        this.safeSetState({body}, onChange);
    }
    private toggleQuantity = () => {

        const {body} = this.state;
        const {onChange} = this.props;

        body.stockQuantity = !body.stockQuantity;
        const query = new URLSearchParams(window.location.search);

        if (body.stockQuantity) query.set('stockQuantity', 'true');
        else query.delete('stockQuantity');

        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        this.safeSetState({body}, onChange);
    }

    public render() {
        const {body, mobileOpen} = this.state;
        return (
            <Screen.Tablet>
                {(match: boolean) => match ? <>
                    {!!body.productText &&
                        <i className="P-back-icon-mini G-back-icon icon-Group-5529" onClick={this.reset}/>}
                    <h2 onClick={this.toggleMobile} className="P-products-filter-toggle"><img src={FilterImage}
                                                                                              alt=""/>{Settings.translations.filter}
                        <i className="icon-Group-5504"/></h2>
                    {mobileOpen && <this.Content/>}
                </> : <this.Content/>}
            </Screen.Tablet>
        );
    }
    // private categoryOpenLink = React.createRef<HTMLAnchorElement>();
    // private closeCategories = (e?: MouseEvent) => {
    //     const {categoryOpen} = this.state;
    //     const canBeClosed = !e || (
    //         this.categoryOpenLink.current &&
    //         !this.categoryOpenLink.current.contains(e.target as Node)
    //     );
    //
    //     if (categoryOpen && canBeClosed) {
    //         this.safeSetState({categoryOpen: false});
    //         document.removeEventListener('mousemove', this.closeCategories);
    //     }
    // };

    private Content = () => {
        const {body, maxPrice, filterOpen,lists} = this.state;
        return (
            <div className="P-products-filter mobile-filter">
                  <span className="P-mobile-menu-close"><i className="icon-Group-5032 G-cursor-pointer"
                                                           onClick={this.toggleMobile}/></span>
                <div className="P-filter-top">
                    <h4 className="G-h2-calc">{Settings.translations.filter}</h4>
                    <span className="G-h4-calc" onClick={this.cancel}>{Settings.translations.cancel}</span>
                </div>

                <div className="P-filter-discount">
                    <h4>{Settings.translations.sale}</h4>

                    <label className="P-switch">
                        <input type="checkbox" checked={body.hasDiscount} onChange={this.toggleDiscount}/>
                        <span className="P-slider"/>
                    </label>
                </div>
                <div className="P-filter-quantity">
                    <h4>{Settings.translations.of_stock}</h4>

                    <label className="P-switch">
                        <input type="checkbox" checked={body.stockQuantity} onChange={this.toggleQuantity}/>
                        <span className="P-slider"/>
                    </label>
                </div>

                <i className="G-back-icon icon-Group-5529" onClick={this.reset}/>
                <PriceRange body={body} onChange={this.bodyChange} maxPriceValue={maxPrice}/>
                {/*<Categories  onClose={this.closeCategories}/>*/}
                <div className="P-row-wrap">
                    {<h2 className="categories-title">{Settings.translations.categories}</h2>}
                    <div className=" P-categories-list">
                        {lists[0].map((item) => <h4
                            key={item.id}
                            onClick={() => this.chooseMainCategory(item.id)}
                            className="P-main-category"
                        >{item.productsCount && item.name.length > 2 ? item.name : null}</h4>)}
                    </div>
                </div>
                {/*///////////////////////*/}
                {/*Categoryyy*/}
                {/*{this.state.childCategoryOpen &&*/}
                {/*    <div className="P-row-wrap">*/}
                {/*        <ChildCategories body={body} onChange={this.bodyChange}/>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*///////////////////////*/}



                {/* <label className="P-discount-label">
          <CheckBox checked={body.hasDiscount} onChange={this.toggleDiscount} />
          {Settings.translations.sale}
        </label> */}

                <div className="P-row-wrap">
                    <Brands body={body} onChange={this.bodyChange}/>
                </div>
                <div className="P-row-wrap">
                    <Producers body={body} onChange={this.bodyChange}/>
                </div>
                <div className="P-row-wrap">
                    <ActiveIngredients body={body} onChange={this.bodyChange}/>
                </div>

                {/* <div className={`P-manufacture ${this.state.openSearch ? "P-opened-search" : ""}`}>
            <h4>Manufactures</h4>

            <div className="P-search-input-wrap">
                <input type="search" placeholder="Search for manufacture" />
            </div>

            <div className="P-filter-items-wrap">
                <p className="P-chacked">AJ Aliance</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Akarpia</p>
                <p>Aguettant</p>
            </div>

            <span className="P-show-all-less" onClick={() => {this.openCloseSearch()}}>{`${this.state.openSearch ? "Show less" : "Show all"}`}</span>

        </div> */}

                {/* <button
          className="G-main-ghost-button P-cancel-button"
          onClick={this.cancel}
        >{Settings.translations.cancel}</button> */}

            </div>
        );
    }
}

export default Filter;
