import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import Settings from 'platform/services/settings';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import './style.scss';
import {Link} from "react-router-dom";
import pdf1 from './pdf/fs1.pdf';
import pdf2 from './pdf/fs2.pdf';
import pdf3 from './pdf/fs3.pdf';
import pdf4 from './pdf/fs4.pdf';
import pdf5 from './pdf/fs5.pdf';
import pdf6 from './pdf/fs6.pdf';
@byRoute(ROUTES.FINANCIAL_STATEMENTS)
class HowToUseApp extends HelperPureComponent<{}, {}> {


  public render() {

    return (
        <div>
            <ul className="Breadcrumbs">
                <li className='fromRoutes'> <Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
                <li className='activeRoute'> {Settings.translations.financial_statements}</li>
            </ul>
            <section className="G-page P-financial-statements">
                <h1 className="G-page-title">{Settings.translations.financial_statements}</h1>
                <ul>
                    <li><a href={pdf1} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs1}</a></li>
                    <li><a href={pdf2} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs2}</a></li>
                    <li><a href={pdf3} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs3}</a></li>
                    <li><a href={pdf4} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs4}</a></li>
                    <li><a href={pdf5} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs5}</a></li>
                    <li><a href={pdf6} rel="noreferrer" target={'_blank'} download={true}>{Settings.translations.fs6}</a></li>
                </ul>
            </section>
        </div>
    );
  }
};

export default HowToUseApp;
