import React, {useState} from "react";
import "./style.scss";
import WheelComponent from "react-wheel-of-prizes";
import ClickOutside from "../click-outside";
import Modal from "../modal";
import Settings from "../../platform/services/settings";
import Logo from "../../assets/images/alpha-logo-white.png"
import {countryCode} from "../../platform/constants";
import description from './data'
import * as copy from "copy-to-clipboard";
import BlogController from "../../platform/api/blog";
interface IProps {
    onClose(): void;
};


const Wheel = React.memo(({onClose}: IProps) => {
        // const ponitschek = () => {
        //     if (!points) {
        //         alert("get more points");
        //     }
        // };
        // const [selected, setSelected] = useState(null);
        const [result, setResult] = useState('');
        const [value, setValue] = useState('');
        const [descriptionResult, setDescriptionResult] = useState('');
        const [isSpinning, setIsSpinning] = useState(false);
        // const [points, setPoints] = useState(false);
        const [hide, setHide] = useState(false);
        const [button, setButton] = useState(false);
        const [buttonText, setButtonText] = useState('');
        const [valid, setValid] = useState(false);
        const [count, setCount] = useState(false);
        const [failed, setFailed] = useState(false);
        const segments = [
            "Jackpot",
            "Promocode 10%",
            "Շատ մոտ էր",
            "Անվճար առաքում",
            "Վայ ափսոս",
            "Promocode 11%",
            "Կսպասենք քեզ վաղը",
            "Փորձանմուշներ",
            "Վաղը կրկին փորձիր",
            'Promocode 12%',
        ];
        const segColors = [
            "#50b548",
            "#ee7027",
            "#f7a416",
            "#ee7027",
            "#f7a416",
            "#ee7027",
            "#f7a416",
            "#ee7027",
            "#f7a416",
            "#ee7027",
        ];


        const canvas = document.getElementById('canvas') as HTMLDivElement;
        const canvasParent = document.getElementById('wheel-box') as HTMLDivElement;


        const changePhoneField = (e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.replace(/\D/g, '');
            if (e.target.value.length === 8) {
                // canvas.style.pointerEvents ='none'
                setValid(true)
            } else if (e.target.value.length > 8) {
                e.target.value = e.target.value.slice(0, -1)
                setValid(true)
                // canvas.style.left ='0'
                // canvas.click()
                // canvas.style.pointerEvents ='none'
            } else {
                setValid(false)
            }
            setValue(e.target.value)
        }

        const onFinished = (winner) => {
            setResult(winner)
            setDescriptionResult(description[winner].text)
            setButton(description[winner].button)
            setButtonText(description[winner].buttonText)
            setFailed(description[winner].failed)
            setCount(true)
        };

        // const handleSpin = (selectedItem: any) => {
        //     setSelected(selectedItem);
        //     setIsSpinning(false);
        // };

        const handleStartSpin = async () => {
            if (value.length === 8) {
                await BlogController.Phone(Number(value));
            }
            const rightWrapper = document.getElementById('right-wrapper') as HTMLDivElement;
            const title = document.getElementById('title') as HTMLDivElement;
            canvas.click()
            canvasParent.style.left = '0'
            canvasParent.style.top = '0'
            rightWrapper.style.display = 'none'
            setIsSpinning(true);
            setTimeout(() => {
                canvasParent.classList.add('wheel-passive')
                rightWrapper.style.display = 'flex'
                title.style.display = 'none'
                rightWrapper.style.top = '40px'
            }, 6200)

        }
        const handleHideInputFieldSpin = () => {
            setHide(true);
        }
        const handleCopy = async () => {
            copy(buttonText);
            const alertify = await import('alertifyjs');
            alertify.success(Settings.translations.copied_promocode_to_clipboard)
        };

        return (
            <Modal onClose={onClose} className="P-spin-modal">
                <ClickOutside onClickOutside={onClose}>
                    <div className={'parent-wrapper'}>
                        <div className="wheel-box" id={'wheel-box'}>
                            {/*<Screen.*/}
                            {/*<div className={'active-item-color'}>*/}
                            {/*    <div className={'arrow-top'}/>*/}
                            {/*</div>*/}
                            <div className={'center-logo'}><img src={Logo} alt=""/></div>
                            <canvas
                                id="canvas"
                                width="600"
                                height="600"
                            >
                                <WheelComponent
                                    segments={segments}
                                    segColors={segColors}
                                    winningSegment="won 60"
                                    onFinished={(winner) => onFinished(winner)}
                                    primaryColor="white"
                                    contrastColor="white"
                                    buttonText="AlfaPharm"
                                    isOnlyOnce={true}
                                    font={"3000px"}
                                    upDuration={100}
                                    downDuration={600}
                                    onRotate={undefined}
                                    onRotatefinish={undefined}
                                />
                            </canvas>
                        </div>
                        <div className={'right-wrapper'} id={'right-wrapper'}>
                            {!count ?
                                <>
                                    <div className={'title'} id={'title'}>
                                        {/*<div className={"after-content"}/>*/}
                                        <h1>Մուտքագրի՛ր հեռախոսահամարդ,<br/>
                                            Պտտի՛ր անիվը և Շահիր
                                        </h1>
                                    </div>
                                    {!hide ?
                                        <div className="G-main-form-field G-phone-input-wrapper">

                                            <p className="G-input-country-code">+{countryCode}</p>
                                            <input
                                                type={"text"}
                                                maxLength={8}
                                                autoComplete={'off'}
                                                name="phoneNumber"
                                                className={`G-main-input P-input-phone ${!valid ? 'G-invalid-field' : ''}`}
                                                placeholder={Settings.translations.phone_number}
                                                onChange={changePhoneField}
                                                value={value}
                                            />

                                        </div> : ''}
                                    {!hide ?
                                        <button id={'confirm_btn'} className="G-orange-button G-mt-20" disabled={!valid}
                                                onClick={handleHideInputFieldSpin}>{Settings.translations.done}</button>
                                        :
                                        <button id={'start_btn'} className="G-orange-button G-mt-20 spinBtn" disabled={isSpinning}
                                                onClick={handleStartSpin}>{Settings.translations.spin}</button>
                                    }
                                </>
                                :
                                <>
                                    <div className={'title'}>
                                        {/*{failed ?*/}
                                        {/*    <h1>Ափսո՜ս</h1>*/}
                                        {/*    : <h1>Շնորհավորում ենք դուք շահել եք</h1>*/}
                                        {/*}*/}
                                    </div>
                                    {/*<>*/}
                                    {/*    <h1 className={'result-title'}>{result}</h1>*/}
                                    {/*    <div className={"after-content"}/>*/}
                                    {/*</>*/}
                                   <h1 className={'result-desc'}>{descriptionResult}</h1>
                                    {button && <button id={'copy-button'} className={'G-orange-button G-mt-20 copy-button'} onClick={handleCopy}>Պատճենել պրոմոկոդը</button> }
                                </>
                            }
                        </div>
                    </div>
                </ClickOutside>
            </Modal>
        )
    }
);
export default Wheel;