import * as React from 'react';
import { Link } from 'react-router-dom';

import HelperComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import ListItem from './components/list-item';
import ROUTES from 'platform/constants/routes';

import './style.scss';
import ProductController from 'platform/api/product';
import Slider from "react-slick";
import Skeleton from "../../../../../components/Skeleton";

interface IState {
  data: any[];
};

class OrthopedicProducts extends HelperComponent<{}, IState> {

  public state: IState = {
    data: [],
  };

  public componentDidMount() {
    this.fetchData();
  }
  private fetchData = async () => {
    const result = await ProductController.GetCategoryList(18724,10);
    this.safeSetState({ data: result && result.data && result.data[0].products || [] });
  }
  public settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }

    ]
  }
  public render() {
    const { data } = this.state;

    return data.length ? (
      <section className="G-page P-home-discounted-products respon">
        <div className={'P-title-header'}>
          <h2 className="G-page-title">{Settings.translations.orthopedic_products}</h2>
          <Link
              to={`${ROUTES.PRODUCTS.MAIN}?categoryIds=18724&sortBy=1&page=1`}
              className=" G-ml-auto G-mr-auto"
          >{Settings.translations.show_all}</Link>
        </div>
        <h2 className="G-page-title">{Settings.translations.orthopedic_products}</h2>
        <div className="P-list-wrapper">
          <Slider
            {...this.settings}
            arrows={true}
            swipe={false}
          >
            {data.map(item => <div key={item.id}>
              <ListItem data={item} />
            </div>)}
          </Slider>
        </div>
        <Link
          to={`${ROUTES.PRODUCTS.MAIN}?categoryIds=18724&sortBy=1&page=1`}
          className="G-main-ghost-button G-ml-auto G-mr-auto"
        >{Settings.translations.show_all}</Link>
      </section>
    ): <Skeleton className={'product-skeleton'} count={4} type={'card'} spanCount={3}/>;
  }
};

export default OrthopedicProducts;
