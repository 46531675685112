import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import Settings from 'platform/services/settings';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import ListItem from './components/list-item';
import { IFAQListResponseModel } from 'platform/api/faq/models/response';
import Details from './pages/details';
import './style.scss';
import FAQController from 'platform/api/faq';
import {Link} from "react-router-dom";

interface IState {
  data: IFAQListResponseModel[];
};

@byRoute(ROUTES.FAQ.MAIN)
class FAQ extends HelperPureComponent<{}, IState> {

  public state: IState = {
    data: [],
  };

  public async componentDidMount() {
    this.fetchData();
  }

  private fetchData = async () => {
    const result = await FAQController.GetList();
    this.safeSetState({ data: result.data });
  }

  public render() {
    const { data } = this.state;

    return (
        <div>
          <ul className="Breadcrumbs">
            <li className='fromRoutes'> <Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
            <li className='activeRoute'> {Settings.translations.faq}</li>
          </ul>
          <section className="G-page P-faq-page">
            <h1 className="G-page-title">{Settings.translations.faq}</h1>
            {data.map(item => <ListItem key={item.id} data={item} />)}
          </section>
        </div>

    );
  }
};

export default {FAQ, Details};
