import * as React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import { contactPhoneNumber, contactEmail } from 'platform/constants/contact-info';
import OrderACall from './components/order_a_call';
import environment from 'platform/services/environment';
import Invite from './components/invite';
import LogoImage from 'assets/images/logo.png';
import AppStoreImage from 'assets/images/app_store.png';
import GooglePlayImage from 'assets/images/google_play.png';
import FacebookImage from 'assets/images/facebook.png';
import InstagramImage from 'assets/images/instagram.png';
import TelegramImage from 'assets/images/tel_icon.png';
import './style.scss';
import {useEffect} from "react";
import StaticController from "../../platform/api/static";

const currentYear = new Date().getFullYear();
const icons = 'icon-Group-5504 P-icons';

function Footer() {
    const [aboutRotateCondition, setAboutRotateCondition] = React.useState(true);
    const [aboutRotateClass, setAboutRotateClass] = React.useState([icons]);
    const [aboutItems, setAboutItems] = React.useState(['P-hidden-items']);

    const [whoSaleRotateCondition, setWhoSaleRotateCondition] = React.useState(true);
    const [whoSaleRotateClass, setWhoSaleRotateClass] = React.useState([icons]);
    const [whoSaleItems, setWhoSaleItems] = React.useState(['P-hidden-items']);

    const [informationRotateCondition, setInformationRotateCondition] = React.useState(true);
    const [informationRotateClass, setInformationRotateClass] = React.useState([icons]);
    const [informationItems, setInformationItems] = React.useState(['P-hidden-items']);

    const [usefulRotateCondition, setUsefulRotateCondition] = React.useState(true);
    const [usefulRotateClass, setUsefulRotateClass] = React.useState([icons]);
    const [usefulItems, setUsefulItems] = React.useState(['P-hidden-items']);

    const [contactRotateCondition, setContactRotateCondition] = React.useState(true);
    const [contactRotateClass, setContactRotateClass] = React.useState([icons]);
    const [contactItems, setContactItems] = React.useState(['P-hidden-items']);

    const[infoData,setInfoData]=React.useState<any>(null)
    useEffect(()=> {
       StaticController.GetAllContents().then(data=> {

           setInfoData(data.data)
       });

    },[])

    const aboutChange = () => {
        setAboutRotateCondition(!aboutRotateCondition);
        if (aboutRotateCondition) {
            setAboutRotateClass([...aboutRotateClass, 'P-rotate-icon']);
            setAboutItems(['P-shown-items']);

            setInformationRotateCondition(true);
            setInformationRotateClass([icons]);
            setInformationItems(['P-hidden-items']);

            setUsefulRotateCondition(true);
            setUsefulRotateClass([icons]);
            setUsefulItems(['P-hidden-items']);

            setContactRotateCondition(true);
            setContactRotateClass([icons]);
            setContactItems(['P-hidden-items'])
        } else {
            setAboutRotateClass([icons]);
            setAboutItems(['P-hidden-items'])
        }
    };

    const wholeSaleChange = () => {
        setWhoSaleRotateCondition(!whoSaleRotateCondition);

        if (whoSaleRotateCondition) {
            setWhoSaleRotateClass([...whoSaleRotateClass, 'P-rotate-icon']);
            setWhoSaleItems(['P-shown-items']);

            setInformationRotateCondition(true);
            setInformationRotateClass([icons]);
            setInformationItems(['P-hidden-items']);

            setUsefulRotateCondition(true);
            setUsefulRotateClass([icons]);
            setUsefulItems(['P-hidden-items']);

            setContactRotateCondition(true);
            setContactRotateClass([icons]);
            setContactItems(['P-hidden-items'])
        } else {
            setWhoSaleRotateClass([icons]);
            setWhoSaleItems(['P-hidden-items'])
        }
    };

    const informationChange = () => {
        setInformationRotateCondition(!informationRotateCondition);

        if (informationRotateCondition) {
            setInformationRotateClass([...informationRotateClass, 'P-rotate-icon']);
            setInformationItems(['P-shown-items']);

            setAboutRotateCondition(true);
            setAboutRotateClass([icons]);
            setAboutItems(['P-hidden-items']);

            setUsefulRotateCondition(true);
            setUsefulRotateClass([icons]);
            setUsefulItems(['P-hidden-items']);

            setContactRotateCondition(true);
            setContactRotateClass([icons]);
            setContactItems(['P-hidden-items'])
        } else {
            setInformationRotateClass([icons]);
            setInformationItems(['P-hidden-items'])
        }
    };

    const usefulChange = () => {
        setUsefulRotateCondition(!usefulRotateCondition);

        if (usefulRotateCondition) {
            setUsefulRotateClass([...usefulRotateClass, 'P-rotate-icon']);
            setUsefulItems(['P-shown-items']);

            setAboutRotateCondition(true);
            setAboutRotateClass([icons]);
            setAboutItems(['P-hidden-items']);

            setInformationRotateCondition(true);
            setInformationRotateClass([icons]);
            setInformationItems(['P-hidden-items']);

            setContactRotateCondition(true);
            setContactRotateClass([icons]);
            setContactItems(['P-hidden-items'])
        } else {
            setUsefulRotateClass([icons]);
            setUsefulItems(['P-hidden-items'])
        }
    };

    const contactChange = () => {
        setContactRotateCondition(!contactRotateCondition);

        if (contactRotateCondition) {
            setContactRotateClass([...contactRotateClass, 'P-rotate-icon']);
            setContactItems(['P-shown-items']);

            setAboutRotateCondition(true);
            setAboutRotateClass([icons]);
            setAboutItems(['P-hidden-items']);

            setInformationRotateCondition(true);
            setInformationRotateClass([icons]);
            setInformationItems(['P-hidden-items']);

            setUsefulRotateCondition(true);
            setUsefulRotateClass([icons]);
            setUsefulItems(['P-hidden-items'])
        } else {
            setContactRotateClass([icons]);
            setContactItems(['P-hidden-items'])
        }
    };

    return (
        <footer>
            <div className="footer-inner">
                <img src={LogoImage} width={'140px'} height={'49px'} className="P-logo G-mr-auto" alt={'logo'}/>
                <div className="P-content">
                    <div className="P-column">
                        <p>{Settings.translations.footer_text}</p>
                        <div className="P-social-links">
                            <a href="https://www.facebook.com/AlfaPharm" target="_blank">
                                <img src={FacebookImage} width={'35px'} height={'35px'} alt="facebook" />
                            </a>
                            <a href="https://www.instagram.com/AlfaPharm/" target="_blank">
                                <img src={InstagramImage} width={'35px'} height={'35px'} alt="instagram" />
                            </a>
                            <a className="P-telegram" href="https://t.me/AlfaPharm" target="_blank">
                                <img src={TelegramImage} width={'50px'} height={'50px'} alt="Telegram" />
                            </a>
                        </div>
                        <div className="P-mobile-app">
                            <img src={AppStoreImage}
                               width={'120px'} height={'40px'}  onClick={() => window.open('https://apps.apple.com/ru/app/alfa-pharm-drugstore-chain/id1453737637', '_blank')} alt={'AppStoreImage'}/>
                            <img src={GooglePlayImage}
                               width={'120px'} height={'40px'}  onClick={() => window.open('https://play.google.com/store/apps/details?id=am.gtest.alfapharm&hl=ru&gl=US', '_blank')}  alt={'GooglePlayImage'}/>
                        </div>
                    </div>
                    {!environment.WHOLESALE && <>
        {/*                { <div className="P-column">*/}
        {/*  <h3 className="G-clr-main">{Settings.translations.wholesale_sale}</h3>*/}
        {/*</div> }*/}

                        <div className="P-column P-mobile-column">
                            <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center"
                                onClick={aboutChange}>
                                <h3 className="G-clr-main">{Settings.translations.about_company}</h3>
                                <i className={aboutRotateClass.join(' ')} />
                            </div>
                            <Link className={aboutItems.join(' ')}
                                to={ROUTES.ABOUT_US}>{Settings.translations.about_us}</Link>
                            <Link className={aboutItems.join(' ')} to={ROUTES.NEWS.MAIN}>{Settings.translations.news}</Link>
                            <Link className={aboutItems.join(' ')}
                                to={ROUTES.VACANCIES.MAIN}>{Settings.translations.vacancies}</Link>
                        </div>


                        <div className="P-column P-mobile-column">
                            <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center" onClick={wholeSaleChange}>
                                <h3 className="G-clr-main">{Settings.translations.wholesale_sale}</h3>
                                <i className={whoSaleRotateClass.join(' ')} />
                            </div>
                            <a className={whoSaleItems.join(' ')} href={Settings.bigSale}
                                target="blank">{Settings.translations.wholesale_sale_price_list}</a>
                            <a className={whoSaleItems.join(' ')} href={Settings.services}
                                target="blank">{Settings.translations.wholesale_sale_service}</a>
                            <a className={whoSaleItems.join(' ')} href={Settings.bigSale}
                                target="blank">{Settings.translations.wholesale_sale_big_sale}</a>
                            <a className={whoSaleItems.join(' ')} href={Settings.OnlineSaleUrl}
                                target="blank">{Settings.translations.wholesale_sale_online_sale}</a>

                            {/*<a className={whoSaleItems.join(' ')} href={Settings.OnlineSaleUrl}*/}
                            {/*    target="blank">{Settings.translations.online_sale_new_website}</a>*/}
                        </div>


                        <div className="P-column P-mobile-column">
                            <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center" onClick={informationChange}>
                                <h3 className="G-clr-main">{Settings.translations.information}</h3>
                                <i className={informationRotateClass.join(' ')} />
                            </div>

                            <Link className={informationItems.join(' ')} to={ROUTES.FAQ.MAIN}>{Settings.translations.faq}</Link>
                            <Link className={informationItems.join(' ')} to={ROUTES.FINANCIAL_STATEMENTS}>{Settings.translations.financial_statements}</Link>
                            <Link className={informationItems.join(' ')} to={ROUTES.PRIVACY_POLICY}>{Settings.translations.privacy_policy}</Link>
                            {infoData && infoData.map((item: any, index: number) => {
                                return (<div key={index}>
                                    {item.contentType === 1?<Link className={informationItems.join(' ')}
                                           to={ROUTES.CARD_INFO}>{item.title}</Link>:
                                       item.contentType === 2?<Link className={informationItems.join(' ')}
                                        to={ROUTES.TENDERS.MAIN}>{item.title}</Link>:
                                           item.contentType === 3?<Link className={informationItems.join(' ')}
                                        to={ROUTES.PRIVACY_POLICY}>{item.title}</Link>:
                                                item.contentType === 4?<Link className={informationItems.join(' ')}
                                        to={ROUTES.TERMS_OF_USE}>{item.title}</Link>:null}
                                </div>)
                            })}
                        </div>
                    </>}
                    <div className="P-column P-mobile-column">
                        <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center"
                            onClick={usefulChange}>
                            <h3 className="G-clr-main">{Settings.translations.useful_links}</h3>
                            <i className={usefulRotateClass.join(' ')} />
                        </div>
                        {!environment.WHOLESALE && <Link className={usefulItems.join(' ')}
                            to={ROUTES.HOW_TO_ORDER_ONLINE}>{Settings.translations.how_to_order_online}</Link>}
                    </div>
                    <div className="P-column P-mobile-column">
                        <div className="P-mobile-drop G-flex G-flex-justify-between G-flex-align-center"
                            onClick={contactChange}>
                            <h3 className="G-clr-main">{Settings.translations.contact}</h3>
                            <i className={contactRotateClass.join(' ')} />
                        </div>
                        <a className={contactItems.join(' ')}><i
                            className="G-clr-orange icon-Group-5522" /> {contactPhoneNumber}</a>
                        <a className={contactItems.join(' ')}><i className="G-clr-orange icon-Group-5524" /> {contactEmail}
                        </a>
                        <a className={contactItems.join(' ')}><i
                            className="G-clr-orange icon-Group-5527" /> {Settings.translations.contact_address}</a>
                        <OrderACall />
                        {!!Settings.token && !Settings.guest && <Invite />}
                    </div>
                </div>
                <h4 className="P-copyright">
                    {Settings.translations.copyright} &copy; {currentYear} Alfa Pharm
                </h4>
            </div>
        </footer>
    )
}

// const Footer = React.memo(Foot);

export default Footer;
