import * as React from 'react';
export default {
    'Jackpot': {
        failed: false,
        text: <><b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք լավագույն մրցանակը՝ 13% զեղչի պրոմոկոդ։ </b> <br/> Կատարեք օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով և «Պրոմոկոդ» դաշտում լրացրեք <b className={'promocode'}>Jackpot</b> բառը և ստացեք 13% զեղչ՝ ամբողջ գնման համար։</>,
        button: true,
        buttonText: 'Jackpot',
    },
    'Promocode 10%': {
        failed: false,
        text: <><b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք 10% զեղչի պրոմոկոդ։ </b> <br/> Կատարեք օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով և «Պրոմոկոդ» դաշտում լրացրեք <b className={'promocode'}>zexch10</b> բառը և ստացեք 10% զեղչ՝ ամբողջ գնման համար։</>,
        button: true,
        buttonText: 'zexch10',
    },
    'Շատ մոտ էր': {
        text: <b> Մենք քեզ սպասում ենք վաղը։<br/>Շարունակի՛ր կատարել քո օնլայն գնումները AlfaPharm online դեղատնից։</b>,
        failed: true,
        button: false,
        buttonText: '',
    },
    'Անվճար առաքում': {
        failed: false,
        text: <> <b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք անվճար առաքման հնարավորություն։ </b> <br/>  Կատարեք օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով և «Պրոմոկոդ» դաշտում լրացրեք  <b className={'promocode'}>araqum</b> բառը և ստացեք անվճար առաքում։ </>,
        button: true,
        buttonText: 'araqum',
    },
    'Վայ ափսոս': {
        text: <b>Մենք քեզ սպասում ենք վաղը։ <br/>Շարունակի՛ր կատարել քո օնլայն գնումները AlfaPharm online դեղատնից։</b>,
        failed: true,
        button: false,
        buttonText: '',
    },
    'Promocode 11%': {
        failed: false,
        text: <> <b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք 11% զեղչի պրոմոկոդ:</b> <br/> Կատարեք օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով և «Պրոմոկոդ» դաշտում լրացրեք <b className={'promocode'}>zexch11</b> բառը  և ստացեք 11% զեղչ՝ ամբողջ գնման համար։</>,
        button: true,
        buttonText: 'zexch11',
    },
    'Փորձանմուշներ': {
        failed: false,
        text: <> <b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք կոսմետիկ փորձանմուշներ։ </b> <br/> Կատարեք 5000 դրամ և ավել գումարի օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով, «Պրոմոկոդ» դաշտում լրացրեք <b className={'promocode'}>porcanmush</b> բառը և ստացեք անակնկալ փորձանմուշներ Ձեր գնումների հետ։</>,
        button: true,
        buttonText: 'porcanmush',
    },
    'Promocode 12%': {
        failed: false,
        text: <> <b className={'success-prise'}>Շնորհավորում ենք, Դուք շահել եք 12% զեղչի պրոմոկոդ։</b> <br/> Կատարեք օնլայն գնումներ Ալֆա-Ֆարմի կայքէջով կամ բջջային հավելվածով և «Պրոմոկոդ» դաշտում լրացրեք <b className={'promocode'}>Zexch12</b> բառը  և ստացեք 12% զեղչ՝ ամբողջ գնման համար։</>,
        button: true,
        buttonText: 'Zexch12',
    },
    'Վաղը կրկին փորձիր': {
        text: <b>Մենք քեզ սպասում ենք վաղը։ <br/> Շարունակի՛ր կատարել քո օնլայն գնումները AlfaPharm online դեղատնից։</b>,
        failed: true,
        button: false,
        buttonText: '',
    },
    'Կսպասենք քեզ վաղը': {
        text: <b> Մենք քեզ սպասում ենք վաղը։ <br/> Շարունակի՛ր կատարել քո օնլայն գնումները AlfaPharm online դեղատնից։</b>,
        failed: true,
        button: false,
        buttonText: '',
    },

};