import * as React from 'react';

import { IBlogDetailsResponseModel } from 'platform/api/blog/models/response';

import './style.scss';
import Moment from "react-moment";

interface IProps {
  data: IBlogDetailsResponseModel;
}

const Info = React.memo(({ data }: IProps) => (
  <div className="P-blog-details-info">
      <h1 className="title">{data.title}</h1>
      <Moment format="DD.MM.YYYY">{data.createdDate.split("T").join(" ").split(".")[0]}</Moment>

    {/*  <h2 className="P-name">{data.title}</h2>*/}
    {/*<p className="P-description">{data.shortDescription}</p>*/}
  </div>
));
export default Info;
