import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import BasketController from 'platform/api/basket';
import {
    IBasketListResponseModel,
    IBasketResponseModel,
    IBasketChangeResponseModel
} from 'platform/api/basket/models/response';
import EmptyState from 'components/empty-state';
import {formatPrice} from 'platform/services/helper';
import Connection from 'platform/services/connection';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Storage from 'platform/services/storage';
import PageLoader from 'components/page-loader';
import {IResponse} from 'platform/constants/interfaces';
import ConfirmModal from 'components/confirm-modal';

import './style.scss';
import PhotoStorage from 'platform/services/photoStorage';
import {PriceNotEnoughModal} from './components/priceNotEnoughModal';
import {Shared} from 'modules';
import * as animationData from 'assets/animations/EmptyBasket.json';
import Slider from "react-slick";
import ListItem from "./components/list-item";
import {IProductListResponseModel} from "../../../platform/api/product/models/response";
import ProductController from "../../../platform/api/product";
import Environment from "../../../platform/services/environment";
import MasterCard from "../../../assets/images/master.svg";
import TrashIcon from "../../../assets/images/icons/trash-icon.svg";
import GtagData from "../../../platform/services/gtagData";
import {responseEnum} from "../checkout";
import VisaCard from "../../../assets/images/visaImage.svg";
import OrderController from "../../../platform/api/order";
import {IOrderResultResponseModel} from "../../../platform/api/order/models/response";

interface IState {
    data?: IBasketResponseModel;
    outOfStockConfirm: boolean;
    priceNotEnoughModalOpen: boolean;
    dataSlider: IProductListResponseModel[];
    promoMessage: boolean;
    resultInfo: IOrderResultResponseModel;
    reclam:boolean;
};

@byRoute(ROUTES.CART)
class Cart extends HelperComponent<{}, IState> {

    public state: IState = {
        outOfStockConfirm: false,
        priceNotEnoughModalOpen: false,
        dataSlider: [],
        promoMessage: false,
        resultInfo: {
            totalPrice: 0,
            deliveryFee: 0,
            receivedBonus: 0,
            totalDiscountedPrice: 0,
            receivedBonusWithoutPromo: 0,
            isBonus: 0,
            isBonusVisa: 0,
            multiply: 0,
            totalDiscountedPriceWithoutPromo:0,
            totalPriceWithoutPromo: 0
        },
        reclam: true
    };
    public settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
        slidesToShow: 4,
        fade: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    }

    public componentDidMount() {
        this.fetchData();
        this.updateBasketCount();
        this.fetchDataSlider();
        window.addEventListener(DispatcherChannels.CartUpdate, this.fetchData);
    }

    private deleteBasketItem = async (item: IBasketListResponseModel) => {
        const result = await BasketController.Delete(item.productId, item.isPackage);
        if (result.success) {
            this.updateBasketCount();
            if (item.productId !== 1262) {
                await GtagData(item, "remove_from_cart")
            }
            this.fetchData();
        }
    };
    private updateBasketCount = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    private fetchDataSlider = async () => {
        const body = {
            productText: '',
            categoryIds: [Environment.RecommendedProductsId],
            brandIds: [],
            producerIds: [],
            activeIngredientIds: [],
            sortBy: undefined,
            hasDiscount: false,
            pageSize:30,
            pageNumber:1,
        };
    const result = await ProductController.GetList(body);
    this.safeSetState({dataSlider: result && result.data && result.data.list || []});
    }
    private fetchData = async () => {
        const result = await BasketController.GetList();
        this.safeSetState({data: result.data}, async () => {
            const {data} = this.state;

            if (data) {
                const photoResult = await Promise.all(data.items.map(item => PhotoStorage.getURL(item.productPhoto).then(url => ({
                    ...item,
                    productPhoto: url,
                    google_product_category: item.productGroupName
                }))));

                data.items = photoResult;
                this.safeSetState({data});
                await GtagData(data,"view_cart")
                await this.sendPromoCode(Environment.PROMOCODE_NAME)
            }
        });
    };
    private sendPromoCode = async (val: any) => {
            val && val !== "" && Connection.POST2({
                body: {
                    "promoCode": val,
                    "productIds": this.state.data?.items.map(item => item.productId)
                },
                action: 'PromoCodeResult',
                controller: "Order",
            }).then(res => {
                if(res.data === responseEnum.success){
                    this.safeSetState({promoMessage: true})
                    this.getResultInfo()
                }else{
                    this.safeSetState({promoMessage: false})
                }
            }).catch(err => console.log(err));
    };
    private getResultInfo = async (bonus = 0) => {
        const result =  await OrderController.GetResult({
            usingBonus: 0,
            deliveryType: 1,
            promoCode: Environment.PROMOCODE_NAME
        })
        result.data && this.safeSetState({
            resultInfo: result.data,
        });
    };

    private changeCount = async (index: number, count: number) => {
        const {data} = this.state;
       setTimeout(()=>{
             this.sendPromoCode(Environment.PROMOCODE_NAME)
        },200)
        let modifyResult: IResponse<IBasketChangeResponseModel>;
        if (data) {
            if (count) {
                Connection.AbortAll();
                modifyResult = await BasketController.Change({
                    productId: data.items[index].productId,
                    productQuantity: count,
                    isPackage: data.items[index].isPackage
                });
                data.items[index].productQuantity = count;
            } else {
                modifyResult = await BasketController.Delete(data.items[index].productId, data.items[index].isPackage);
                data && data.items.splice(index, 1);
                this.updateBasketCount();
            }

            if (modifyResult.data) {
                this.safeSetState({data: modifyResult.data});
                window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
            }
        }
    };

    private goToCheckout = async () => {
        const {data} = this.state;

        if (data) {
            const price = data && (data.totalDiscountedPrice || data?.totalPrice);

            if (data.items.some(item => !item.productStockQuantity)) this.safeSetState({outOfStockConfirm: true});
            else if (data && price < 2000) this.safeSetState({priceNotEnoughModalOpen: true});
            else window.routerHistory.push(`${ROUTES.CHECKOUT}?total=${price}`);
            const newData = data.items.filter(item => item.productId !== 1262);
            await GtagData(newData,"begin_checkout")
        }
    };

    private closeOutOfStockConfirm = () => this.safeSetState({outOfStockConfirm: false});
    private deleteOutOfStock = async () => {
        const {data} = this.state;
        setTimeout(()=>{
            this.sendPromoCode(Environment.PROMOCODE_NAME)
        },200)
        if (data) {
            const updatingItems = data.items.filter(item => !item.productStockQuantity).map(item => ({
                productId: item.productId,
                productQuantity: 0,
                isPackage: item.isPackage
            }));

            const result = await BasketController.ChangeList(updatingItems);
            result.success && this.safeSetState({outOfStockConfirm: false}, () => {
                this.updateBasketCount();
                this.fetchData();
            });
        }
    };

    private deleteAll = async () => {
        this.safeSetState({promoMessage: false})
        const result = await BasketController.DeleteAll();
        if (result.data) {
            this.safeSetState({data: result.data});
            window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
        }
    };

    private saveCart = async () => {
        const {data} = this.state;

        if (data) {
            const alertify = await import('alertifyjs');
            const basketIds = data.items.map(item => item.id);
            const result = await BasketController.Save(basketIds);
            result.success && alertify.success(Settings.translations.basket_save_success);
        }
    };

    private togglePriceNotEnoughModal = () => {
        const {priceNotEnoughModalOpen} = this.state;
        this.safeSetState({priceNotEnoughModalOpen: !priceNotEnoughModalOpen});
    };

    public render() {
        const {data, promoMessage,resultInfo, dataSlider, priceNotEnoughModalOpen, outOfStockConfirm} = this.state;
        return (
            <div>
                {dataSlider.length ? <section className="G-page P-home-discounted-products respon cart-slider">
                    <h2 className="G-page-title G-clr-orange ">{Settings.translations.suggested_products}</h2>
                    <div className="P-list-wrapper">
                        <Slider
                            {...this.settings}
                            arrows={true}
                            swipe={true}
                            autoplay={true}
                            autoplaySpeed={3000}
                        >

                            {dataSlider.map(item => <div key={item.id}>
                                <ListItem data={item}/>
                            </div>)}
                        </Slider>
                    </div>
                    {/*<Link*/}
                    {/*    to={`${ROUTES.PRODUCTS.MAIN}?categoryIds=18496&sortBy=1&page=1`}*/}
                    {/*    className="G-main-ghost-button G-ml-auto G-mr-auto"*/}
                    {/*>{Settings.translations.show_all}</Link>*/}
                </section> : null}
                <section className="P-Step-Wrapper">
                    {data && data.items.length ?
                        <div className="P-steps G-mb-30">
                            <p>1</p>
                            <span/>
                            <p className="P-disabled">2</p>
                            <span/>
                            <p className="P-disabled">3</p>
                        </div>
                        : <></>
                    }
                </section>
                {data ? <>



                            {data.items.length !== 0 && <>
                            <section className="G-page P-cart-page">
                                <div className="P-cart-left-box">
                                <div
                                    className="G-flex G-flex-justify-between G-flex-align-center G-full-width P-basket-clear">
                                    <h1 className="G-fs-26 G-full-width">{Settings.translations.cart}</h1>
                                    <a className="G-ml-auto P-clear-all"
                                       onClick={this.deleteAll}>{Settings.translations.clear_basket}</a>
                                    <a className="G-ml-auto P-clear-all-icon"
                                       onClick={this.deleteAll}><img src={TrashIcon} alt="trash"/></a>
                                </div>

                                <div className="G-flex G-flex-justify-between P-cart-block">
                                    <div className="G-flex G-flex-column P-basket-products">
                                        <Shared.Products.BasketList
                                            onQuantityChange={this.changeCount}
                                            onDeleteBasketItem={this.deleteBasketItem}
                                            data={data.items}
                                        />
                                    </div>
                                </div>


                                {priceNotEnoughModalOpen &&
                                    <PriceNotEnoughModal onClose={this.togglePriceNotEnoughModal}/>}

                                {outOfStockConfirm && <ConfirmModal
                                    title={Settings.translations.out_of_stock}
                                    text={Settings.translations.out_of_stock_delete_confirm}
                                    onConfirm={this.deleteOutOfStock}
                                    onClose={this.closeOutOfStockConfirm}
                                />}
                                </div>
                                <div className="P-cart-right-box">
                                    {data && data.items.length ?
                                        <div className="P-data-block-wrapper">
                                            <div className="P-data-block G-flex-column">
                                                <div
                                                    className="G-flex G-flex-justify-between G-flex-align-center G-mb-25">
                                            <span
                                                className="G-fs-normal G-text-bold">{Settings.translations.total}</span>
                                                    <div
                                                        className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                                                        {!!data.totalDiscountedPrice && data.totalDiscountedPrice !== data.totalPrice &&
                                                            <del>{formatPrice(data.totalPrice)}</del>}
                                                        <h1
                                                            className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.totalDiscountedPrice || data.totalPrice)}</h1>
                                                    </div>
                                                </div>

                                                <div
                                                    className="G-flex G-flex-justify-between G-flex-align-center G-mb-25">
                                            <span
                                                className="G-fs-normal G-clr-main G-text-bold">{Settings.translations.accumulative_bonus}</span>
                                                    <h1 className="G-clr-main G-text-bold G-fs-normal G-mt-5">{data.totalBonus} {Settings.translations.pointss}</h1>
                                                </div>
                                                {data.isBonus > 0 &&
                                                    <div
                                                        className="G-flex G-flex-justify-between G-flex-align-center G-mb-25">
                                            <span
                                                className="G-fs-normal G-clr-main G-text-bold master-card-text">{Settings.translations.accumulative_bonus_master_card}
                                                <img src={MasterCard} className="logo-image" width="30"
                                                     alt="MASTER CARD"/>
                                            </span>
                                                        <h1 className="G-clr-main G-text-bold G-fs-normal G-mt-5 master-card-bonus">{data.totalBonus * data.multiply} {Settings.translations.pointss}</h1>
                                                    </div>
                                                }

                                            </div>
                                            {promoMessage &&
                                                <h3 className="G-clr-orange G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span
                                        className="G-clr-orange G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_visa_card}<br/>
                                        <img src={VisaCard} className="logo-image" width="50" alt="VISA CARD"/>
                                    </span>
                                                    <span
                                                        className="G-clr-orange G-text-semi-bold master-card-bonus">{formatPrice(resultInfo.totalDiscountedPrice)}</span>
                                                </h3>}
                                            {Storage.profile && <div className="P-data-block-cart">
                <span className="P-save-cart" onClick={this.saveCart}>
                  {Settings.translations.save_cart}
                </span>
                                            </div>}

                                            <button
                                                id="order_process_start_btn"
                                                className="G-mt-50 G-main-button G-ml-auto G-fs-normal P-pay-button"
                                                onClick={this.goToCheckout}
                                            >{Settings.translations.next_step}</button>
                                        </div> : <></>
                                    }
                                </div>
                            </section>
                            </>}

                    {data.items.length === 0 && <>
                        <section className="P-empty-wrapper">
                            <div className="P-empty-state-align-end">
                                <EmptyState
                                    animationData={animationData}
                                    text={Settings.translations.no_products}
                                />
                            </div>
                        </section>
                    </>}


                </> : <PageLoader/>}
            </div>
        );
    }
}

export default Cart;
