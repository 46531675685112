import * as React from 'react';

import Settings from 'platform/services/settings';
import Form from './components/form';
import AboutDelivery from './components/about-delivery';
import AboutBonusCard from './components/about-bonus-card';
import AboutMobileApp from './components/about-mobile-app';
import HelperComponent from 'platform/classes/helper-component';
import PharmacyBranchController from 'platform/api/pharmacyBranch';
import { formatTime, getViewEnum } from 'platform/services/helper';
import { WeekDaysEnum } from 'platform/constants/enums';
import { IPharmacyBranchListResponseModel } from 'platform/api/pharmacyBranch/models/response';
import MapIconGreen from 'assets/images/map-icon-green.svg';
import MapIconOrange from 'assets/images/map-icon-orange.svg';


import './style.scss';

interface IState {
  branches: IPharmacyBranchListResponseModel[];
  hoveredMarkerIndex?: number;
};

class ContactUs extends HelperComponent<{}, IState> {

  public state: IState = {
    branches: []
  };

  public componentDidMount() { this.fetchData() }
  private weeksViewEnum = getViewEnum(WeekDaysEnum);
  private toggleMarker = (index?: number) => this.safeSetState({ hoveredMarkerIndex: index });

  private fetchData = async () => {
    const result = await PharmacyBranchController.GetList({
      pageSize: 20000,
      pageNumber: 1,
    });

    this.safeSetState({
      branches: result && result.data && result.data.list || []
    });
  }
  private get markers() {
    return this.state.branches.map((item, index) => ({
      position: { lat: item.addressLat, lng: item.addressLng },
      onClick: () => this.toggleMarker(index),
      icon: {
        url: item.isOpen ? MapIconGreen : MapIconOrange,
        scaledSize:  new google.maps.Size(30, 30)
      },
    }));
  }
  private get hoveredMarkerData() {
    const { hoveredMarkerIndex, branches } = this.state;
    return hoveredMarkerIndex || hoveredMarkerIndex === 0 ? branches[hoveredMarkerIndex] : undefined;
  }
  private WorkingPlan = () => {
    if (!this.hoveredMarkerData) return null;
    return <div className="G-flex G-flex-column">
      {this.hoveredMarkerData.workingPlan.map((item, index) => <span key={index}>
        {item.endDay ? '-' + Settings.translations[this.weeksViewEnum[item.endDay]] : ''}
        &nbsp;&nbsp;
        {item.isDayOff ? Settings.translations.day_off : `${formatTime(item.startTime)}-${formatTime(item.endTime)}`}
      </span>)}
    </div>
  }
  public getPointOptions = (item:any) => {
    return {
      iconLayout: 'default#image',
      iconImageHref: item.icon.url,
      iconImageSize: [30, 42],
      iconImageOffset: [-5, -38],

    };
  };

  public getPointData = (index:any) => {
    return {
      objectId: index,
      hintContent: "<div class='my-hint'>" +
          "<b>SomeObject</b><br />" +
          "SomeAddress" +
          "</div>",
      balloonContentBody: "placemark <strong>balloon " + index + "</strong>",
      clusterCaption: "placemark <strong>" + index + "</strong>"
    };
  };



  public render() {
    const { hoveredMarkerIndex } = this.state;

    return (
      <section className="G-page P-home-contact-us padMap">
        <h2 className="G-page-title">{Settings.translations.contact_us}</h2>
        <Form />

        {/*<Maps className="P-maps P-home-map">*/}
        {/*  <div className="P-icons-info">*/}
        {/*    <p><img src={MapIconGreen} alt="" /> <b> { Settings.translations.open}</b></p>*/}
        {/*    <p><img src={MapIconOrange} alt=""/> <b> { Settings.translations.closed}</b></p>*/}
        {/*  </div>*/}
        {/*  {this.markers.map((item, index) =>*/}
        {/*    <Marker key={index} {...item}>*/}
        {/*      {hoveredMarkerIndex === index && this.hoveredMarkerData && <InfoWindow>*/}
        {/*        <div className="P-info-window P-home-info-window">*/}
        {/*          <h3 className="G-clr-orange G-text-center P-name">{this.hoveredMarkerData.name}</h3>*/}
        {/*          <h4 className="P-info-row G-flex-center">*/}
        {/*            <i className="icon-Group-5522 G-clr-orange" /> <span>{this.hoveredMarkerData.contactPhoneNumber}</span>*/}
        {/*          </h4>*/}
        {/*          <h4 className="P-info-row G-flex G-mt-15">*/}
        {/*            <i className="icon-Group-5554 G-clr-orange" />*/}
        {/*            <this.WorkingPlan />*/}
        {/*          </h4>*/}
        {/*        </div>*/}
        {/*      </InfoWindow>}*/}
        {/*    </Marker>*/}
        {/*  )}*/}
        {/*</Maps>*/}


        {/* <YMaps>
          <div style={{position:"relative"}}>
            <Map style={{width:'100%',height:'700px',marginTop:'20px'}} defaultState={{center: [40.177623, 44.512520],zoom: 12,}}>
              <div className={"iconMap"}>
                <p><img src={MapIconGreen} alt=""/> <b> {Settings.translations.open}</b></p>
                <p><img src={MapIconOrange} alt=""/> <b> {Settings.translations.closed}</b></p>
              </div>

              {this.markers.map((coordinates, idx) => (
                    <Placemark
                        key={idx}
                        {...coordinates}
                        geometry={[coordinates.position.lat,coordinates.position.lng]}
                        properties={{
                          hintContent: 'Это хинт',
                          balloonContent: 'Это балун'
                        }}
                        options={this.getPointOptions(coordinates)}/>
              ))}
            </Map>
          </div>
        </YMaps> */}

        <div className="P-bottom">
          <AboutDelivery />
          <AboutBonusCard />
          <AboutMobileApp />
        </div>
      </section>
    );
  }
}

export default ContactUs;
