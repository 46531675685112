import * as React from 'react';
import './style.scss';

interface SkeletonProps {
    count: number;
    className?: string;
    type?: string;
    spanCount?: number | null;
}

const Skeleton: React.FC<SkeletonProps> = React.memo(({count, className = '', type = '', spanCount = null }) => {
    const skeletons = new Array(count).fill(1);
    let classTemp = '';

    if (className) {
        classTemp += ` ${className}`;
    }
    if (type) {
        classTemp += ` ${type}`;
    }

    return (
        <ul className={`skeleton ${classTemp}`}>
            {skeletons.map((_, index) => (
                <li key={index}>
                    {spanCount ? new Array(spanCount).fill(1).map((item, i) => <span key={i} />) : null}
                </li>
            ))}
        </ul>
    );
});

export default Skeleton;
