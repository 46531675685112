// export default {
//   BASE_URL: "https://qapapi.armlon.co.uk/",
// };

// import * as CONFIG from '../../../config.json';

export default {

     BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://api.alfapharm.am/" : "https://api.alfapharm.am/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "http://localhost:5000/" : "http://localhost:5000/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://api.alfapharm.am/" : "https://alfaback.abmdemo.me/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://alfapharm.dev-15.toolbox.am/" : "https://alfapharm.dev-15.toolbox.am/",
    SITE_URL:'https://alfapharm.am/',
    PROMOCODE_NAME: 'visatest10',


    WHOLESALE: false,
    SeasonalOffersId:18523,
    OthersProductsId:18496,
    RecommendedProductsId:18524,
    ProductOfTheDay:18552
};
