import React from 'react';
import Settings from './settings';
import Storage from 'platform/services/storage';
import {PromotionTypeEnum} from "../constants/enums";

declare global {
    interface Window {
        dataLayer: any[];
    }
}

function getData(data, EventName,category = '',isList = false) {
    let Data = JSON.parse(JSON.stringify(data));
    const gtagEvent = (item: object | null) => {
        // window.gtag(eventName, eventName, item,{
        //     event_category: 'buttons on main page',
        //     groups: 'MYGROUP',
        //     poll_title: 'some title',
        //     event: 'event',
        //     event_label: 'test',
        //     eventName: EventName,
        //     event_name: EventName,
        // });
        // window.dataLayer.push({event:null, ecommerce: null });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(item);
    }
    const UserData = Settings.token && !Settings.guest ? {
        userId: Storage.profile.id,
        dateOfBirth: Storage.profile.dateOfBirth,
        email: Storage.profile.email,
        firstName: Storage.profile.firstName,
        lastName: Storage.profile.lastName,
        gender: Storage.profile.gender,
        phoneNumber: Storage.profile.phoneNumber,
        photoPath: Storage.profile.photoPath,
        username: Storage.profile.username,
    } : null;
    if (Data?.items || Data?.list ) {
        const Items = Data.items ? 'items' : 'list'
        if(isList){ Data =  {items: Object.values(Data[Items])}}
        const newArr = []
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < Data.items.length; i++) {

            if (isList){
                const isDiscount = !!Data.items[i]?.promotion?.result && Data.items[i].promotion.promotionType === PromotionTypeEnum.Discount;

                Data.items[i].price =  isDiscount ?   Data.items[i].promotion.result : Data.items[i].price
            }else{
                Data.items[i].price =  getItemPriceInfo(Data.items[i]).discountedPrice > 0 ?
                    getItemPriceInfo(Data.items[i]).discountedPrice :
                    getItemPriceInfo(Data.items[i]).price
            }
            let stockQuantity = true
            if("stockQuantity" in Data.items[i]){
                stockQuantity = Data.items[i].stockQuantity
            }else if("productStockQuantity" in Data.items[i]){
                stockQuantity = Data.items[i].productStockQuantity
            }
            if(stockQuantity && Data.items[i].id !== 1262 ){
                // tslint:disable-next-line:no-string-literal
                if(isList){ Data.items[i]['google_product_category'] = category}
                // @ts-ignore
                newArr.push(Data.items[i])
            }

        }
        Data?.list && delete Data?.list
        Data.items =  newArr

    } else {
        if (Data?.promotion?.result > 0) {
            Data.price = Data.promotion.result
            if(Data.category?.name){
                // tslint:disable-next-line:no-string-literal
                Data['google_product_category'] = Data.category?.name
            }
        }
        delete Data?.avgPrice;

    }

    const DataEvent = {
        event: EventName,
        ecommerce: {
            user: {
                UserData
            },
            add: {
                products: [
                    {
                        Data,
                    },
                ],
            },
        },
    };
    gtagEvent(DataEvent);
}


const getItemPriceInfo = (item)=> {
    const result = {
        price: item.productQuantity * (item.isPackage && item.havePackage ? item.packagePrice : item.price),
        discountedPrice: 0,
    };

    if (item.promotion.promotionType === PromotionTypeEnum.Discount) {
        result.discountedPrice = item.promotion.groupPromotion ? item.promotion.result - item.promotion.groupPromotion.result : item.promotion.result;
        return result;
    }

    if (item.promotion.groupPromotion) result.discountedPrice = result.price - item.promotion.groupPromotion.result;

    return result;
};

export default getData;

//
// import React from 'react';
// import Settings from './settings';
// import Storage from 'platform/services/storage';
//
// declare global {
//     interface Window {
//         dataLayer: any[];
//     }
// }
//
// function getData(Data, EventName) {
//     const gtagEvent = (eventName: string, item: object | null) => {
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push(item);
//     }
//     const UserData = Settings.token && !Settings.guest ? {
//         userId: Storage.profile.id,
//         dateOfBirth: Storage.profile.dateOfBirth,
//         email: Storage.profile.email,
//         firstName: Storage.profile.firstName,
//         lastName: Storage.profile.lastName,
//         gender: Storage.profile.gender,
//         phoneNumber: Storage.profile.phoneNumber,
//         photoPath: Storage.profile.photoPath,
//         username: Storage.profile.username,
//     } : null;
//     const products = { ...Data, name: Data.title };
//     delete products.title;
//
//     const DataEvent = {
//         event: EventName,
//         ecommerce: [
//             {
//                 user: {
//                     UserData
//                 },
//                 add: [
//                     {
//                         products
//                     },
//                 ]
//             }
//         ],
//     };
//     console.log(DataEvent)
//     gtagEvent(EventName, DataEvent);
// }
// export default getData;