class SearchHistory {

  public static get items(): any {
    try {
      const storedItems = window.localStorage.getItem('searchHistory');
      return storedItems ? JSON.parse(storedItems) : [];
    } catch { return []; }
  }

  public static add(product: number) {
    const { items } = SearchHistory;
    if (!items.some(item => item.id === product)) {
      items.unshift(product);
      if (SearchHistory.items.length > 10) items.splice(-1)
      window.localStorage.setItem('searchHistory', JSON.stringify(items));  
    }
  }

  public static clear() {
    window.localStorage.removeItem('searchHistory');
  }
}

export default SearchHistory;