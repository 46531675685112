import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import {ICategoryListResponseModel, ICategoryTreeResponseModel} from './models/response';

const controller = 'category';

class CategoryController {

  public static GetList = (parentId?: any): Promise<IResponse<ICategoryListResponseModel[]>> => {
    const result = Connection.GET({
      action: '',
      query: { parentId },
      controller,
    });

    return result;
  };
  public static GetCategoryTree = (id: any): Promise<IResponse<any>> => {
    const result = Connection.GET({
      action: `Categorytree/${id}`,
      controller,
    });

    return result;
  };
};

export default CategoryController;