import * as React from 'react';

import { IFAQListResponseModel } from 'platform/api/faq/models/response';

import './style.scss';
import ROUTES from "../../../../../platform/constants/routes";
import Settings from "../../../../../platform/services/settings";
import {Link} from "react-router-dom";

interface IProps {
  data: IFAQListResponseModel;
};

const ListItem = React.memo(({ data }: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const lang:any=Settings.language
  return <>
    <div className={`P-faq-list-item ${open ? 'P-open' : ''}`}>
      <Link className="" to={ROUTES.FAQ.DETAILS.replace(':id', data.id).replace(':slug', data.slugs[lang])}>
        <h2 className="P-title">{data.title}</h2>
      </Link>
    </div>

    {open && <div className="P-faq-list-item-content">
      <p className="P-description" dangerouslySetInnerHTML={{ __html: data.description }} />
    </div>}
  </>
});

export default ListItem;
